import React, {
    useEffect,
    useState
} from "react";
import {
    Checkbox,
    Dialog,
    Divider,
    FormControlLabel,
    InputAdornment,
    Typography,
} from "@material-ui/core";
import {
    BasicButton,
    BasicInput,
    BasicTrButton,
    ErrorMessage,
    ModalBody,
    ModalBodyWrapper,
    ModalContactChip,
    ModalFoot,
    ModalFormGroup,
    ModalFormGroupSectionHead,
    ModalFormGroupWrapper,
    ModalFormInput,
    ModalFormLabel,
    ModalHead,
    ModalTitle
} from "./ProductStyles";
import {changeDealStatus, createUserProduct, userProductStatus} from "../../../../api/dealApi";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import {CONTACT_PRODUCTS, CONTACT_STAGE, RESPONSE_STATUS} from "../../../../constants/CoreConstants";
import { AttachMoney } from "@material-ui/icons";

const INPUT_FIELDS = {
    unit_price: "unit_price",
    unit_cost: "unit_cost"
};

const AddProductModal = ({ contact, open, onClose, appendNewContactProduct,setCreateProduct}) => {
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        unit_cost: "",
        unit_price: "",
        billing_frequency: "",
        close_date: ""
    });
    const [formDataError, setFormDataError] = useState({
        title: "",
        description: "",
        unit_cost: "",
        unit_price: "",
        billing_frequency: "",
        close_date: ""
    });
    const [submitting, setSubmitting] = useState(false);
    const [contactLabel, setContactLabel] = useState("");
    const [duplicateTrashProduct, setDuplicateTrashProduct] = useState(null);
    const [createDealAfterAddProduct,setCreateDealAfterAddProduct]= useState(false);

    useEffect(() => {
        if (contact){
            let label = "";

            if (contact.first_name){
                label += contact.first_name;
            }

            if (contact.last_name){
                if (label){
                    label += " ";
                }
                label += contact.last_name;
            }

            if (!label){
                label = contact.email;
            }

            if (!label){
                label = contact.number;
            }

            setContactLabel(label);
        }
    }, [contact]);

    const handleChangeFormData = (event) => {
        let key = event.target.name;
        let value = event.target.value;

        if (key === INPUT_FIELDS.unit_price || key === INPUT_FIELDS.unit_cost){
            const regex = /^(?:\d*|\d+\.\d{0,4})$/;

            if (value.trim() !== "" && !regex.test(value)) {
                return;
            }
        }

        setFormData((prevState)=>({ ...prevState, [key]: value }));

        if (formDataError[key]){
            setFormDataError((prevState)=>({ ...prevState, [key]: "" }));
        }
    };

    const handleOnClose = () => {
      onClose();
      resetFormData();
    };

    const resetFormData = () => {
        setFormData({
            title: "",
            description: "",
            unit_cost: "",
            unit_price: "",
            billing_frequency: "",
            close_date: ""
        });
        setFormDataError({
            title: "",
            description: "",
            unit_cost: "",
            unit_price: "",
            billing_frequency: "",
            close_date: ""
        });
    };

    const validateFormData = () => {
      let inValid = false;
      const errors = {
          title: "",
          description: "",
          unit_cost: "",
          unit_price: "",
          billing_frequency: "",
          close_date: ""
      }

      if (formData.title.trim() === ""){
          inValid = true;

          errors.title = "Product title is required";
      }

      if (formData.title.length > 100){
        inValid = true;

        errors.title = "Product title  must be less than 100 characters";
    }

    if (formData.description.length > 255){
        inValid = true;

        errors.description = "Description  must be less than 255 characters";
    }

    if (formData.unit_cost.trim() === ""){
        inValid = true;

        errors.unit_cost = "Product unit cost is required";
    }

    if (formData.unit_cost > 1000000){
        inValid = true;

        errors.unit_cost = "Product unit cost must be less then 1000000";
    }

    if (formData.unit_price.trim() === ""){
        inValid = true;

        errors.unit_price = "Product unit price is required";
    }

    if (formData.unit_price > 1000000){
        inValid = true;

        errors.unit_price = "Product unit price must be less then 1000000";
    }

    if (formData.billing_frequency > 1000000){
        inValid = true;

        errors.billing_frequency = "Billing Frequency must be less then 1000000";
    }

      if (inValid){
          setFormDataError(errors);
      }

      return inValid;
    };

    const handleSubmitFormData = async () => {
      try{
        if (validateFormData()){
            return;
        }

        setSubmitting(true);
        const response = await createUserProduct(formData);

        if (response && response.success){
            if (response.data.id){
                window.showNotification(RESPONSE_STATUS.SUCCESS, response.message);
                if (createDealAfterAddProduct) {
                    const dealResponse = await window.globalAddDeal(true, {
                        callback: async (data) => {
                            if (data && data?.contact_stage_id) {
                                const dealStatusResponse = await changeDealStatus({
                                    stage_id: data?.contact_stage_id,
                                    status: CONTACT_STAGE.WIN
                                });

                                let contactStage = data.contact_stage;
                                let contactStageProducts = data.contact_stage_products;

                                if (dealStatusResponse && dealStatusResponse.data?.status === RESPONSE_STATUS.SUCCESS) {
                                    contactStageProducts.forEach((contactStageProduct) => {
                                        appendNewContactProduct({
                                            userProductTitle: contactStageProduct.title,
                                            userProductUnitCost: parseFloat(contactStageProduct.unit_cost),
                                            userProductUnitPrice: parseFloat(contactStageProduct.unit_price),
                                            dealTitle: contactStage.title,
                                            dealCreatedById: contactStage.created_by,
                                            dealClosedById: contactStage.created_by,
                                            dealProductValue: parseFloat(contactStageProduct.unit_price),
                                            dealCreatedDate: contactStage.created_at,
                                            dealClosedDate: contactStage.created_at,
                                            pipelineTitle: data?.pipelineTitle,
                                            stageTitle: data?.stageTitle,
                                        });
                                    });
                                    setCreateDealAfterAddProduct(false);
                                }
                            }
                        },
                        data: {
                            contact_id: contact.id,
                            user_contact_stage_products: [
                                {
                                    user_product_id: response.data.id,
                                    value: response.data.unit_price,
                                    status: response.data.status                                
                                }
                            ]
                        },
                        edit: false,
                    });               
                }
                handleOnClose();
                setCreateProduct(response.data);
            }else {
                window.showNotification(RESPONSE_STATUS.SUCCESS, "Unable to create product. Try again later");
            }
        }else {
            if (response.data && response.data.status === CONTACT_PRODUCTS.PRODUCT_STATUS.TRASH){
                setDuplicateTrashProduct(response.data);
                setFormDataError((prevState)=>({ ...prevState, title: "Product already exists in trash." }));
                window.showNotification(RESPONSE_STATUS.ERROR, "Product already exists it trash.");
            }else {
                setFormDataError((prevState)=>({ ...prevState, title: response.message }));
                window.showNotification(RESPONSE_STATUS.ERROR, response.message);
            }
        }
      }catch (err){

      }finally {
          setSubmitting(false);
      }
    };

    const handleRestoreProduct = async () => {
        try{
            const response = await userProductStatus({ id: duplicateTrashProduct.id, status: CONTACT_PRODUCTS.PRODUCT_STATUS.ACTIVE });

            if (response && response.success){
                window.globalAddDeal(true, {
                    callback: async (data) => {
                        if (data && data?.contact_stage_id) {
                            const response = await changeDealStatus({ stage_id: data?.contact_stage_id, status: CONTACT_STAGE.WIN });

                            let contactStage = data.contact_stage;
                            let contactStageProducts = data.contact_stage_products;

                            if (response && response.data?.status === RESPONSE_STATUS.SUCCESS){
                                contactStageProducts.forEach((contactStageProduct)=>{
                                    console.log(contactStageProduct);
                                    appendNewContactProduct({
                                        userProductTitle: contactStageProduct.title,
                                        userProductUnitCost: parseFloat(contactStageProduct.unit_cost),
                                        userProductUnitPrice: parseFloat(contactStageProduct.unit_price),
                                        dealTitle: contactStage.title,
                                        dealCreatedById: contactStage.created_by,
                                        dealClosedById: contactStage.created_by,
                                        dealProductValue: parseFloat(contactStageProduct.unit_price),
                                        dealCreatedDate: contactStage.created_at,
                                        dealClosedDate: contactStage.created_at,
                                        pipelineTitle: data?.pipelineTitle,
                                        stageTitle: data?.stageTitle,
                                    });
                                });
                            }
                        }
                    },
                    data: {
                        contact_id: contact.id,
                        user_contact_stage_products: [
                            {
                                user_product_id: duplicateTrashProduct.id,
                                value: formData.unit_price,
                                status: duplicateTrashProduct.status
                            }
                        ]
                    },
                    edit: false,
                });
                handleOnClose();
            }
        }catch (err){

        }
    }

    const handleNumberInput = (e) => {
        const { value,name } = e.target;

        const decimalRegex = /^\d{0,6}(\.\d{0,2})?$/;
    
        if (decimalRegex.test(value)) {
          const [integerPart, decimalPart] = value.split(".");
    
          if (integerPart.length <= 6) {
            if (!decimalPart || decimalPart.length <= 2) {
                setFormData((prevState)=>({ ...prevState, [name]: value }));
            }
          }
        }
      };

    return(
        <Dialog open={open} onClose={handleOnClose}>
            <ModalBodyWrapper>
                <ModalHead>
                    <ModalTitle>
                        Add a New Product Or Service
                    </ModalTitle>
                    <Divider style={{ width: "165px", backgroundColor: "#316aff" }}/>
                    {
                        contactLabel &&
                        <ModalContactChip>
                            <BootstrapTooltip placement={"top"} arrow title={contactLabel}>
                                <span>{contactLabel}</span>
                            </BootstrapTooltip>
                        </ModalContactChip>
                    }
                </ModalHead>

                <ModalBody>
                    <ModalFormGroup>
                        <ModalFormLabel>
                            Name of Product / Service
                        </ModalFormLabel>
                        <ModalFormInput>
                            <BasicInput
                                fullWidth
                                name={"title"}
                                value={formData.title}
                                onChange={handleChangeFormData}
                                required
                            />
                            {
                                formDataError.title &&
                                <ErrorMessage>{formDataError.title} { duplicateTrashProduct ? <span className="restoreText" onClick={handleRestoreProduct}>Restore</span> : null }</ErrorMessage>
                            }
                        </ModalFormInput>
                    </ModalFormGroup>

                    <ModalFormGroupSectionHead>
                        <ModalFormLabel style={{ color: "#316aff" }}>
                            Product / Service Information
                        </ModalFormLabel>
                        <Divider style={{
                            width: "330px",
                            backgroundColor: "#316aff",
                         }}
                        />
                    </ModalFormGroupSectionHead>

                    <ModalFormGroup style={{ marginTop: "16px" }}>
                        <ModalFormLabel>
                            Description
                        </ModalFormLabel>
                        <ModalFormInput>
                            <BasicInput
                                fullWidth
                                multiline
                                name={"description"}
                                value={formData.description}
                                onChange={handleChangeFormData}
                                required
                            />
                            {
                                formDataError.description &&
                                <ErrorMessage>{formDataError.description}</ErrorMessage>
                            }
                        </ModalFormInput>
                    </ModalFormGroup>

                    <ModalFormGroupWrapper>
                        <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel>
                                Unit Cost
                            </ModalFormLabel>
                            <ModalFormInput style={{position:'relative'}}>
                                <BasicInput
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 999999, className: "pricePadding" }}
                                    name={"unit_cost"}
                                    value={formData.unit_cost}
                                    onChange={handleNumberInput}
                                />
                                <AttachMoney fontSize="small" style={{position:'absolute', left:'6px', top:'8px',color:'var(--dark_blue)'}}/>
                                {
                                    formDataError.unit_cost &&
                                    <ErrorMessage>{formDataError.unit_cost}</ErrorMessage>
                                }
                            </ModalFormInput>
                        </ModalFormGroup>
                        <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel>
                                Unit Price
                            </ModalFormLabel>
                            <ModalFormInput style={{position:'relative'}}>
                                <BasicInput
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 999999,className:"pricePadding",  }}
                                    name={"unit_price"}
                                    value={formData.unit_price}
                                    onChange={handleNumberInput}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">$</InputAdornment>
                                        ),
                                    }}
                                />
                                 <AttachMoney fontSize="small" style={{position:'absolute', left:'6px', top:'8px',color:'var(--dark_blue)'}}/>
                                {
                                    formDataError.unit_price &&
                                    <ErrorMessage>{formDataError.unit_price}</ErrorMessage>
                                }
                            </ModalFormInput>
                        </ModalFormGroup>
                    </ModalFormGroupWrapper>

                    <ModalFormGroupWrapper>
                        <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel>
                                Billing Frequency
                            </ModalFormLabel>
                            <ModalFormInput>
                                <BasicInput
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 999999 }}
                                    name={"billing_frequency"}
                                    value={formData.billing_frequency}
                                    onChange={handleNumberInput}
                                />
                                {
                                    formDataError.billing_frequency &&
                                    <ErrorMessage>{formDataError.billing_frequency}</ErrorMessage>
                                }
                            </ModalFormInput>
                        </ModalFormGroup>
                        {/* <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel>
                                Close Date
                            </ModalFormLabel>
                            <ModalFormInput>
                                <BasicInput
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    name={"close_date"}
                                    value={formData.close_date}
                                    onChange={handleChangeFormData}
                                />
                                {
                                    formDataError.close_date &&
                                    <ErrorMessage>{formDataError.close_date}</ErrorMessage>
                                }
                            </ModalFormInput>
                        </ModalFormGroup> */}
                    </ModalFormGroupWrapper>
                    <ModalFormGroup style={{ marginTop: '24px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={'primary'}
                                    size={'small'}
                                    checked={createDealAfterAddProduct}
                                    onChange={() => { setCreateDealAfterAddProduct((prev)=>!prev) }}
                                />
                            }
                            style={{ color: '#0E1B3D', fontSize: '16px !important', fontWeight: '600' }}
                            label=' Include this manual entry in sales reporting and goal calculations'
                        />
                        <Typography style={{ fontSize: '12px', paddingLeft: '28px', color: '#808080',fontWeight:'400' }}>
                            For accurate reporting you must associate this entry with a deal
                        </Typography>
                    </ModalFormGroup>
                </ModalBody>

                <ModalFoot>
                    <BasicTrButton style={{ marginRight: "10px" }} onClick={handleOnClose}>
                        Cancel
                    </BasicTrButton>
                    <BasicButton disabled={submitting} onClick={handleSubmitFormData}>
                        Save
                    </BasicButton>
                </ModalFoot>
            </ModalBodyWrapper>
        </Dialog>
    );
};

export default AddProductModal;