import React from "react";
import {
    Typography,
    Grid,
    Divider,
} from "@material-ui/core";
import {
    ContentProductCardHead,
    ContentProductCardPlainGroup,
    ContentProductCardTeamMember,
    ContentProductCardTitle,
    ContentProductCardWrapper,
    ContentProductCardBody,
    ContentProductCardFooter
} from "./ProductStyles";
import Utils from "../../../../helpers/Utils";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";

const ContactProductCard = ({ contactProduct, findSubUserById }) => {

    return (
        <ContentProductCardWrapper>
            <ContentProductCardHead>
                <ContentProductCardTeamMember>
                    <ContentProductCardPlainGroup>
                        <BootstrapTooltip title={contactProduct.userProductTitle} arrow>
                        <ContentProductCardTitle style={{width:'100%', whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{contactProduct.userProductTitle}</ContentProductCardTitle>
                        </BootstrapTooltip>
                        <Typography variant="body2">Created By</Typography>
                        <Typography variant="body1">{findSubUserById(contactProduct.dealCreatedById, "full_name")}</Typography>
                    </ContentProductCardPlainGroup>
                    
                        <Typography variant="h5" style={{ fontWeight: "bold", color: "#fff", width:'30%', textAlign:'end'}}>{Utils.formatCurrency(contactProduct.dealProductValue, "$")}</Typography>
                    
                </ContentProductCardTeamMember>
            </ContentProductCardHead>

            <ContentProductCardBody>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Deal Created Date</Typography>
                        <Typography variant="body1">{window.globalTimezoneConversionToDifferentTimezone(contactProduct.dealCreatedDate, "UTC", "", "MM/DD/YYYY")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Deal Closed Date</Typography>
                        <Typography variant="body1">{window.globalTimezoneConversionToDifferentTimezone(contactProduct.dealClosedDate, "UTC", "", "MM/DD/YYYY")}</Typography>
                    </Grid>
                </Grid>
                <Divider style={{margin:'8px 0px'}}/>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Deal Pipeline</Typography>
                        <Typography variant="body1">{contactProduct.pipelineTitle}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Deal Stage</Typography>
                        <Typography variant="body1">{contactProduct.stageTitle}</Typography>
                    </Grid>
                </Grid>
                <Divider style={{margin:'8px 0px'}}/>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">Deal Title</Typography>
                        <Typography style={{width:'90%', whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}} variant="body1">{contactProduct.dealTitle}</Typography>
                    </Grid>
                </Grid>
                <Divider style={{margin:'8px 0px'}}/>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Product Unit Cost</Typography>
                        <Typography variant="body1">{Utils.formatCurrency(contactProduct.userProductUnitCost, "$")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">Product Unit Price</Typography>
                        <Typography variant="body1">{Utils.formatCurrency(contactProduct.userProductUnitPrice, "$")}</Typography>
                    </Grid>
                </Grid>
            </ContentProductCardBody>

            <ContentProductCardFooter>
                <Typography variant="body2" color="textSecondary">Closed By:</Typography>
                <Typography variant="body1">{findSubUserById(contactProduct.dealClosedById, "full_name")}</Typography>
            </ContentProductCardFooter>
        </ContentProductCardWrapper>
    );
};

export default ContactProductCard;