import React, {useEffect, useState} from "react";
import { KeyboardArrowDown } from "@material-ui/icons";
import {
    Checkbox,
    TableBody,
    TableCell,
    TableContainer,
    Box,
    TableHead,
    TableRow,
    Table,
    MenuItem,
    FormControl,
    Select,
    Typography,
    CircularProgress, FormControlLabel, withStyles, Button,
} from "@material-ui/core";

import {
    BootstrapInput,
    validateFilterAction,
    isNumber
} from "../FileReportDetailsSingle";
import Pagination from "../Pagination";
import Styles from "../FileReportDetails.module.css";
import { IconList } from "../../../globals/IconList";
import ModalAssignToTag from "../actions/ModalAssignToTag";
import ModalAssignToList from "../actions/ModalAssignToList";
import ModalExportContact from "../actions/ModalExportContact";
import NonAddressTableWrapper from "./NonAddressTableWrapper";
import { CoreButton } from "../../enhanceData/commons/CoreButton";
import ModalListForStageAdd from "../actions/ModalListForStageAdd";
import ModalAssignToCampaign from "../actions/ModalAssignToCampaign";
import NewConfirmAlert from "../../../common/new-alert/NewConfirmAlert";
import ModalAssignToPowerDialer from "../actions/ModalAssignToPowerDialer";
import { cleanContactsActionsApi, cleanDataFilesDetailsApi } from "../../../../api/cleanDataApi";
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from "../../../globals/Dropdown/GlobalDropdown";
import NonAddressItemRegular from "./NonAddressItemRegular";
import NonAddressComplexItem from "./NonAddressComplexItem";
import {deleteCleanContacts} from "../../../../api/contactApi";
import {EnhanceSearchStyledInput} from "../../CommonCss";
import StripedProgress from "../../StripedProgress";
const ActionButton = withStyles((theme) => ({
    root: {
        backgroundColor: '#00ff91',
        color: 'black',
        '&:hover': {
            backgroundColor: '#80ffbf',
        },
        '&:active': {
            backgroundColor: '#00cc68',
        },
    },
}))(Button);

const NonAddressReportDetails = ({ filter, fileSource = "ALL" }) => {
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [limit, setLimit] = useState(25);
    const [loading, setLoading] = useState(false);
    const [fileContacts, setFileContacts] = useState(null);
    const [searchLead, setSearchLead] = useState('');
    const [filterBy, setFilterBy] = useState({
        invalidNumbers: false,
        validNumbers: false,
        invalidEmails: false,
        validEmails: false,
        dncOn: false,
        dncOff: false,
        skipTrace: false,
        landline: false,
        mobile: false,
    });
    const [selectedFileContactIds, setSelectedFileContactIds] = useState([]);
    const [showListModal, setShowListModal] = useState(false);
    const [showAddToCampaignModal, setShowAddToCampaignModal] = useState(false);
    const [showAddToPowerDialerModal, setShowAddToPowerDialerModal] = useState(false);
    const [showTagModal, setShowTagModal] = useState(false);
    const [openAddStageToContactModal, setOpenAddStageToContactModal] = useState(false);
    const [openExportFileModal, setOpenExportFileModal] = useState(false);
    const [exportAll, setExportAll] = useState(false);
    const [currentPageIds, setCurrentPageIds] = useState([]);
    const [resetSelect, setResetSelect] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [isActiveActionBtn, setIsActiveActionBtn] = useState(false);
    const [isResultProcessing, setIsResultProcessing] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            let currentURL = window.location.href;
            if (currentURL.includes("&redirect=processing")) {
                setIsResultProcessing(true);
            }
        }, 1000);
    }, []);

    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            const context = this;
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const handleCurrentPageIds = (id) => {
        if (!currentPageIds.includes(parseInt(id))){
            setCurrentPageIds((prevState)=>([ ...prevState, parseInt(id) ]));
        }
    };

    const filterArray = filter.map((value) => {
        const result = validateFilterAction.find((filterValue) => filterValue.props === value);
        if (!result) throw Error("invalid filterBy type passed to component");
        return result;
    });

    const fetchFileDetails = () => {
        setLoading(true);
        cleanDataFilesDetailsApi({
            page: page,
            limit: limit,
            totalPage: totalPage,
            search: searchLead,
            cleanDataRequestId: null,
            filterBy: Object.values(filterBy).every((value) => value === false) ? null : filterBy,
            fileSource,
        })
            .then((res) => {
                res = res.data;

                if (res.success) {
                    setCurrentPageIds([]);
                    setFileContacts(res.data);
                    setTotalPage(res.totalPage);
                    setTotalCount(res.totalCount);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleFilterChange = (name, value) => {
        setFilterBy({ ...filterBy, [name]: value });
    };

    const debouncedSearch = debounce((value) => {
        setPage(1);
        setSearchLead(value);
    }, 1000);

    const handleInputChange = (e) => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const handleAddAllContactToCRM = () => {
        NewConfirmAlert({
            onSubmit: () => {
                const payload = {
                    cleanContactIds: [],
                    filterType: "ALL",
                    action: "ADD_TO_CRM",
                };

                if (filterBy) {
                    if (filterBy.invalidNumbers) {
                        payload.numberStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    } else if (filterBy.validNumbers) {
                        payload.numberStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }

                    if (filterBy.invalidEmails) {
                        payload.emailStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    } else if (filterBy.validEmails) {
                        payload.emailStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }
                }
                cleanContactsActionsApi(payload)
                    .then((res) => {
                        if (res && res.success) {
                            window.showNotification("success", res.message);
                        } else {
                            window.showNotification("error", "Something went wrong try again later");
                        }
                    })
                    .finally(() => {});
            },
            title: "Are your sure?",
            description: "You are about to add all contacts to CRM",
            cancelText: "Cancel",
            submitText: "Add",
            width: "480px",
        });
    };

    const handleAddSelectedContactToCRM = () => {
        if (selectedFileContactIds.length === 0) {
            window.showNotification("ERROR", "Check at least one contact");
            return;
        }
        NewConfirmAlert({
            onSubmit: () => {
                const payload = {
                    cleanContactIds: selectedFileContactIds,
                    filterType: "SEGMENT",
                    action: "ADD_TO_CRM",
                };

                if (filterBy) {
                    if (filterBy.invalidNumbers) {
                        payload.numberStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    } else if (filterBy.validNumbers) {
                        payload.numberStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }

                    if (filterBy.invalidEmails) {
                        payload.emailStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    } else if (filterBy.validEmails) {
                        payload.emailStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }
                }
                cleanContactsActionsApi(payload)
                    .then((res) => {
                        if (res && res.success) {
                            window.showNotification("success", res.message);
                        } else {
                            window.showNotification("error", "Something went wrong try again later");
                        }
                    })
                    .finally(() => {});
            },
            title: "Are your sure?",
            description: "You are about to add selected contacts to CRM",
            cancelText: "Cancel",
            submitText: "Add",
            width: "480px",
        });
    };

    const handleDeleteSelectedContactToCRM = () => {
        if (selectedFileContactIds.length === 0) {
            window.showNotification("ERROR", "Check at least one contact");
            return;
        }
        NewConfirmAlert({
            onSubmit: async () => {
                try{
                    const res = await deleteCleanContacts({
                        page: page,
                        limit: limit,
                        totalPage: totalPage,
                        cleanDataRequestId: null,
                        filterBy: Object.values(filterBy).every((value) => value === false) ? null : filterBy,
                        fileSource: fileSource,
                        deletedContactIds: selectedFileContactIds
                    });

                    if (res && res.success){
                        let updatedCurrentIds = currentPageIds.filter(element => !selectedFileContactIds.includes(element));
                        setCurrentPageIds(updatedCurrentIds);
                        setFileContacts(res.data);
                        setTotalPage(res.totalPage);
                        setSelectedFileContactIds([]);
                        setResetSelect(true);
                        window.showNotification("success", "The selected contacts have been deleted");
                    }else {
                        window.showNotification("error", "Something went wrong try again later");
                    }
                }catch (err){
                    console.log(err);
                    window.showNotification("error", "Something went wrong try again later");
                }
            },
            title: "Are your sure?",
            description: "You are about to delete selected contacts",
            cancelText: "Cancel",
            submitText: "Yes",
            width: "480px",
        });
    };

    const handleSelectContact = (id) => {
        if (selectedFileContactIds.includes(id)) {
            setSelectedFileContactIds(selectedFileContactIds.filter((selectedFileContactId) => selectedFileContactId !== id));
        } else {
            setSelectedFileContactIds([...selectedFileContactIds, id]);
        }
    };

    const isSelected = (id) => {
        return selectedFileContactIds.includes(id);
    };

    const selectAddressGroup = (ids) => {
        setSelectedFileContactIds((prevState)=>([...prevState, ...ids]));
    };

    const deSelectAddressGroup = (ids) => {
        setSelectedFileContactIds((prevState)=>(prevState.filter((id)=>(!ids.includes(id)))));
    };

    useEffect(() => {
        if (page !== "") {
            fetchFileDetails();
        }
    }, [page, limit, filterBy, searchLead]);

    const handleClick = () => {
        setIsActiveActionBtn(!isActiveActionBtn);
    };

    const handleBlur = () => {
        setIsActiveActionBtn(false);
    };

    return (
        <div className={Styles.pageWrapper}>
            <div className={Styles.pageHeader}>
                <div style={{ display: "grid" }}>
                    <Typography variant={"h6"}>Single/Multiple Validation Details</Typography>
                    <Typography variant={"caption"}>
                        Check the status of single/multiple email address(s) and phone number(s) you've validated
                    </Typography>
                </div>
                <div className={Styles.pageHeaderAction}></div>
            </div>

            <div className={Styles.pageBody}>
                <div className={`${Styles.detailsPane} ${Styles.extPadding}`}>
                    <div className={Styles.detailsPaneHeader}>
                        <div className={Styles.filterWrapper}>
                            <Typography variant={"subtitle1"}>Filter Results: </Typography>
                            {filterArray.map((value, index) => {
                                return (
                                    <div key={index} className={Styles.dFlexAlignCenter}>
                                        <Checkbox
                                            size={"small"}
                                            checked={filterBy[value.key]}
                                            onClick={() => {
                                                setPage(1);
                                                handleFilterChange(value.key, !filterBy[value.key]);
                                            }}
                                            color='primary'
                                        />
                                        <Typography variant={"body2"}>{value.title}</Typography>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='record_search' style={{paddingRight:"5px"}}>
                            <EnhanceSearchStyledInput
                                placeholder={"Search . . ."}
                                variant='outlined'
                                fullWidth
                                onChange={(e)=> {
                                    handleInputChange(e);
                                }}
                            />
                            <div className='search_icon'>
                                <svg
                                    width='16'
                                    height='16'
                                    viewBox='0 0 16 16'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z'
                                        fill='#133159'
                                        fill-opacity='0.65'
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <GlobalDropdown className='add__action_dropdown' placement='bottomLeft'>
                            <GlobalDropDownButton>
                                {/*<CoreButton variant='contained' color='primary' endIcon={<KeyboardArrowDown />}>*/}
                                {/*    Actions*/}
                                {/*</CoreButton>*/}
                                <ActionButton variant='contained' endIcon={<KeyboardArrowDown />} onClick={handleClick}
                                              onBlur={handleBlur}
                                              className={isActiveActionBtn ? 'active' : ''}>
                                    Actions
                                </ActionButton>
                            </GlobalDropDownButton>
                            <GlobalDropDownItem
                                onClick={handleAddAllContactToCRM}
                                title='Add All Contact to CRM'
                                onClickHide={true}
                                icon={IconList.addToContactSVG}
                            />
                            <GlobalDropDownItem
                                onClick={handleAddSelectedContactToCRM}
                                title='Add Selected Contact to CRM'
                                onClickHide={true}
                                icon={IconList.addToContactSVG}
                            />
                            <GlobalDropDownItem
                                onClick={() => {
                                    if (selectedFileContactIds.length === 0) {
                                        window.showNotification("ERROR", "Check at least one contact");
                                        return;
                                    }
                                    setShowListModal(true);
                                }}
                                title='Add To List'
                                onClickHide={true}
                                icon={IconList.addToListSVG}
                            />
                            <GlobalDropDownItem
                                onClick={() => {
                                    if (selectedFileContactIds.length === 0) {
                                        window.showNotification("ERROR", "Check at least one contact");
                                        return;
                                    }
                                    setShowAddToCampaignModal(true);
                                }}
                                title='Add To Campaign'
                                onClickHide={true}
                                icon={IconList.addToContactSVG}
                            />
                            <GlobalDropDownItem
                                onClick={() => {
                                    if (selectedFileContactIds.length === 0) {
                                        window.showNotification("ERROR", "Check at least one contact");
                                        return;
                                    }
                                    setShowAddToPowerDialerModal(true);
                                }}
                                title='Add To Power Dialer'
                                onClickHide={true}
                                icon={IconList.addToContactSVG}
                            />
                            <GlobalDropDownItem
                                onClick={() => {
                                    if (selectedFileContactIds.length === 0) {
                                        window.showNotification("ERROR", "Check at least one contact");
                                        return;
                                    }
                                    setShowTagModal(true);
                                }}
                                title='Assign Tag'
                                onClickHide={true}
                                icon={IconList.addTagSVG}
                            />
                            <GlobalDropDownItem
                                onClick={() => {
                                    if (selectedFileContactIds.length === 0) {
                                        window.showNotification("ERROR", "Check at least one contact");
                                        return;
                                    }
                                    setOpenAddStageToContactModal(true);
                                }}
                                title='Add to Pipeline Stage'
                                onClickHide={true}
                                icon={IconList.addToPipelineSVG}
                            />
                            <GlobalDropDownItem
                                onClick={() => {
                                    setExportAll(true);
                                    setOpenExportFileModal(true);
                                }}
                                title='Export All Contacts'
                                onClickHide={true}
                                icon={IconList.exportContactSVG}
                            />
                            <GlobalDropDownItem
                                onClick={() => {
                                    if (selectedFileContactIds.length === 0) {
                                        window.showNotification("ERROR", "Check at least one contact");
                                        return;
                                    }
                                    setExportAll(false);
                                    setOpenExportFileModal(true);
                                }}
                                title='Export Selected Contacts'
                                onClickHide={true}
                                icon={IconList.exportContactSVG}
                            />
                            <GlobalDropDownItem
                                onClick={handleDeleteSelectedContactToCRM}
                                title='Delete Selected Contacts'
                                onClickHide={true}
                                icon={IconList.deleteSelectedContactSVG}
                            />
                        </GlobalDropdown>
                    </div>
                    <div className={Styles.detailsPaneBody}>
                        <NonAddressTableWrapper variant={"outlined"}>
                            <TableContainer>
                                <Table stickyHeader className={"skip-tracing-table"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={Styles.enhance_data_dnc_result_header}>
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                style={{ color: "#fff" }}
                                                                indeterminate={((selectedFileContactIds.length > 0) && (currentPageIds.length > selectedFileContactIds.length))}
                                                            />
                                                        }
                                                        checked={((currentPageIds.length) > 0 && (currentPageIds.length === selectedFileContactIds.length))}
                                                        onChange={(event, checked)=>{
                                                            if (checked){
                                                                setSelectedFileContactIds(currentPageIds);
                                                            }else {
                                                                setSelectedFileContactIds([]);
                                                            }
                                                        }}
                                                    />
                                                    Name
                                                </Box>
                                            </TableCell>
                                            <TableCell className={Styles.enhance_data_dnc_result_header}>Email</TableCell>
                                            <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Email Status</TableCell>
                                            <TableCell className={Styles.enhance_data_dnc_result_header}>Number</TableCell>
                                            <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Number Status</TableCell>
                                            <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Landline/Mobile</TableCell>
                                            <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>DNC Status</TableCell>
                                            <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>SkipTrace Status</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    { isResultProcessing ?
                                        <TableRow>
                                            <TableCell colSpan={9}>
                                                <StripedProgress
                                                    width={100}
                                                    text={
                                                        "Processing... this may take a few minutes depending on the number of records"
                                                    }
                                                />
                                            </TableCell>
                                    </TableRow> : <></>}
                                    <TableBody>
                                        {loading ? (
                                            <TableRow>
                                                <TableCell colSpan={9}>
                                                    <div className={Styles.loaderWrapper}>
                                                        <CircularProgress />
                                                        <h5 className={Styles.marginTop}>Getting file contact list....</h5>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : ( totalPage > 0 ?
                                                Object.entries(fileContacts).map(([key, contacts], index)=>(
                                                    Object.values(contacts)[0]['id'] ?
                                                        <NonAddressItemRegular
                                                            contact={Object.values(contacts)[0]}
                                                            index={index}
                                                            isSelected={isSelected}
                                                            onSelect={handleSelectContact}
                                                            collectIds={handleCurrentPageIds}
                                                        /> :
                                                        <NonAddressComplexItem
                                                            address={Object.keys(contacts)[0]}
                                                            contacts={Object.values(contacts)[0]}
                                                            index={index}
                                                            isSelected={isSelected}
                                                            onSelect={handleSelectContact}
                                                            selectAddressGroup={selectAddressGroup}
                                                            deSelectAddressGroup={deSelectAddressGroup}
                                                            collectIds={handleCurrentPageIds}
                                                            allSelected={((currentPageIds.length) > 0 && (currentPageIds.length === selectedFileContactIds.length))}
                                                            resetSelect={resetSelect}
                                                            setResetSelect={setResetSelect}
                                                        />
                                                )) :
                                                <TableRow>
                                                    <TableCell colSpan={9}>
                                                        <div className={Styles.loaderWrapper}>
                                                            <h5 className={Styles.marginTop}>No contact found!</h5>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                        )}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </NonAddressTableWrapper>

                        <Box display='flex' justifyContent='space-between' alignItems='center' width={"60%"}>
                            {totalCount ? <Typography variant="body1" style={{ fontSize: "14px", fontWeight: 600, color: "#64587e" }}>Showing <strong>{currentPageIds.length}</strong> of <strong>{totalCount}</strong> contacts</Typography> : null}
                            <Box display='flex' alignItems='center'>
                                {
                                    totalPage ?
                                    <FormControl className={Styles.formControl}>
                                        <Select
                                            style={{ width: "200px" }}
                                            name='perPage'
                                            value={limit}
                                            displayEmpty
                                            onChange={(e) => {
                                                setPage(1);
                                                setSelectedFileContactIds([]);
                                                setLimit(e.target.value);
                                            }}
                                            input={<BootstrapInput />}
                                            inputProps={{ "aria-label": "Without label" }}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                        >
                                            <MenuItem className='dropdownhelper-menuitem-class' value={""} disabled>
                                                # Results
                                            </MenuItem>
                                            <MenuItem className='dropdownhelper-menuitem-class' value={25}>
                                                25 Results
                                            </MenuItem>
                                            <MenuItem className='dropdownhelper-menuitem-class' value={50}>
                                                50 Results
                                            </MenuItem>
                                            <MenuItem className='dropdownhelper-menuitem-class' value={100}>
                                                100 Results
                                            </MenuItem>
                                            <MenuItem className='dropdownhelper-menuitem-class' value={250}>
                                                250 Results
                                            </MenuItem>
                                            <MenuItem className='dropdownhelper-menuitem-class' value={500}>
                                                500 Results
                                            </MenuItem>
                                        </Select>
                                    </FormControl> :
                                        <div/>
                                }

                                <Pagination
                                    page={page}
                                    totalPage={totalPage}
                                    callback={(uPage) => {
                                        setPage(uPage);
                                    }}
                                />
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>

            {showListModal && (
                <ModalAssignToList
                    open={showListModal}
                    selectedFileContactIds={selectedFileContactIds}
                    onClose={() => setShowListModal(false)}
                    filterBy={filterBy}
                    tab={fileSource}
                />
            )}

            {showAddToCampaignModal && (
                <ModalAssignToCampaign
                    open={showAddToCampaignModal}
                    onClose={() => setShowAddToCampaignModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    filterBy={filterBy}
                    tab={fileSource}
                />
            )}

            {showAddToPowerDialerModal && (
                <ModalAssignToPowerDialer
                    open={showAddToPowerDialerModal}
                    onClose={() => setShowAddToPowerDialerModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    filterBy={filterBy}
                    tab={fileSource}
                />
            )}

            {showTagModal && (
                <ModalAssignToTag
                    open={showTagModal}
                    selectedFileContactIds={selectedFileContactIds}
                    filterBy={filterBy}
                    onClose={() => setShowTagModal(false)}
                    tab={fileSource}
                />
            )}

            {openAddStageToContactModal && (
                <ModalListForStageAdd
                    open={openAddStageToContactModal}
                    onClose={() => setOpenAddStageToContactModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    filterBy={filterBy}
                    tab={fileSource}
                />
            )}

            {openExportFileModal &&
                <ModalExportContact
                    exportAll={exportAll}
                    filter={filterBy}
                    openExportFileModal={openExportFileModal}
                    onClose={() => setOpenExportFileModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    tab={fileSource}
                />
            }
        </div>
    );
};

export default NonAddressReportDetails;
