import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/contactActionTypes'
import * as CONTACT_API from "../api/contactApi";
import Utils from "../../src/helpers/Utils";


function* updateContactWatcher() {
    yield takeEvery(ACTION.UPDATE_CONTACT, attemptUpdateContact)
    yield takeEvery(ACTION.ADD_EXTRA_EMAIL, attemptAddExtraEmail)
    yield takeEvery(ACTION.FETCH_CONTACT_ADDITIONAL_CONTACTS, fetchContactAdditionalContacts)
}

function* attemptUpdateContact(action) {
    try {
        let response = null
        if(action.payload.callback !== undefined){
            response = yield call(CONTACT_API.updateContactInfo, action.payload.payload);
            action.payload.callback(response?.data);
        }
        else{
            response = yield call(CONTACT_API.updateContactInfo, action.payload);
        }
        let responseData = response.data.original;
        if (responseData.success) {
            yield put({type: ACTION.UPDATE_CONTACT_COLUMN, payload: responseData.data});
        } else {
        }

    } catch (error) {
        console.log(error);
    }

}

function* attemptAddExtraEmail(action) {
    try {
        let response = null
        if(action.payload.callback !== undefined){
            response = yield call(CONTACT_API.addExtraEmailApi, action.payload.payload);
            action.payload.callback(response?.data);
        }
        else{
            console.log(action.payload,"ok")
            response = yield call(CONTACT_API.addExtraEmailApi, action.payload);
        }
        let responseData = response.data.original;
        if (responseData.success) {
            yield put({type: ACTION.UPDATE_CONTACT_COLUMN, payload: responseData.data});
        } else {
        }

    } catch (error) {
        console.log(error);
    }

}

function* getNoteModelOpenWatcher() {
    yield takeEvery(ACTION.SHOW_NOTE_MODAL, attemptNoteModelOpen)
}

function* attemptNoteModelOpen(action) {
    yield put({type: ACTION.SET_SHOW_NOTE_MODAL, payload:action.payload.status });
}
function* getTaskModelOpenWatcher() {
    yield takeEvery(ACTION.SHOW_TASK_MODAL, attemptTaskModelOpen)
}

function* attemptTaskModelOpen(action) {
    yield put({type: ACTION.SET_SHOW_TASK_MODAL, payload:action.payload.status });
}

function* VideoEmailWatcher() {
    yield takeEvery(ACTION.FETCH_VIDEO_FOLDERS, fetchVideoFoldersFlow);
    yield takeEvery(ACTION.FETCH_VIDEOS, fetchVideosFlow);
    yield takeEvery(ACTION.DELETE_VIDEOS, deleteVideosFlow);
    yield takeEvery(ACTION.FETCH_MESSAGE_TEMPLATES, fetchMessageTemplatesFlow);
}

function* fetchVideoFoldersFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CONTACT_API.fetchVideoFolders, payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.STORE_VIDEO_FOLDERS, payload: responseData.data});
        }

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchVideosFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CONTACT_API.fetchVideos, payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.STORE_VIDEOS, payload: {videos: responseData.data, s3ServerVideoUrl: responseData.s3ServerVideoUrl, videoLoader: false}});
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteVideosFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CONTACT_API.deleteVideo, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchMessageTemplatesFlow(action){
    try {
        const response = yield call(CONTACT_API.getMessageTemplate, action.payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({ type: ACTION.STORE_MESSAGE_TEMPLATE, payload: responseData.html });
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getAllContactsWatcher() {
    yield takeEvery(ACTION.GET_ALL_CONTACTS, attemptFetchContacts)
}

function* attemptFetchContacts(action) {
    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getAllContacts, payload.payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.SET_ALL_CONTACTS, payload: responseData.html});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);

    } catch (error) {
        console.log(error);
    }
}

function* getUserInfoWatcher() {
    yield takeEvery(ACTION.GET_USER_INFO, attemptFetchUserInfo)
}

function* attemptFetchUserInfo(action) {
    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getUserInfo, payload.payload);
        let responseData = response.data;
        // if (responseData.success) {
            yield put({type: ACTION.SET_USER_INFO, payload: responseData});
        // } else {
        // }
        if (payload.callback) payload.callback(response?.data);

    } catch (error) {
        console.log(error);
    }
}

function* getSubUserListWatcher() {
    yield takeEvery(ACTION.GET_SUBUSER_LIST, attemptGetSubUserList)
}

function* attemptGetSubUserList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getAllTeamUsers, action.payload);
        let responseData = response.data;

        if (response.status === 200) {
            yield put({type: ACTION.SET_SUBUSER_LIST, payload: responseData.users});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}


function* getUserTagListWatcher() {
    yield takeEvery(ACTION.GET_USERTAG_LIST, attemptGetUserTagList)
}

function* attemptGetUserTagList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getUserTagListNew, action.payload);
        let responseData = response.data;
        if (response.status === 200) {
            yield put({type: ACTION.SET_USERTAG_LIST, payload: responseData});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}

function* getUserSourceListWatcher() {
    yield takeEvery(ACTION.GET_USERSOURCE_LIST, attemptGetUserSourceList)
}

function* attemptGetUserSourceList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getSourceListDropdownForContactTable, action.payload);
        let responseData = response.data;
        if (response.status === 200) {
            yield put({type: ACTION.SET_USERSOURCE_LIST, payload: responseData});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}

function* getUserCustomFieldListWatcher() {
    yield takeEvery(ACTION.GET_USERCUSTOMFIELD_LIST, attemptGetUserCustomFieldList)
}

function* attemptGetUserCustomFieldList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getUserCustomField, action.payload);
        let responseData = response.data;
        if (response.status === 200) {
            yield put({type: ACTION.SET_USERCUSTOMFIELD_LIST, payload: responseData.data});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}

function* getPipelineListWatcher() {
    yield takeEvery(ACTION.GET_PIPELINE_LIST, attemptGetPipelineList)
}

function* attemptGetPipelineList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getPipelineList, action.payload);
        let responseData = response.data;
        if (response.status === 200) {
            yield put({type: ACTION.SET_PIPELINE_LIST, payload: responseData.data});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}

function* getCampaignListWatcher() {
    yield takeEvery(ACTION.GET_CAMPAIGN_LIST, attemptGetCampaignList)
}

function* getPowerDialerListWatcher() {
    yield takeEvery(ACTION.GET_POWER_DIALER_LIST, attemptGetPowerDialerList)
}

function* attemptGetCampaignList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getAllCampaignForDropdown, action.payload.payload);
        let responseData = response.data;
        if (response.status === 200) {
            yield put({type: ACTION.SET_CAMPAIGN_LIST, payload: responseData.data});
        } else {
            
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}

function* attemptGetPowerDialerList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getPowerDialerList);
        let responseData = response.data;
        if (response.status === 200) {
            yield put({type: ACTION.SET_POWER_DIALER_LIST, payload: responseData.data});
        } else {
            
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}


function* fetchContactAdditionalContacts(action){
    const { payload } = action;

    try{
        const response = yield call(CONTACT_API.getAllAdditionalContactApi, payload);

        if (response.data.success === true) {
            if (response.data.data) {
                if (Array.isArray(response.data.data)) {
                    yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactAdditionalContacts: response.data.data } })
                }
            }
        }else {
            yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactAdditionalContacts: [] } })
        }
    }catch (err){
        yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactAdditionalContacts: [] } })
    }
}

export default function* contactMiddleware() {
    yield all([
        updateContactWatcher(),getNoteModelOpenWatcher(),getTaskModelOpenWatcher(),VideoEmailWatcher(),getAllContactsWatcher(),
        getUserInfoWatcher(),getSubUserListWatcher(),getUserTagListWatcher(), getUserSourceListWatcher(),
        getUserCustomFieldListWatcher(),getPipelineListWatcher(), getCampaignListWatcher(),getPowerDialerListWatcher()])
}