import React, { useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import {
  CircularProgress,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import ActionTypes from "../ActionTypes";
import { STATUS, TAB_CLEAN_LIST } from "../Constants";
import { DataCleanContext } from "../StateProvider";
import { cleanDataFileListApi } from "../../../api/cleanDataApi";
import Pagination from "./Pagination";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { CoreButton } from "../enhanceData/commons/CoreButton";
import Styles from "./FileReport.module.css";
import numberToDollar from "../../../helpers/numberToDollar";
import StripedProgress from '../StripedProgress'

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    fontSize: "14px !important",
    borderRadius: "4px !important",
    position: "relative",
    color: "#3a414a !important",
    border: "1px solid #ced4da !important",
    padding: "10px 26px 10px 12px !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
    },
    backgroundColor: "#ffffff",
  },
}))(InputBase);

export const renderSearchType = (file) => {
  if (file && file.carrier_infos) {
    if (file.carrier_infos && file.carrier_infos === "\\") {
      return "Not Found";
    } else {
      try {
        const searchType = [];
        let carrierInfos = file.carrier_infos.replace(/\\/g, "").replace(/&quot;/g, '"');

        carrierInfos = JSON.parse(carrierInfos);
        if (carrierInfos.do_not_call) searchType.push("DNC");
        if (carrierInfos.email_verification_type) searchType.push("Email Verification");
        if (carrierInfos.phone_verification_type) searchType.push("Phone Verification");
        if (carrierInfos.skip_trace) searchType.push("Skip Trace");

        return searchType.join(", ");
      } catch (error) {
        console.log(error);
      }
      return "Not Found";
    }
  }
  return "Not Found";
};

const FileReport = ({ fileSource = "ALL", onChangeTab }) => {
  const { dispatch } = useContext(DataCleanContext);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (page !== "") {
      setLoading(true);
      cleanDataFileListApi({
        page: page,
        limit: limit,
        totalPage: totalPage,
        fileSource,
      })
        .then((res) => {
          res = res.data;
          if (res.success) {
            setFiles(res.data);
            setTotalPage(res.totalPage);
          }
        })
        .finally(() => {
          setLoading(false);
          const button = document.getElementById('viewDetailsFirstButton');

          if (button) {
            const urlParams = new URLSearchParams(window.location.search);
            const openValue = urlParams.get('open');
            if (openValue !== null && openValue === 'new') {
              button.click();
              const url = new URL(window.location.href);
              url.searchParams.delete('open');
              window.history.replaceState({}, '', url.toString());
            }
          } else {
            console.log("Button not found.");
          }
        });
    }
    const urlParams = new URLSearchParams(window.location.search);
    const redirectValue = urlParams.get('redirect');
    if (redirectValue !== null && redirectValue === 'auto') {
      setTimeout(() => {
        showViewResultPopUp();
      }, 5000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  const showViewResultPopUp = () => {
    window.globalImportEnhancePopupData(true, {
      requestModule: "CONTACT",
      callback: (res) => {
      },
    });
  }


  const renderFileUrl = (file) => {
    if (file && file.aws3_info) {
      if (file.aws3_info && file.aws3_info === "\\") {
        return "";
      } else {
        let aws3Info = file.aws3_info.replace(/\\/g, "").replace(/&quot;/g, '"');
        try {
          aws3Info = JSON.parse(aws3Info).fileUrl;
        } catch (error) {
          console.log(error);
        }
        return aws3Info;
      }
    }
    return "";
  };

  const getChipColor = (file) => {
    switch (file.status) {
      case STATUS.ACTIVE:
        return Styles.active;
      case STATUS.PROCESSING:
        return Styles.processing;
      case STATUS.QUEUED:
        return Styles.queued;
      case STATUS.COMPLETE:
        return Styles.complete;
      case STATUS.FAILED:
        return Styles.failed;
      default:
        return Styles.default;
    }
  };

  const getTotalRecord = (file) => {
    if (!file.file_statistics) {
      return 0;
    }

    try {
      return JSON.parse(file.file_statistics).total_record;
    } catch (e) {
      return 0;
    }
  };

  return (
    <div className={Styles.pageWrapper}>
      <div className={Styles.pageHeader}>
        <div style={{ display: "grid" }}>
          <Typography variant={"h6"}>Files</Typography>
          <Typography variant={"caption"}>Check the status of files you've uploaded for verifications</Typography>
        </div>
        <div className={Styles.pageHeaderAction}>
          <FormControl className={Styles.formControl}>
            <Select
              name='perPage'
              value={limit}
              displayEmpty
              onChange={(e) => {
                setLimit(e.target.value);
              }}
              input={<BootstrapInput />}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className='dropdownhelper-menuitem-class' value={""} disabled>
                # of Results
              </MenuItem>
              <MenuItem className='dropdownhelper-menuitem-class' value={5}>
                5 of Results
              </MenuItem>
              <MenuItem className='dropdownhelper-menuitem-class' value={10}>
                10 of Results
              </MenuItem>
              <MenuItem className='dropdownhelper-menuitem-class' value={25}>
                25 of Results
              </MenuItem>
              <MenuItem className='dropdownhelper-menuitem-class' value={50}>
                50 of Results
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={Styles.pageBody}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "30%" }}>File Name</TableCell>
                <TableCell className={Styles.textAlignCenter}>Date Uploaded</TableCell>
                <TableCell className={Styles.textAlignCenter}>Total Records</TableCell>
                <TableCell className={Styles.textAlignCenter}>Total Cost</TableCell>
                <TableCell className={Styles.textAlignCenter}>Status</TableCell>
                <TableCell className={Styles.textAlignCenter}>Failed Reason</TableCell>
                <TableCell align={"center"}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <div className={Styles.loaderWrapper}>
                      <CircularProgress />
                      <h5 className={Styles.marginTop}>Getting file list....</h5>
                    </div>
                  </TableCell>
                </TableRow>
              ) : files[0] ? (
                files.map((file, index) => (
                  <TableRow>
                    <TableCell style={{ width: "30%" }}>
                      <ul>
                        <li className={Styles.fileTitle}>{file.file_name_original}</li>
                        <li className={Styles.validationType}>
                          <span className={Styles.validationTypeLabel}>Search type:</span> {renderSearchType(file)}
                        </li>
                      </ul>
                    </TableCell>
                    <TableCell className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                      {window.globalTimezoneConversionToDifferentTimezone(file.created_at)}
                    </TableCell>
                    {file.status === 'QUEUED' ? <TableCell colSpan={5}>
                      <StripedProgress
                          width={100}
                          text={
                            "Processing... this may take a few minutes depending on the number of records"
                          }
                      />
                    </TableCell> : <>
                      <TableCell className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                        {getTotalRecord(file)}
                      </TableCell>
                      <TableCell className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                        {numberToDollar(file.total_cost)}
                      </TableCell>
                      <TableCell className={Styles.textAlignCenter}>
                        <span className={`${Styles.statusChip} ${getChipColor(file)}`}>{file.status}</span>
                      </TableCell>
                      <TableCell className={Styles.textAlignCenter}>
                        {file.failed_reason && (
                            <BootstrapTooltip arrow title={<span>{file.failed_reason}</span>}>
                              <div className={`${Styles.truncate} ${Styles.failedReason}`}>{file.failed_reason}</div>
                            </BootstrapTooltip>
                        )}
                      </TableCell>
                      <TableCell align={"right"}>
                        <div className={Styles.fileActions}>
                          <CoreButton
                              {...( index === 0 ? { id: "viewDetailsFirstButton" } : {})}
                              backgroundColor={"#d9e3eb"}
                              className={`${Styles.viewDetails} ${Styles.cursorPointer}`}
                              onClick={() => {
                                if (onChangeTab) {
                                  onChangeTab(TAB_CLEAN_LIST.REPORTS_DETAILS);
                                }
                                dispatch({
                                  type: ActionTypes.UPDATE_STATE,
                                  payload: {
                                    cleanDataRequestId: file.id,
                                    cleanDataRequest: file,
                                  },
                                });
                              }}
                              style={{ minWidth: "max-content" }}
                          >
                            View Results
                          </CoreButton>
                          {renderFileUrl(file) !== "" ? (
                              <CoreButton
                                  target='_blank'
                                  href={renderFileUrl(file)}
                                  backgroundColor={"#316AFF"}
                                  color={"primary"}
                                  variant={"contained"}
                              >
                                Download
                              </CoreButton>
                          ) : (
                              <div />
                          )}
                        </div>
                      </TableCell>
                    </>}

                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <div className={Styles.loaderWrapper}>
                      <h5 className={Styles.marginTop}>No file found!</h5>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          page={page}
          totalPage={totalPage}
          callback={(uPage) => {
            setPage(uPage);
          }}
        />
      </div>
    </div>
  );
};

export default FileReport;
