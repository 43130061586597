import React, {Fragment, useEffect, useState} from "react";
import {
    BasicButton,
    ContactProducts,
    TabHeader,
    TabWrapper
} from "./ProductStyles";
import AddProductModal from "./AddProductModal";
import ContactProductCard from "./ContactProductCard";
import {getContactSoldProducts} from "../../../../api/dealApi";
import Loading from "./Loading";
import { getProductsList, getUserProductsList } from "../../../../api/contactApi";
import EmptyProduct from "./EmptyProduct";
import AddIcon from "@material-ui/icons/Add";
import AddProductFromDropdown from "./AddProductFromDropdown";

const Products = (props) => {
    const [contactProducts, setContactProducts] = useState([]);
    const [openProductModal, setOpenProductModal] = useState(false);
    const [loadingContent, setLoadingContent] = useState(false);
    const [productList,setProductList] = useState(null);
    const[createProduct,setCreateProduct] = useState(null);

    useEffect(() => {
        if(props.contact?.id){
            (async ()=>{
                setLoadingContent(true);
                //await fetchUserProductList();
                await handleGetContactSoldProducts();
                 await getProductList();
                setLoadingContent(false);
            })();
        }
    }, [props.contact,createProduct]);


    const fetchUserProductList =  () => {
        getUserProductsList().then((res) => {
          try {
            if (res && res.success ) {
                setProductList(res.data);
            }
          } catch (err) {
            console.log(err);
          }
        });
      };

    const handleGetContactSoldProducts = async () => {
      try{
          const response = await getContactSoldProducts(props.contact.id);

          if (response && response.success){
              setContactProducts(response.data);
          }
      }catch (err){
          console.log(err);
      }
    }; 

    const findSubUserById = (id, field=null) => {
      try{
          const subUser = props.subUserList.find((subUser)=>(subUser.id === id));

          if (field){
              if (subUser){
                  return subUser[field];
              }

              return null;
          }

          return subUser;
      }catch (err){
          return null;
      }
    };

    const appendNewContactProduct = (newContactProduct) => {
        setContactProducts((prevState) => ([ newContactProduct, ...prevState ]));
    };

    const getProductList = async () => {
        try{
            const response = await getProductsList();
  
            if (response && response.success && response.data[0]){
                setProductList(response.data);
            }
            else {
                setProductList([]);
            }
        }catch (err){
            console.log(err);
        }
      };

    return(
        <TabWrapper className={"awesome__scroll_bar"}>
            {
                loadingContent ?
                    <Loading/> :
                    <Fragment>
                        {
                            contactProducts[0] &&
                            <TabHeader>
                                <BasicButton
                                    startIcon={<AddIcon/>}
                                    onClick={()=>{ setOpenProductModal(true); }}
                                >
                                    New Product/Service
                                </BasicButton>
                            </TabHeader>
                        }
                        {
                            contactProducts[0] ?
                               <ContactProducts>
                                   {
                                       contactProducts.map((contactProduct)=>(
                                           <ContactProductCard
                                               findSubUserById={findSubUserById}
                                               contactProduct={contactProduct}
                                           />
                                       ))
                                   }
                               </ContactProducts> :
                               <EmptyProduct
                                   setOpenProductModal={setOpenProductModal}
                               />
                        }
                    </Fragment>
            }

           { openProductModal && 
           (  ( productList && productList[0] ) ? 
            <AddProductFromDropdown
                contact={props.contact}
                appendNewContactProduct={appendNewContactProduct}
                open={openProductModal}
                onClose={()=>{ setOpenProductModal(false); }}
                productList={productList}
                setCreateProduct={setCreateProduct}
            /> :
           <AddProductModal
                contact={props.contact}
                appendNewContactProduct={appendNewContactProduct}
                open={openProductModal}
                onClose={()=>{ setOpenProductModal(false); }}
                setCreateProduct={setCreateProduct}
            /> 
            )}
        </TabWrapper>
    );
}

export default Products;