import React, { useEffect, useState } from "react"
import {
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core"
import { CloudDownload } from "@material-ui/icons"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { CoreButton } from "./enhanceData/commons/CoreButton"
import { cleanContactsExportedFilesApi } from "../../api/cleanDataApi"
import Pagination from "./report/Pagination"
import ExportedFileStatus from "./ExportedFileStatus"

const StyledTable = styled(Table)({
  "& th": {
    backgroundColor: "#e7e7e7",
    fontSize: "14px",
    fontWeight: 600,
    color: "#464646de",
    textTransform: "capitalize",
  },
})

const ExportedFiles = () => {
  const [loading, setLoading] = useState(false)
  const [exportedFiles, setExportedFiles] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPage: 0,
  })
  useEffect(() => {
    setLoading(true)
    cleanContactsExportedFilesApi({
      page: pagination.page,
      "per-page": pagination.limit,
    })
      .then((data) => {
        if (data.success) {
          setExportedFiles(data.data)
          setPagination((pagination) => ({
            ...pagination,
            totalPage: data.totalPage,
          }))
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [pagination.page, pagination.limit])

  let content = <></>
  let listNotFound = <></>

  if (loading) {
    content = (
      <TableRow>
        <TableCell
          colSpan={3}
          align={"center"}
          style={{ padding: "80px", backgroundColor: "#f6f7fb" }}
        >
          <CircularProgress style={{ position: "relative" }} />
        </TableCell>
      </TableRow>
    )
  }

  if (loading === false && exportedFiles.length > 0) {
    content = (
      <React.Fragment>
        {exportedFiles.map((file, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{file.file_name}</TableCell>
              <TableCell>
                <ExportedFileStatus
                  status={file.status || "PROCESSING"}
                  errorText={file.failed_reason}
                />
              </TableCell>
              <TableCell align={"right"}>
                {file.status === "PROCESSED" && file.file_url !== null ? (
                  <CoreButton
                    size={"small"}
                    variant={"contained"}
                    color={"primary"}
                    startIcon={<CloudDownload />}
                    onClick={() => {
                      window.location.href = file.file_url
                    }}
                  >
                    Download
                  </CoreButton>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </React.Fragment>
    )
  }

  if (loading === false && exportedFiles.length === 0) {
    listNotFound = (
      <Typography variant='h5' py={3} style={{ textAlign: "center" }}>
        No file Exported!
      </Typography>
    )
  }

  return (
    <Box>
      <Paper variant={"outlined"} style={{ borderBottom: "none" }}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align={"right"}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{content}</TableBody>
        </StyledTable>
      </Paper>
      <Pagination
        page={pagination.page}
        totalPage={pagination.totalPage}
        callback={(uPage) => {
          setPagination((pagination) => ({ ...pagination, page: uPage }))
        }}
      />

      {listNotFound}
    </Box>
  )
}

export default ExportedFiles
