import React, { useEffect, useRef, useState } from "react";
import { InputAdornment } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import EmptyScreen from "./EmptyScreen";
import {
  BasicInput,
  ContactAvatar,
  ContactInfo,
  ContactLine,
  ContactName,
  SearchResult,
  SearchResults,
  SearchResultWrapper,
  SearchWrapper,
} from "./RelatedContactsModalStyles";
import { getElasticContacts } from "../../../../api/contactApi";
import useDelayCallback from "../../../../hooks/useDelayCallback";
import { useIsMounted } from "../../../../hooks/IsMounted";


const ContactSearch = ({ callback }) => {
  const isMounted = useIsMounted();
  const searchBoxRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [emptyScreenText, setEmptyScreenText] = useState("No contact found");
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (showSearchResult) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSearchResult]);

  useDelayCallback(() => {
      (async ()=>{
          if (isMounted){
              await handleGetElasticContacts();
          }
      })();
  },[searchText]);

  const handleSearch = (e) => {
    setLoadingData(true);
    setShowSearchResult(!!e.target.value);
    setEmptyScreenText("Please wait...");
    setSearchText(e.target.value);
  };

  const handleClickOutside = (event) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setShowSearchResult(false);
      setSearchText("");
    }
  };

  const handleGetElasticContacts = async () => {
      try{
          const resp = await getElasticContacts({
              page: 1,
              size: 10,
              search: searchText,
          });

          if (resp?.status === "SUCCESS" && resp?.data?.contactInfos[0]){
              setResults(resp.data.contactInfos);
              setEmptyScreenText("");
          }else {
              setResults([]);
              setEmptyScreenText("No contact found");
          }
      }catch (err){
          console.log(err);
          setResults([]);
          setEmptyScreenText("No contact found");
      }finally {
          setLoadingData(false);
      }
  };

  return (
    <SearchWrapper ref={searchBoxRef}>
      <BasicInput
        fullWidth
        value={searchText}
        placeholder='Search for existing contact'
        endAdornment={
          <InputAdornment position='end'>
            <SearchIcon style={{ color: "#656565" }} />
          </InputAdornment>
        }
        onChange={handleSearch}
        onFocus={() => {
          if (searchText !== "") {
            setShowSearchResult(true);
          }
        }}
      />
      {showSearchResult && (
        <SearchResultWrapper>
          {!loadingData && (
            <SearchResults>
              {results.map((result) => {
                let name = "";
                let avatar = "";

                if (result["firstName"]) {
                  name = result["firstName"];
                  avatar = result["firstName"].substring(0, 1);
                }

                if (name) {
                  name += " ";
                  name += result["lastName"];
                  avatar += result["lastName"].substring(0, 1);
                }

                return (
                  <SearchResult
                    onClick={() => {
                      callback(result);
                      setShowSearchResult(false);
                      setSearchText("");
                    }}
                  >
                    <ContactAvatar>{avatar || <PersonIcon />}</ContactAvatar>
                    <ContactInfo>
                      <ContactName>{name}</ContactName>
                      <ContactLine>{result["email"]}</ContactLine>
                      <ContactLine>{result["number"]}</ContactLine>
                    </ContactInfo>
                  </SearchResult>
                );
              })}
            </SearchResults>
          )}
          {(loadingData || emptyScreenText) && <EmptyScreen text={emptyScreenText} />}
        </SearchResultWrapper>
      )}
    </SearchWrapper>
  );
};

export default ContactSearch;
