import React, { useState, useEffect, useRef } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AddIcon from "@material-ui/icons/Add";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { connect } from "react-redux";
import AvatarComponet from "./avatar"
import TextInputField from "./TextInputField"
import SelectField from "./SelectField";
import TagsComponent from "./tag/TagsComponent"
import { reloadConversationList, setContactDetails, toggleNoteModal, addExtraEmailContact } from "../../../actions/contactAction"
import NoteComponent from "./note/NoteComponent";
import CustomFields from './customFields/CustomFields';
import './leftside.css'
import LeftSideContactSingleInputField from './Tabs/ContactTab/LeftSideContactSingleInputField';
import Utils from '../../../helpers/Utils';
import { confirmAlert } from 'react-confirm-alert';
import {
	removeBulkContact,
	getUserCampaignRegistrationStatusApi,
} from '../../../api/contactApi';
import { useHistory } from "react-router-dom";
import { IconList } from '../../globals/IconList';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import MoreInfo from './moreInfo/MoreInfo';
import SelectContactEmail from './SelectContactEmail';
import Helper from '../../globals/Helper'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CarrierLookup from "../../carrierLookup";
import dncNoCall from "../../common/dncNoCall";
import BootstrapTooltip from "../../carrierLookup/BootstrapTooltip";
import AdditionalContactList from './AdditionalContactList';
const GIFT = 8;

const thanksIoActive = (Utils.getAccountData('customMenu')['63'] && Utils.getAccountData('customMenu')['63']
	&& Utils.getAccountData('customMenu')['63'] !== null && Utils.getAccountData('customMenu')['63'] !== "") ? true : false

const zenDirectActive = (Utils.getAccountData('customMenu')['61'] && Utils.getAccountData('customMenu')['61']
	&& Utils.getAccountData('customMenu')['61'] !== null && Utils.getAccountData('customMenu')['61'] !== "") ? true : false
const TabPanel = (props) => {
	const { children, value, index, ...other } = props;


	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					{children}
				</Box>
			)}
		</div>
	);
}

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const CONTACT_RELATION = [
	{
		id: "HOME",
		relation_type: "HOME",
	},
	{
		id: "WORK",
		relation_type: "WORK",
	},
	{
		id: "OTHERS",
		relation_type: "OTHERS",
	},
];


const LeftSide = (props) => {
	const [mailOptionVisible, setMailOptionVisible] = useState(false);
	const [lastCommunicationMessage, setLastCommunicationMessage] = useState('');
	const [inputList, setInputList] = useState([]);
	const [toggle, setToggle] = useState(false);

	// for video send
	const [activeTab, setActiveTab] = useState(0);
	const history = useHistory();



	useEffect(() => {
		getLastCommunicationinfo();
	}, [props.contact]);

	const getLastCommunicationinfo = () => {
		setLastCommunicationMessage(props.contact?.contact_activity?.local_last_communication?.for_human
			? props.contact.contact_activity.local_last_communication.for_human : "No conversation yet!")
	}

	const selectActiveMailType = (type) => {

		setMailOptionVisible(false)
		window.openGlobalDirectMailModal({
			messageType: type, contactId: props.contact.id, contactDetails: props.contact,
			callBack: submitDirectMail, from: 'inbox'
		})
	}

	const submitDirectMail = () => {
		console.log('submit Direct Mail')
	}

	const showNotificationStart = (type, message) => {
		window.showNotification(type, message)
	}

	const removeBulkContacts = async () => {

		confirmAlert({
			title: 'Confirm to delete',
			message: 'Are you sure to delete contact?',
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						await removeBulkContact({ 'ids': [props.contact.id] })
							.then(response => {

								if (response.data.success) {
									window.showNotification('SUCCESS', 'Contact deleted successfully')
									history.push("/contacts");

								} else {
									window.showNotification('ERROR', 'Something went wrong')
								}
							})
							.catch(error => {
								window.showNotification('ERROR', 'Something went wrong')
							});
					}
				},
				{
					label: 'No',
					onClick: () => {
						return;
					}
				}
			]
		})

	}

	const getContactEmail = () => {
		let contact = props.contact
		if (contact.email !== undefined && contact.email !== null) {
			return contact.email
		}
		return ""
	}

	const editMoreInfo = (changedInfo) => {
		if (props.updateStoredContactDetails !== undefined) {
			props.updateStoredContactDetails(changedInfo)
		}
	}

	const openContactInfoModal = () => {
		window.handleGlobalContactDetailsModal({
			open: true,
			from: 'contact-details',
			title: () => {
				let title = "Contact details";
				let data = props.contact
				if (data !== undefined && data !== null) {
					if ((data.first_name !== null && data.first_name !== "") && (data.last_name !== null && data.last_name !== "")) {
						title = data.first_name + ' ' + data.last_name
					}
					else if (data.first_name !== null && data.first_name !== "") {
						title = data.first_name
					}
					else if (data.last_name !== null && data.last_name !== "") {
						title = data.last_name
					}
					else if (data.email !== null && data.email !== "") {
						title = data.email
					}
					else if (data.number !== null && data.number !== "") {
						title = data.number
					}
				}
				return title;
			},
			id: props.contact.id,
			closeContactDetails: () => console.log('info modal close')
		})
	}



	const handleAddClick = () => {
		// if (validation != null) {
		// 	setValidation(null)
		// }
		setInputList([...inputList, { first_name: "", last_name: "", email: "", contact_relation: CONTACT_RELATION[0].id, label: "", number: "" }]);
		setToggle(!toggle);
	};

	const callAll = () => {

		let allContacts = (props.allContactList && props.allContactList.data) ? props.allContactList.data : []
		let totalContacts = (props.allContactList && props.allContactList.total) ? props.allContactList.total : 0
		let currentIndex = props.assignedContactListInfo && props.assignedContactListInfo.currentIndex ? props.assignedContactListInfo.currentIndex : null

		if (allContacts[1]) {
			let tmpParams = { from: 'contactListPage', contactList: allContacts, totalContacts: totalContacts, currentIndex: currentIndex }
			window.initiateGlobalDialer(props.contact, tmpParams)
		} else {
			window.initiateGlobalDialer(props.contact)
		}


	}

	const sendVoiceMail = () => {
		if (window.sendCommunication !== undefined) {
			window.sendCommunication({
				open: true,
				onClose: () => console.log('RVM close'),
				id: props.contact.id,
				from: "contact-details",
				fetchContactDetails: false,
				sendSuccessCallBack: () => props.reloadNeedForConversation(true)
			}, 'voice')
		}
	}

	const handleNewPostcard = (type = 'postcard') => {
		setMailOptionVisible(false)
		window.sendGlobalDirectMailV2({
			contactId: props.contact.id,
			from: '/contacts/' + props.contact.id,
			card_type: type,
			for: 'direct-mail-send' //campaign-setting
		})
	}

	const sendSMSModal = () => {
		if (window.sendCommunication !== undefined) {
			window.sendCommunication({
				open: true,
				onClose: () => console.log('sms close'),
				id: props.contact.id,
				from: "deal-details",
				fetchContactDetails: false,
				contactInfo: props.contact,
				sendSuccessCallBack: () => {
					setLastCommunicationMessage("A few seconds ago");
					props.reloadNeedForConversation(true);
				}
			}, 'sms')
		}
	}

	const getUserCampaignRegistrationStatus = () => {
		try {
			getUserCampaignRegistrationStatusApi()
				.then((res) => {
					res = res.data;
					if (res.success) {
						if (res.campaignRegistrationStatus === "TRUE") {
							sendSMSModal();
						} else {
							window.globalSmsRestriction(true, {
								callback: (res) => {
									if (res.sendWithRisk) {
										sendSMSModal();
									}
								}
							});
						}
					}
				})
				.catch((err) => {
					sendSMSModal();
					console.log(err);
				});
		} catch (err) {
			sendSMSModal();
			console.log(err);
		}
	}




	return (
		<React.Fragment>


			{props.showNoteModal && (
				<NoteComponent
					open={props.showNoteModal}
					onClose={() => props.toggleNoteModal(false)}
				/>
			)}
			<div className="col s12 m12 l3 xl3 contact_details white">

				<div className="d-flex justify-content-between align-items-center contact_details_top">
					<div className="px-2 py-3 d-flex align-items-center contact_details_top__for__pagination ">
						<div className="btn-floating box-shadow-none contact_person_icon d-flex justify-content-center align-items-center">
							<i className="material-icons">person</i>
						</div>
						{!props.assignedContactListInfo ?

							<span className="ml-2 contact_details_top__for__pagination_info">Contact Information</span>
							:
							<span className="ml-2 contact_details_top__for__info1">Information
								<span className='contact_details_top__for__pagin'>{props.assignedContactListInfo.currentIndex !== 0 &&
									<span onClick={() => {
										props.goToContactDetails('previous')
										setActiveTab(0)
									}}><NavigateBeforeIcon /></span>
								}
									{props.assignedContactListInfo.currentIndex + 1} of {props.assignedContactListInfo.totalContacts} contacts
									{props.assignedContactListInfo.currentIndex + 1 < props.assignedContactListInfo.totalContacts &&
										<span onClick={() => {
											props.goToContactDetails('next')
											setActiveTab(0)
										}}><NavigateNextIcon /></span>
									}
								</span>
							</span>

						}

					</div>
				</div>
				<div className="mt-3 ContactCustomClass">
					<div className="text-center">
						<AvatarComponet
							showNotificationStart={showNotificationStart}
						/>
						<div className="user_profile_icons d-flex justify-content-center v3">
							{props.contact.number &&
								props.contact.number !== "" && (
									<div
										id="text_message_btn"
										className="single_icon"
									>

										<span
											className="email_btn_icon"
											// onClick={(e) =>
											// 	setIsOpenSMSModal(true)
											// }
											onClick={(e) => {
												e.preventDefault();
												if (Utils.getAccountData("campaignRegistrationAvailable") === "TRUE") {
													if (window.sendCommunication !== undefined) {
														window.sendCommunication({
															open: true,
															onClose: () => console.log('sms close'),
															id: props.contact.id,
															from: "deal-details",
															fetchContactDetails: false,
															contactInfo: props.contact,
															sendSuccessCallBack: () => {
																setLastCommunicationMessage("A few seconds ago");
																props.reloadNeedForConversation(true);
															}
														}, 'sms')
													}
												} else {
													getUserCampaignRegistrationStatus();
												}
											}}
										>
											{IconList.massageIcon}
										</span>
										<p className="text-gray m-0">Text</p>
									</div>
								)}
							{(props.contact.email && props.contact.email !== "") && (
								<div
									id="email_btn"
									className="single_icon"
								>
									<span
										className="email_btn_icon"
										onClick={(e) => {
											e.preventDefault();
											if (window.sendCommunication !== undefined) {
												window.sendCommunication({
													open: true,
													onClose: () => console.log('Email Close'),
													id: props.contact.id,
													from: "contact-details",
													primaryEmail: getContactEmail(),
													fetchContactDetails: false,
													sendSuccessCallBack: () => {
														setLastCommunicationMessage("A few seconds ago");
														props.reloadNeedForConversation(true);
													}
												}, 'email')
											}
										}}
									>
										{IconList.emailIconV2}
									</span>
									<p className="text-gray m-0">Email</p>
								</div>
							)}

							{props.contact.number &&
								props.contact.number !== "" && (
									<div className="single_icon" onClick={() => {
										{
											(Utils.getAccountData("dnc")
												&& props.contact && props.contact.number) ?
												(
													(
														props.contact.contact_additional_informations &&
														props.contact.contact_additional_informations.dnc_status === "DO_NOT_CALL"
													) ? (
														dncNoCall({
															takeRisk: () => {
																callAll(props.contact)
															},
															cancelButtonText: "Don't Call"
														})
													) : (
														callAll(props.contact)
													)
												) : (
													callAll(props.contact)
												)
										}
									}}>
										<i className="material-icons waves-effect m-2 text-gray modal-trigger d-none">
											phone_in_talk
										</i>
										<span className="email_btn_icon phone-icon"

										>
											{IconList.callIconv2}
										</span>
										<p className="text-gray m-0">Call</p>
									</div>
								)}

							{props.contact.number &&
								props.contact.number !== "" && (
									<div
										className="single_icon"
									>
										<i
											data-target="voice_mail_modal"
											className="modal-trigger material-icons waves-effect m-2 text-gray d-none">
											mic
										</i>
										<span
											className="email_btn_icon micBtn"
											onClick={(e) => {
												e.preventDefault();
												{
													(Utils.getAccountData("carrierLookup")
														&& props.contact && props.contact.number) ?
														(
															(
																props.contact.contact_additional_informations &&
																props.contact.contact_additional_informations.dnc_status === "DO_NOT_CALL"
															) ? (
																dncNoCall({
																	takeRisk: () => {
																		sendVoiceMail()
																	},
																	cancelButtonText: "Don't Send"
																})
															) : (
																sendVoiceMail()
															)
														) : (
															sendVoiceMail()
														)
												}
											}}
										>
											{IconList.micIcon}
										</span>
										<p className="text-gray m-0">Voice</p>
									</div>
								)}

							{props.contact.email && props.contact.email !== "" && (
								<div className="single_icon"
									// onClick={() => setOpenVideoMailModal(true)}>
									onClick={() => window.sendCommunication({
										open: true,
										contactId: props.contact.id,
										contactDetails: props.contact,
										from: 'contact-details',
										responseCallback: () => props.reloadNeedForConversation(true)
									}, 'videoEmail')}
								>
									<VideoCallIcon className="modal-trigger material-icons waves-effect m-2 text-gray d-none" />
									<span className="email_btn_icon micBtn videoIcon">
										{IconList.videoIconWithoutStroke}
									</span>
									<p className="text-gray m-0">Video</p>
								</div>
							)}


							{(thanksIoActive || zenDirectActive) && !Helper.isEmpty(props.contact.address) && !Helper.isEmpty(props.contact.state) &&

								<div
									className="single_icon"
									onClick={(e) => setMailOptionVisible(e.currentTarget)}
								>
									<span className="email_btn_icon  micBtn videoIcon">
										{IconList.directMailiconInContactDetailsWithoutStroke}
									</span>
									<p className="text-gray m-0">DMail</p>
								</div>
							}
							<Menu
								id="more-menu"
								anchorEl={mailOptionVisible}
								keepMounted
								open={Boolean(mailOptionVisible)}
								onClose={() => setMailOptionVisible(false)}
							>
								{Utils.getAccountData('userId') == 1 &&
									<MenuItem onClick={() => selectActiveMailType(GIFT)}
										style={{
											paddingTop: 6,
											paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
										}}>

										<span>
											{IconList.giftIcon}

										</span>
										&nbsp; Old Gift</MenuItem>
								}

								{thanksIoActive &&
									<>
										<MenuItem onClick={() => handleNewPostcard('postcard')}
											style={{
												paddingTop: 6,
												paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
											}}
										>
											<span>{IconList.giftIcon}</span>&nbsp; Postcard
										</MenuItem>
										<MenuItem onClick={() => handleNewPostcard('letter')}
											style={{
												paddingTop: 6,
												paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
											}}
										>
											<span>{IconList.giftIcon}</span>&nbsp; Letter
										</MenuItem>
										<MenuItem onClick={() => handleNewPostcard('notecard')}
											style={{
												paddingTop: 6,
												paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
											}}
										>
											<span>{IconList.giftIcon}</span>&nbsp; Notecard
										</MenuItem>
									</>
								}
								{zenDirectActive &&
									<MenuItem onClick={() => handleNewPostcard('zendirect_gift')}
										style={{
											paddingTop: 6,
											paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
										}}
									>
										<span>{IconList.giftIcon}</span>&nbsp; Gift
									</MenuItem>
								}
							</Menu>
						</div>
					</div>
					<div className="row mt-4 userprofile__tabs">
						<div className="col s12">
							<Tabs
								className="user__tabs__content"
								value={activeTab}
								onChange={(event, newValue) =>
									setActiveTab(newValue)
								}
								aria-label="simple tabs example">
								<Tab
									className={`user__tabs__title ${activeTab === 0
										? "accent--bg--text--color"
										: ""
										}`}
									label="contact"
									{...a11yProps(0)}
								/>
								<Tab
									className={`user__tabs__title ${activeTab === 1
										? "accent--bg--text--color"
										: ""
										}`}
									label="more info"
									{...a11yProps(1)}
								/>
								<Tab
									className={`user__tabs__title ${activeTab === 2
										? "accent--bg--text--color"
										: ""
										}`}
									label="tags"
									{...a11yProps(2)}
								/>
							</Tabs>
						</div>
						<TabPanel
							className="use__tabs__panel"
							value={activeTab}
							index={0}>
							<div id="contact" className="col s12">
								<div className="bg-gray-light p-3 comon__title__name addtional__eamail__btn__wr">

									<div className="user-name-group-leftside d-flex">
										<div className="first-name-leftside">
											<LeftSideContactSingleInputField
												label={"First Name"}
												name={"first_name"}
												contactId={
													props.contact &&
													props.contact.id
												}
												value={
													props.contact &&
													props.contact.first_name
												}
												placeholder="Enter first name"
												showSpan={false}
												loadingData={props.loadingData}
											/>
										</div>
										<div className="last-name-leftside">
											<LeftSideContactSingleInputField
												label={"Last Name"}
												name={"last_name"}
												contactId={
													props.contact &&
													props.contact.id
												}
												value={
													props.contact &&
													props.contact.last_name
												}
												placeholder="Enter last name"
												loadingData={props.loadingData}
											/>
										</div>
									</div>
									<div style={{ position: "relative" }}>
										{Utils.getAccountData("carrierLookup") && props.contact && props.contact.email &&
											props.contact.email.trim() !== "" &&
											<div style={{ position: "absolute", top: "6px", right: "10px" }}>
												<CarrierLookup
													validationType={'EMAIL'}
												/>
											</div>
										}
										<LeftSideContactSingleInputField
											label={"Email"}
											name={"email"}
											addButton={
												inputList.length === 0 && (
													<BootstrapTooltip arrow title="Add Button">
														<span
															className="small-round-icon"
															onClick={handleAddClick}
														>
															{props.addButton}
															{<AddIcon fontSize="small" />}
														</span>
													</BootstrapTooltip>
												)
											}
											contactId={
												props.contact && props.contact.id
											}
											value={
												props.contact && props.contact.email
											}
											placeholder="Enter email address"
											checkValidation={(value) => {
												let invalid = "";
												if (value === '' && props.contact.number && props.contact.number !== '') {

												} else if (value.trim() === '') {
													invalid = "Number or Email is required"
												}
												else if (!Utils.validateEmail(value)) {
													invalid = "Invalid email";
												}

												return invalid;
											}}
											loadingData={props.loadingData}
										/>
									</div>

									<div style={{ position: "relative" }}>
										
										<LeftSideContactSingleInputField
											label={"Phone Number"}
											name={"number"}
											addButton={
												inputList.length === 0 && (
													<BootstrapTooltip arrow title="Add Button">
														<span
															className="small-round-icon"
															onClick={handleAddClick}
														>
															{props.addButton}
															{<AddIcon fontSize="small" />}
														</span>
													</BootstrapTooltip>
												)
											}
											contactId={
												props.contact && props.contact.id
											}
											value={
												props.contact &&
												props.contact.number
											}
											placeholder="Enter number"
											checkValidation={(value) => {
												let invalid = "";
												if (/^\d+$/.test(value)) {
													if (
														!Utils.validateMobileNumber(
															value
														)
													) {
														invalid = "Invalid number";
													}
												} else if (value.trim() === '' && props.contact.email && props.contact.email !== '') {

												} else if (value.trim() === '') {
													invalid = "Number or Email is required"
												}
												else {
													invalid = "Invalid number";
												}
												return invalid;
											}}
											loadingData={props.loadingData}
										/>
										{props.contact && props.contact.number &&
											props.contact.number.trim() !== "" &&
											<div className="validateAndDNC">
												{
													Utils.getAccountData("carrierLookup") &&
													<CarrierLookup validationType={'NUMBER'} />
												}

												{
													Utils.getAccountData("dnc") &&
													<CarrierLookup validationType={'DNC_STATUS'} />
												}
												
											</div>
										}
									</div>

									<AdditionalContactList loadingData={props.loadingData} inputList={inputList} setInputList={setInputList}
										toggle={toggle} setToggle={setToggle} />

									<TextInputField
										label={"Last Contacted"}
										name={"last_contacted"}
										value={lastCommunicationMessage}
									/>
									<SelectField
										label={"Lead Status"}
										name={"status"}
										value={
											props.contact &&
											props.contact.status
										}
										redirect={() => {
											history.push('/contacts');
										}}
									/>
								</div>

								<button
									className="btn profile-outline-btn text-light-blue mt-3 modal-trigger"
									data-target="view_all_info_modal"
									onClick={(e) => {
										e.preventDefault();
										openContactInfoModal()
									}}>
									<span className="material-icons mr-2">
										visibility
									</span>
									View All Info
								</button>

								<button
									className="btn profile-outline-btn text-red mt-3"
									onClick={() => removeBulkContacts()}>
									<svg
										className="mr-2"
										width="16"
										height="20"
										viewBox="0 0 16 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M14.2356 2.42308H10.9038V1.81731C10.9038 0.813624 10.0902 0 9.08654 0H6.66346C5.65978 0 4.84615 0.813624 4.84615 1.81731V2.42308H1.51442C0.678045 2.42308 0 3.10112 0 3.9375V5.14904C0 5.48361 0.271195 5.75481 0.605769 5.75481H15.1442C15.4788 5.75481 15.75 5.48361 15.75 5.14904V3.9375C15.75 3.10112 15.072 2.42308 14.2356 2.42308ZM6.05769 1.81731C6.05769 1.48338 6.32953 1.21154 6.66346 1.21154H9.08654C9.42047 1.21154 9.69231 1.48338 9.69231 1.81731V2.42308H6.05769V1.81731Z"
											fill="#FF5858"
										/>
										<path
											d="M1.15023 6.96637C1.04214 6.96637 0.956008 7.05671 0.961157 7.16468L1.46092 17.6537C1.50711 18.6244 2.30445 19.3846 3.27595 19.3846H12.4753C13.4468 19.3846 14.2442 18.6244 14.2904 17.6537L14.7901 7.16468C14.7953 7.05671 14.7091 6.96637 14.601 6.96637H1.15023ZM10.2987 8.48079C10.2987 8.14611 10.5698 7.87502 10.9045 7.87502C11.2392 7.87502 11.5103 8.14611 11.5103 8.48079V16.3558C11.5103 16.6905 11.2392 16.9616 10.9045 16.9616C10.5698 16.9616 10.2987 16.6905 10.2987 16.3558V8.48079ZM7.26987 8.48079C7.26987 8.14611 7.54095 7.87502 7.87563 7.87502C8.21032 7.87502 8.4814 8.14611 8.4814 8.48079V16.3558C8.4814 16.6905 8.21032 16.9616 7.87563 16.9616C7.54095 16.9616 7.26987 16.6905 7.26987 16.3558V8.48079ZM4.24102 8.48079C4.24102 8.14611 4.5121 7.87502 4.84679 7.87502C5.18148 7.87502 5.45256 8.14611 5.45256 8.48079V16.3558C5.45256 16.6905 5.18148 16.9616 4.84679 16.9616C4.5121 16.9616 4.24102 16.6905 4.24102 16.3558V8.48079Z"
											fill="#FF5858"
										/>
									</svg>
									Delete Contact
								</button>
							</div>
						</TabPanel>
						<TabPanel
							className="use__tabs__panel"
							value={activeTab}
							index={1}
						>
							{/* show more cotact info */}
							<MoreInfo contact={props.contact} onChange={editMoreInfo} />
							<CustomFields
								data={props.data}
								// customTypes={props.data.customTypes}
								contact_id={props.contact.id}
								contactCustomFields={
									props.contact.all_contact_custom_fields
								}
								defaultFields={props.contact.user.custom_fields}
							/>
						</TabPanel>
						<TabPanel
							className="use__tabs__panel"
							value={activeTab}
							index={2}>
							<TagsComponent />
						</TabPanel>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

const mapStateToProps = state => {
	return {
		contact: state.contactReducer.contact,
		showNoteModal: state.contactReducer.showNoteModal,
		assignedContactListInfo: state.contactReducer.assignedContactListInfo,
		allContactList: state.contactReducer.allContactList,

	};
};

const mapDispatchToProps = dispatch => {
	return {
		toggleNoteModal: (params) => dispatch(toggleNoteModal(params)),
		reloadNeedForConversation: (params) => dispatch(reloadConversationList(params)),
		updateStoredContactDetails: (params) => dispatch(setContactDetails(params)),
		addExtraEmailContact: (params) => dispatch(addExtraEmailContact(params)),
		setContactDetails: (params) => dispatch(setContactDetails(params)),

	};
}
export default connect(mapStateToProps, mapDispatchToProps)(LeftSide);