import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import BasicSelect from '../custom/custom/BasicSelect'
import BasicInput from '../custom/custom/BasicInput'
import BasicInputForIcon from '../custom/custom/BasicInputForIcon'
import {  ModalContactChip, ModalHead, ModalTitle } from '../Products/ProductStyles';
import { Box, Checkbox, Divider, FormControlLabel, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import BootstrapTooltip from '../../../globals/BootstrapTooltip';
import { Add } from '@material-ui/icons';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
const useStyle=makeStyles({
    formLabel: {
        fontWeight: "600 !important",
        color: "#181f48",
        marginBottom: "0px !important"
    },
    addNewButton:{
        color:" white !important",
        padding: "7px 12px 8px !important",
        fontWeight: "600 !important",
        borderRadius: "5px !important",
        textTransform: "capitalize !important",
        backgroundColor:" #316AFF !important",
        width:'135px'
    },
    checkBoxStyle:{
        color: '#0E1B3D',
         fontSize: '16px !important',
          fontWeight: '600'
    },
    addDealButton:{
        color:" white !important",
        padding: "7px 12px 8px !important",
        fontWeight: "600 !important",
        borderRadius: "5px !important",
        textTransform: "capitalize !important",
        backgroundColor:" #316AFF !important",
       
    },
    cancelButton:{
        color:" #316AFF !important",
    }

})
const policies=[
    {id:1,
        label:'abc'
    },
    {id:2,
        label:'def'
    },
    {id:3,
        label:'dfg'
    },
    {id:4,
        label:'aert'
    }
]

const AddPolicyModal = ({open,onClose,setOpenAddNewPolicyTypeModal,contact}) => {
    const[selectedPolicy,setSelectedPolicy]=useState('')
    const[selectedInsuranceCompany,setSelectedInsuranceCompany]=useState('')
    const[policyNumber,setPolicyNumber]=useState(null)
    const[policyLink,setPolicyLink]=useState(null)
    const[effectiveDate,setEffectiveDate]=useState(null)
    const[expirationDate,setExpirationDate]=useState(null)
    const[fullPremium,setFullPremium]=useState(null)
    const[selectedLeadSource,setSelectedLeadSource]=useState('')
    const[selectedAgent,setSelectedAgent]=useState('')
    const [contactLabel,setContactLabel]=useState("")
    const[isChecked,setIsChecked]=useState(true)


    useEffect(() => {
        if (contact){
            let label = "";

            if (contact.first_name){
                label += contact.first_name;
            }

            if (contact.last_name){
                if (label){
                    label += " ";
                }
                label += contact.last_name;
            }

            if (!label){
                label = contact.email;
            }

            if (!label){
                label = contact.number;
            }

            setContactLabel(label);
        }
    }, [contact]);

    const handleSubmit=()=>{
        const payload={
            selectedPolicy,
            selectedInsuranceCompany,
            policyNumber,
            policyLink,
            effectiveDate,
            expirationDate,
            fullPremium,
            selectedLeadSource,
            selectedAgent,
            isChecked

        }
        console.log(payload);
    }

    const handleAddNewPolicyType=()=>{
        onClose()
        setOpenAddNewPolicyTypeModal(true)
    }

    const openDealModal=()=>{
      window.globalAddDeal(true)
    }
    const classes=useStyle()
    const activeColor = "#36454F";
    const placeholderColorForDate= "rgb(8 40 82 / 42%)";
    return (
      <div>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <ModalHead>
            <ModalTitle>Add a New Policy</ModalTitle>
            <Divider style={{ width: "165px", backgroundColor: "#316aff" }} />
            {contactLabel&&
              <ModalContactChip>
                <BootstrapTooltip placement={"top"} arrow title={contactLabel}>
                  <span>{contactLabel}</span>
                </BootstrapTooltip>
              </ModalContactChip>
            }
          </ModalHead>
          <Box minWidth={600} p={"16px"}>
            <Box>
              <Typography className={classes.formLabel}>Policy Type</Typography>
              <Box display={"flex"} alignItems={"center"} gridGap={16}>
                <BasicSelect
                  fullWidth
                  name='policy'
                  defaultText='Select a option'
                  value={selectedPolicy}
                  options={policies}
                  onChange={(e) => setSelectedPolicy(e.target.value)}
                  mapping={{
                    label: "label",
                    value: "id",
                  }}
                />
                <Button onClick={handleAddNewPolicyType } variant='contained' className={classes.addNewButton} startIcon={<Add />}>
                  Add New
                </Button>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography className={classes.formLabel}>Insurance Company</Typography>
              <BasicSelect
                fullWidth
                name='policy'
                defaultText='Select a option'
                value={selectedInsuranceCompany}
                options={policies}
                onChange={(e) => setSelectedInsuranceCompany(e.target.value)}
                mapping={{
                  label: "label",
                  value: "id",
                }}
              />
            </Box>
            {/* <Box mt={2}>
              <Typography className={classes.formLabel}>Insurance Category</Typography>
              <BasicSelect
                fullWidth
                name='category'
                defaultText='Select category'
                value={selectedInsuranceCategory}
                options={policies}
                onChange={(e) => setSelectedInsuranceCategory(e.target.value)}
                mapping={{
                  label: "label",
                  value: "id",
                }}
              />
            </Box> */}
            <Box>
              <Box display={"flex"} gridGap={16} mt={2}>
                <Box>
                  <Typography className={classes.formLabel}>Policy Number</Typography>
                  <BasicInput type='number' onChange={(e)=>setPolicyNumber(e.target.value)} fullWidth placeholder='Enter Number' />
                </Box>
                <Box>
                  <Typography className={classes.formLabel}>Policy Link</Typography>
                  <BasicInput onChange={(e)=>setPolicyLink(e.target.value)} fullWidth placeholder='Enter Link' />
                </Box>
              </Box>
              <Box display={"flex"} gridGap={16} mt={2}>
                <Box width={"100%"}>
                  <Typography className={classes.formLabel}>Effective Date</Typography>
                  <BasicInput
                    fullWidth
                    type='date'
                    onChange={(e)=>setEffectiveDate(e.target.value)}
                    style={{ color: effectiveDate ? activeColor : placeholderColorForDate }}
                  />
                </Box>
                <Box width={"100%"}>
                  <Typography className={classes.formLabel}>Expiration Date</Typography>
                  <BasicInput
                    fullWidth
                    onChange={(e)=>setExpirationDate(e.target.value)}
                    type='date'
                    style={{ color: expirationDate ? activeColor : placeholderColorForDate }}
                  />
                </Box>
              </Box>
              <Box display={"flex"} gridGap={16} mt={2}>
                <Box width={"50%"}>
                  <Typography className={classes.formLabel}>Full Premium</Typography>
                  <BasicInputForIcon
                  type='number'
                    startAdornment={
                      <InputAdornment position='start'>
                        <AttachMoneyIcon
                          style={{ fontSize: "20px", color: fullPremium ? activeColor : placeholderColorForDate }}
                        />
                      </InputAdornment>
                    }
                    fullWidth
                    placeholder='Enter Premium'
                    onChange={(e)=>setFullPremium(e.target.value)}
                  />
                </Box>
                <Box width={"50%"}>
                  <Typography className={classes.formLabel}>Lead Source</Typography>
                  <BasicSelect
                    fullWidth
                    name='policy'
                    defaultText='Select a option'
                    value={selectedLeadSource}
                    options={policies}
                    onChange={(e) => setSelectedLeadSource(e.target.value)}
                    mapping={{
                      label: "label",
                      value: "id",
                    }}
                  />
                </Box>
              </Box>
              <Box mt={2}>
                <Typography className={classes.formLabel}>Agent</Typography>
                <BasicSelect
                  fullWidth
                  name='policy'
                  defaultText='Select an agent'
                  value={selectedAgent}
                  options={policies}
                  onChange={(e) => setSelectedAgent(e.target.value)}
                  mapping={{
                    label: "label",
                    value: "id",
                  }}
                />
              </Box>
              <Box mt={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.cancelButton}
                      size={"small"}
                      checked={isChecked}
                      // onChange={() =>  }}
                    />
                  }
                  className={classes.checkBoxStyle}
                  label=' Include this manual entry in sales reporting and goal calculations'
                />
                <Typography style={{ fontSize: "12px", paddingLeft: "28px", color: "#808080", fontWeight: "400" }}>
                  For accurate reporting you must associate this entry with a deal
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'end'} gridGap={16} mt={2}>
                <Button className={classes.cancelButton} onClick={()=>onClose()}>Cancel</Button>
                <Button variant='contained' className={classes.addDealButton} onClick={openDealModal}>
                   Add Deal
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </div>
    );
};

export default AddPolicyModal;