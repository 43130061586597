import React, { useEffect, useState } from "react";
import Styles from "./FileReportDetails.module.css";
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Box,
  Typography, Button
} from "@material-ui/core";
import { InfoRounded, KeyboardArrowDown } from "@material-ui/icons";
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from "../../globals/Dropdown/GlobalDropdown";
import { IconList } from "../../globals/IconList";
import { cleanContactsActionsApi, cleanDataFilesDetailsApi } from "../../../api/cleanDataApi";
import Pagination from "./Pagination";
import ModalAssignToList from "./actions/ModalAssignToList";
import ModalAssignToCampaign from "./actions/ModalAssignToCampaign";
import ModalAssignToTag from "./actions/ModalAssignToTag";
import ModalListForStageAdd from "./actions/ModalListForStageAdd";
import NewConfirmAlert from "../../common/new-alert/NewConfirmAlert";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import ValidationResponse from "./ValidationResponse";
import { CoreButton } from "../enhanceData/commons/CoreButton";
import ModalAssignToPowerDialer from "./actions/ModalAssignToPowerDialer";
import { green, red, blue } from "@material-ui/core/colors";
import ModalExportContact from "./actions/ModalExportContact";
import ModalMoreInfo from "./actions/ModalMoreInfo";
import Icons from "../../carrierLookup/icons";
const ActionButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#00ff91',
    color: 'black',
    '&:hover': {
      backgroundColor: '#80ffbf',
    },
    '&:active': {
      backgroundColor: '#00cc68',
    },
  },
}))(Button);

export const isNumber = (text) => {
  try {
    return /^\d+$/.test(text);
  } catch (err) {
    return false;
  }
};

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    fontSize: "14px !important",
    borderRadius: "4px !important",
    position: "relative",
    color: "#3a414a !important",
    border: "1px solid #ced4da !important",
    padding: "10px 26px 10px 12px !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
    },
    backgroundColor: "#ffffff",
  },
}))(InputBase);

// validate: valid-email, invalid-email, dnc-on, dnc-off, valid-number, invalid-number, skip address
export const validateFilterAction = [
  { title: "Valid Numbers", key: "validNumbers", props: "valid-numbers" },
  { title: "Invalid Numbers", key: "invalidNumbers", props: "invalid-numbers" },
  { title: "Valid Emails", key: "validEmails", props: "valid-emails" },
  { title: "Invalid Emails", key: "invalidEmails", props: "invalid-emails" },
  { title: "DNC On", key: "dncOn", props: "dnc-on" },
  { title: "DNC Off", key: "dncOff", props: "dnc-off" },
  { title: "Landline", key: "landline", props: "landline" },
  { title: "Mobile", key: "mobile", props: "mobile" },
  { title: "Skiptraced", key: "skipTrace", props: "skip-trace" },
];

export const getDncStatusLabelAndColor = (label) => {
  if (label === "VERIFIED_OK") return ["DNC Inactive", green[500]];
  else if (label === "DO_NOT_CALL") return ["DNC Active", red[500]];
  else if (label === "NOT_VERIFIED") return ["Not Verified", red[500]];
  else return ["", "error"];
};

export const getDncStatusIcon = (status) => {
  if (status === "VERIFIED_OK") return Icons.checkMark;
  else if (status === "DO_NOT_CALL") return Icons.doNotCall;
  else if (status === "NOT_VERIFIED") return '';
  else return "";
};

export const getLandlineMobileStatus = (value) => {
  if (!value) {
    return "NOT AVAILABLE";
  } else {
    return value.toUpperCase();
  }
};

export const getNumberValidationStatusLabelAndColor = (label) => {
  if (label === "VALID") return ["Valid", green[500]];
  if (label === "INVALID") return ["Invalid", red[500]];
  else if (label === "NOT_VERIFIED") return ["Not Validated", red[500]];
  else return ["", "error"];
};

export const getNumberValidationStatusIcon = (status) => {
  if (status === "VALID") return Icons.checkMark;
  if (status === "INVALID") return Icons.cross;
  else if (status === "NOT_VERIFIED") return "";
  else return "";
};

export const getEmailValidationStatusLabelAndColor = (label) => {
  if (label === "VALID") return ["Valid", green[500]];
  if (label === "INVALID") return ["Invalid", red[500]];
  else if (label === "NOT_VERIFIED") return ["Not Validated", red[500]];
  else return ["", "error"];
};

export const getEmailValidationStatusIcon = (status) => {
  if (status === "VALID") return Icons.checkMark;
  if (status === "INVALID") return Icons.cross;
  else if (status === "NOT_VERIFIED") return "";
  else return "";
};

export const getSkipTraceStatusLabelAndColor = (label) => {
  if (label === "PENDING" || label === "NOT_VERIFIED") return ["Not Validated", "default"];
  else if (label === "TRACED") return ["Traced", blue[900]];
  else if (label === "TRACE_FAILED") return ["Trace Failed", red[500]];
  else if (label === "NOT_VERIFIED") return ["Not Validated", red[500]];
  else return ["", "error"];
};

const FileReportDetailsSingle = ({ filter = [], fileSource = "ALL", tab }) => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [fileContacts, setFileContacts] = useState([]);
  const [filterBy, setFilterBy] = useState({
    invalidNumbers: false,
    validNumbers: false,
    invalidEmails: false,
    validEmails: false,
    dncOn: false,
    dncOff: false,
    skipTrace: false,
    landline: false,
    mobile: false,
  });
  const [selectedFileContactIds, setSelectedFileContactIds] = useState([]);
  const [showListModal, setShowListModal] = useState(false);
  const [showAddToCampaignModal, setShowAddToCampaignModal] = useState(false);
  const [showAddToPowerDialerModal, setShowAddToPowerDialerModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [openAddStageToContactModal, setOpenAddStageToContactModal] = useState(false);
  const [openExportFileModal, setOpenExportFileModal] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [isActiveActionBtn, setIsActiveActionBtn] = useState(false);

  const filterArray = filter.map((value) => {
    const result = validateFilterAction.find((filterValue) => filterValue.props === value);
    if (!result) throw Error("invalid filterBy type passed to component");
    return result;
  });

  const fetchFileDetails = () => {
    setLoading(true);
    cleanDataFilesDetailsApi({
      page: page,
      limit: limit,
      totalPage: totalPage,
      cleanDataRequestId: null,
      filterBy: Object.values(filterBy).every((value) => value === false) ? null : filterBy,
      fileSource,
    })
      .then((res) => {
        res = res.data;
        if (res.success) {
          setFileContacts(res.data);
          setTotalPage(res.totalPage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (page !== "") {
      fetchFileDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, filterBy]);

  const handleFilterChange = (name, value) => {
    setFilterBy({ ...filterBy, [name]: value });
  };

  const handleAddAllContactToCRM = () => {

    if (!fileContacts[0]) {
      window.showNotification("ERROR", "No contact available");
      return;
    }

    NewConfirmAlert({
      onSubmit: () => {
        const payload = {
          cleanContactIds: [],
          filterType: "ALL",
          action: "ADD_TO_CRM",
          pageSource: tab
        };

        if (filterBy) {
          if (filterBy.validNumbers || filterBy.invalidNumbers) {
            let numberStatus = [];

            if (filterBy.validNumbers) {
              numberStatus.push("VALID");
            }

            if (filterBy.invalidNumbers) {
              numberStatus.push("INVALID");
            }

            payload.numberStatus = numberStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.validEmails || filterBy.invalidEmails) {
            let emailStatus = [];

            if (filterBy.validEmails) {
              emailStatus.push("VALID");
            }

            if (filterBy.invalidEmails) {
              emailStatus.push("INVALID");
            }

            payload.emailStatus = emailStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.dncOn || filterBy.dncOff) {
            let dncStatus = [];

            if (filterBy.dncOn) {
              dncStatus.push("DO_NOT_CALL");
            }

            if (filterBy.dncOff) {
              dncStatus.push("VERIFIED_OK");
            }

            payload.dncStatus = dncStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.skipTrace) {
            payload.skipTraceStatus = ["TRACED"];
            payload.filterType = "SEGMENT";
          }

          if (filterBy.landline || filterBy.mobile) {
            let numberType = [];

            if (filterBy.landline) {
              numberType.push("landline");
            }

            if (filterBy.mobile) {
              numberType.push("mobile");
            }

            payload.numberType = numberType;
            payload.filterType = "SEGMENT";
          }
        }

        cleanContactsActionsApi(payload)
          .then((res) => {
            if (res && res.success) {
              window.showNotification("success", res.message);
            } else {
              window.showNotification("error", "Something went wrong try again later");
            }
          })
          .finally(() => { });
      },
      title: "Are your sure?",
      description: "You are about to add all contacts to CRM",
      cancelText: "Cancel",
      submitText: "Add",
      width: "480px",
    });
  };

  const handleAddSelectedContactToCRM = () => {

    if (!fileContacts[0]) {
      window.showNotification("ERROR", "No contact available");
      return;
    }

    if (selectedFileContactIds.length === 0 && !filterBy.validNumbers && !filterBy.invalidNumbers && !filterBy.validEmails && !filterBy.invalidEmails && !filterBy.dncOn && !filterBy.dncOff && !filterBy.skipTrace) {
      window.showNotification("ERROR", "Check at least one contact or any filter");
      return;
    }

    NewConfirmAlert({
      onSubmit: () => {
        const payload = {
          cleanContactIds: selectedFileContactIds,
          filterType: "SEGMENT",
          action: "ADD_TO_CRM",
          pageSource: tab
        };

        if (filterBy) {
          if (filterBy.validNumbers || filterBy.invalidNumbers) {
            let numberStatus = [];

            if (filterBy.validNumbers) {
              numberStatus.push("VALID");
            }

            if (filterBy.invalidNumbers) {
              numberStatus.push("INVALID");
            }

            payload.numberStatus = numberStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.validEmails || filterBy.invalidEmails) {
            let emailStatus = [];

            if (filterBy.validEmails) {
              emailStatus.push("VALID");
            }

            if (filterBy.invalidEmails) {
              emailStatus.push("INVALID");
            }

            payload.emailStatus = emailStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.dncOn || filterBy.dncOff) {
            let dncStatus = [];

            if (filterBy.dncOn) {
              dncStatus.push("DO_NOT_CALL");
            }

            if (filterBy.dncOff) {
              dncStatus.push("VERIFIED_OK");
            }

            payload.dncStatus = dncStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.skipTrace) {
            payload.skipTraceStatus = ["TRACED"];
            payload.filterType = "SEGMENT";
          }

          if (filterBy.landline || filterBy.mobile) {
            let numberType = [];

            if (filterBy.landline) {
              numberType.push("landline");
            }

            if (filterBy.mobile) {
              numberType.push("mobile");
            }

            payload.numberType = numberType;
            payload.filterType = "SEGMENT";
          }
        }

        cleanContactsActionsApi(payload)
          .then((res) => {
            if (res && res.success) {
              window.showNotification("success", res.message);
            } else {
              window.showNotification("error", "Something went wrong try again later");
            }
          })
          .finally(() => { });
      },
      title: "Are your sure?",
      description: "You are about to add selected contacts to CRM",
      cancelText: "Cancel",
      submitText: "Add",
      width: "480px",
    });
  };

  const handleDeleteSelectedContactToCRM = () => {

    if (!fileContacts[0]) {
      window.showNotification("ERROR", "No contact available");
      return;
    }

    if (selectedFileContactIds.length === 0 && !filterBy.validNumbers && !filterBy.invalidNumbers && !filterBy.validEmails && !filterBy.invalidEmails && !filterBy.dncOn && !filterBy.dncOff && !filterBy.skipTrace) {
      window.showNotification("ERROR", "Check at least one contact or any filter");
      return;
    }

    NewConfirmAlert({
      onSubmit: () => {
        const payload = {
          cleanContactIds: selectedFileContactIds,
          filterType: "SEGMENT",
          action: "DELETE_CONTACT",
          pageSource: tab
        };

        if (filterBy) {
          if (filterBy.validNumbers || filterBy.invalidNumbers) {
            let numberStatus = [];

            if (filterBy.validNumbers) {
              numberStatus.push("VALID");
            }

            if (filterBy.invalidNumbers) {
              numberStatus.push("INVALID");
            }

            payload.numberStatus = numberStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.validEmails || filterBy.invalidEmails) {
            let emailStatus = [];

            if (filterBy.validEmails) {
              emailStatus.push("VALID");
            }

            if (filterBy.invalidEmails) {
              emailStatus.push("INVALID");
            }

            payload.emailStatus = emailStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.dncOn || filterBy.dncOff) {
            let dncStatus = [];

            if (filterBy.dncOn) {
              dncStatus.push("DO_NOT_CALL");
            }

            if (filterBy.dncOff) {
              dncStatus.push("VERIFIED_OK");
            }

            payload.dncStatus = dncStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.skipTrace) {
            payload.skipTraceStatus = ["TRACED"];
            payload.filterType = "SEGMENT";
          }

          if (filterBy.landline || filterBy.mobile) {
            let numberType = [];

            if (filterBy.landline) {
              numberType.push("landline");
            }

            if (filterBy.mobile) {
              numberType.push("mobile");
            }

            payload.numberType = numberType;
            payload.filterType = "SEGMENT";
          }
        }

        cleanContactsActionsApi(payload)
          .then((res) => {
            if (res && res.success) {
              window.showNotification("success", "The selected contacts have been deleted");
              fetchFileDetails();
            } else {
              window.showNotification("error", "Something went wrong try again later");
            }
          })
          .finally(() => { });
      },
      title: "Are your sure?",
      description: "You are about to delete selected contacts",
      cancelText: "Cancel",
      submitText: "Yes",
      width: "480px",
    });
  };

  const handleClick = () => {
    setIsActiveActionBtn(!isActiveActionBtn);
  };

  const handleBlur = () => {
    setIsActiveActionBtn(false);
  };


  return (
    <div className={Styles.pageWrapper}>
      <div className={Styles.pageHeader}>
        <div style={{ display: "grid" }}>
          <Typography variant={"h6"}>Single/Multiple Validation Details</Typography>
          <Typography variant={"caption"}>
            Check the status of single/multiple email address(s) and phone number(s) you've validated
          </Typography>
        </div>
        <div className={Styles.pageHeaderAction}></div>
      </div>

      <div className={Styles.pageBody}>
        <div className={Styles.detailsPane}>
          <div className={Styles.detailsPaneHeader}>
            <div className={Styles.filterWrapper}>
              <Typography variant={"subtitle1"}>Filter Results: </Typography>
              {filterArray.map((value, index) => {
                return (
                  <div key={index} className={Styles.dFlexAlignCenter}>
                    <Checkbox
                      size={"small"}
                      checked={filterBy[value.key]}
                      onClick={() => {
                        setPage(1);
                        handleFilterChange(value.key, !filterBy[value.key]);
                      }}
                      color='primary'
                    />
                    <Typography variant={"body2"}>{value.title}</Typography>
                  </div>
                );
              })}
            </div>
            <GlobalDropdown className='add__action_dropdown' placement='bottomLeft'>
              <GlobalDropDownButton>
                {/*<CoreButton variant='contained' color='primary' endIcon={<KeyboardArrowDown />}>*/}
                {/*  Actions*/}
                {/*</CoreButton>*/}
                <ActionButton variant='contained' endIcon={<KeyboardArrowDown />} onClick={handleClick}
                  onBlur={handleBlur}
                  className={isActiveActionBtn ? 'active' : ''}>
                  sda Actions
                </ActionButton>
              </GlobalDropDownButton>
              <GlobalDropDownItem
                onClick={handleAddAllContactToCRM}
                title='Add All Contact to CRM'
                onClickHide={true}
                icon={IconList.addToContactSVG}
              />
              <GlobalDropDownItem
                onClick={handleAddSelectedContactToCRM}
                title='Add Selected Contact to CRM'
                onClickHide={true}
                icon={IconList.addToContactSVG}
              />
              <GlobalDropDownItem
                onClick={() => {
                  if (!fileContacts[0]) {
                    window.showNotification("ERROR", "No contact available");
                    return;
                  }
                  setShowListModal(true);
                }}
                title='Add To List'
                onClickHide={true}
                icon={IconList.addToListSVG}
              />
              <GlobalDropDownItem
                onClick={() => {
                  if (!fileContacts[0]) {
                    window.showNotification("ERROR", "No contact available");
                    return;
                  }
                  setShowAddToCampaignModal(true);
                }}
                title='Add To Campaign'
                onClickHide={true}
                icon={IconList.addToContactSVG}
              />
              <GlobalDropDownItem
                onClick={() => {
                  if (!fileContacts[0]) {
                    window.showNotification("ERROR", "No contact available");
                    return;
                  }

                  if (selectedFileContactIds.length === 0) {
                    window.showNotification("ERROR", "Check at least one contact");
                    return;
                  }
                  setShowAddToPowerDialerModal(true);
                }}
                title='Add To Power Dialer'
                onClickHide={true}
                icon={IconList.addToContactSVG}
              />
              <GlobalDropDownItem
                onClick={() => {
                  if (!fileContacts[0]) {
                    window.showNotification("ERROR", "No contact available");
                    return;
                  }
                  setShowTagModal(true);
                }}
                title='Assign Tag'
                onClickHide={true}
                icon={IconList.addTagSVG}
              />
              <GlobalDropDownItem
                onClick={() => {
                  if (!fileContacts[0]) {
                    window.showNotification("ERROR", "No contact available");
                    return;
                  }
                  setOpenAddStageToContactModal(true);
                }}
                title='Add to Pipeline Stage'
                onClickHide={true}
                icon={IconList.addToPipelineSVG}
              />
              <GlobalDropDownItem
                onClick={() => {
                  if (!fileContacts[0]) {
                    window.showNotification("ERROR", "No contact available");
                    return;
                  }
                  setExportAll(true);
                  setOpenExportFileModal(true);
                }}
                title='Export All Contacts'
                onClickHide={true}
                icon={IconList.exportContactSVG}
              />
              <GlobalDropDownItem
                onClick={() => {
                  if (!fileContacts[0]) {
                    window.showNotification("ERROR", "No contact available");
                    return;
                  }

                  if (selectedFileContactIds.length === 0 && !filterBy.validNumbers && !filterBy.invalidNumbers && !filterBy.validEmails && !filterBy.invalidEmails && !filterBy.dncOn && !filterBy.dncOff && !filterBy.skipTrace) {
                    window.showNotification("ERROR", "Check at least one contact or any filter");
                    return;
                  }
                  setExportAll(false);
                  setOpenExportFileModal(true);
                }}
                title='Export Selected Contacts'
                onClickHide={true}
                icon={IconList.exportContactSVG}
              />
              <GlobalDropDownItem
                onClick={handleDeleteSelectedContactToCRM}
                title='Delete Selected Contacts'
                onClickHide={true}
                icon={IconList.deleteSelectedContactSVG}
              />
            </GlobalDropdown>
          </div>
          <div className={Styles.detailsPaneBody}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={Styles.enhance_data_dnc_result_header}>
                      <div className={Styles.dFlexAlignCenter}>
                        <Checkbox
                          color='primary'
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                          checked={
                            fileContacts.length === selectedFileContactIds.length && selectedFileContactIds.length !== 0
                          }
                          indeterminate={
                            fileContacts.length > selectedFileContactIds.length && selectedFileContactIds.length !== 0
                          }
                          onClick={() => {
                            if (fileContacts.length === selectedFileContactIds.length) {
                              setSelectedFileContactIds([]);
                            } else {
                              setSelectedFileContactIds(fileContacts.map((fileContact) => fileContact.id));
                            }
                          }}
                        />
                        Name
                      </div>
                    </TableCell>
                    <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Email</TableCell>
                    <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Email Validation Status</TableCell>
                    <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Number</TableCell>
                    <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Number Validation Status</TableCell>
                    <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Landline/Mobile</TableCell>
                    <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>DNC Status</TableCell>
                    <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Skip Trace Status</TableCell>
                    <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Skip Trace Additional Info</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div className={Styles.loaderWrapper}>
                          <CircularProgress />
                          <h5 className={Styles.marginTop}>Getting file contact list....</h5>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : fileContacts[0] ? (
                    fileContacts.map((fileContact, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div className={Styles.dFlexAlignCenter}>
                            <Checkbox
                              color='primary'
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              checked={selectedFileContactIds.includes(fileContact.id)}
                              onClick={() => {
                                if (selectedFileContactIds.includes(fileContact.id)) {
                                  setSelectedFileContactIds(
                                    selectedFileContactIds.filter(
                                      (selectedFileContactId) => selectedFileContactId !== fileContact.id
                                    )
                                  );
                                } else {
                                  setSelectedFileContactIds([...selectedFileContactIds, fileContact.id]);
                                }
                              }}
                            />
                            {`${fileContact.first_name ?? ""} ${fileContact.last_name ?? ""}`}
                          </div>
                        </TableCell>
                        <TableCell className={Styles.textAlignCenter}>{fileContact.email}</TableCell>
                        <TableCell className={Styles.textAlignCenter}>
                          <Box style={{ display: "flex", alignItems: "center" }}>
                            <Chip
                              size={"small"}
                              variant={"default"}
                              color='primary'
                              style={{
                                backgroundColor: getEmailValidationStatusLabelAndColor(
                                  fileContact.email_valid_status
                                )[1],
                              }}
                              label={getEmailValidationStatusLabelAndColor(fileContact.email_valid_status)[0]}
                            />
                            {fileContact.email_validation_infos && (
                              <BootstrapTooltip arrow title='Click for details'>
                                <span
                                  onClick={() => {
                                    ValidationResponse({
                                      response: fileContact.email_validation_infos,
                                    });
                                  }}
                                  style={{ display: "inline-flex" }}
                                >
                                  <InfoRounded
                                    style={{
                                      color: "green",
                                      fontSize: "14px",
                                      marginLeft: "6px",
                                    }}
                                  />
                                </span>
                              </BootstrapTooltip>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell className={Styles.textAlignCenter}>{fileContact.number}</TableCell>
                        <TableCell className={Styles.textAlignCenter}>
                          <Box style={{ display: "flex", alignItems: "center" }}>
                            <Chip
                              size={"small"}
                              variant={"default"}
                              color='primary'
                              style={{
                                backgroundColor: getNumberValidationStatusLabelAndColor(
                                  fileContact.number_valid_status
                                )[1],
                              }}
                              label={getNumberValidationStatusLabelAndColor(fileContact.number_valid_status)[0]}
                            />
                            {fileContact.number_validation_infos && (
                              <BootstrapTooltip arrow title='Click for details'>
                                <span
                                  onClick={() => {
                                    ValidationResponse({
                                      response: fileContact.number_validation_infos,
                                    });
                                  }}
                                  style={{ display: "inline-flex" }}
                                >
                                  <InfoRounded
                                    style={{
                                      color: "green",
                                      fontSize: "14px",
                                      marginLeft: "6px",
                                    }}
                                  />
                                </span>
                              </BootstrapTooltip>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell className={Styles.textAlignCenter}>
                          <Chip
                            size='small'
                            variant='default'
                            color='primary'
                            label={getLandlineMobileStatus(fileContact.number_state)}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            size={"small"}
                            variant={"default"}
                            color='primary'
                            style={{
                              backgroundColor: getDncStatusLabelAndColor(fileContact.dnc_status)[1],
                            }}
                            label={getDncStatusLabelAndColor(fileContact.dnc_status)[0]}
                          />
                        </TableCell>
                        <TableCell>
                          {fileContact.skip_trace_status && (
                            <Chip
                              size={"small"}
                              variant={"default"}
                              color='primary'
                              style={{
                                backgroundColor: getSkipTraceStatusLabelAndColor(fileContact.skip_trace_status)[1],
                              }}
                              label={getSkipTraceStatusLabelAndColor(fileContact.skip_trace_status)[0]}
                            />
                          )}
                        </TableCell>
                        <TableCell className={Styles.textAlignCenter}>
                          {fileContact && fileContact.skip_trace_additional_infos && (
                            <BootstrapTooltip arrow title='More Info'>
                              <span
                                onClick={() => ModalMoreInfo(fileContact.skip_trace_additional_infos)
                                }
                                style={{ display: "inline-flex", borderRadius: "16px", padding: "5px", fontSize: "0.8125rem", backgroundColor: "#3f51b5", color: "#fff", cursor: "pointer" }}
                              >
                                More Info
                              </span>
                            </BootstrapTooltip>
                          )}

                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div className={Styles.loaderWrapper}>
                          <h5 className={Styles.marginTop}>No contact found!</h5>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display='flex' justifyContent='center'>
              <Box display='flex' alignItems='center'>
                <FormControl className={Styles.formControl}>
                  <Select
                    style={{ width: "200px" }}
                    name='perPage'
                    value={limit}
                    displayEmpty
                    onChange={(e) => {
                      setPage(1)
                      setLimit(e.target.value);
                    }}
                    input={<BootstrapInput />}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem className='dropdownhelper-menuitem-class' value={""} disabled>
                      # of Results
                    </MenuItem>
                    <MenuItem className='dropdownhelper-menuitem-class' value={5}>
                      5 of Results
                    </MenuItem>
                    <MenuItem className='dropdownhelper-menuitem-class' value={10}>
                      10 of Results
                    </MenuItem>
                    <MenuItem className='dropdownhelper-menuitem-class' value={25}>
                      25 of Results
                    </MenuItem>
                    <MenuItem className='dropdownhelper-menuitem-class' value={50}>
                      50 of Results
                    </MenuItem>
                  </Select>
                </FormControl>

                <Pagination
                  page={page}
                  totalPage={totalPage}
                  callback={(uPage) => {
                    setPage(uPage);
                  }}
                />
              </Box>
            </Box>
          </div>
        </div>
      </div>

      {showListModal && (
        <ModalAssignToList
          open={showListModal}
          selectedFileContactIds={selectedFileContactIds}
          onClose={() => setShowListModal(false)}
          filter={filterBy}
          tab={tab}
        />
      )}

      {showAddToCampaignModal && (
        <ModalAssignToCampaign
          open={showAddToCampaignModal}
          onClose={() => setShowAddToCampaignModal(false)}
          selectedFileContactIds={selectedFileContactIds}
          filter={filterBy}
          tab={tab}
        />
      )}

      {showAddToPowerDialerModal && (
        <ModalAssignToPowerDialer
          open={showAddToPowerDialerModal}
          onClose={() => setShowAddToPowerDialerModal(false)}
          selectedFileContactIds={selectedFileContactIds}
          filter={filterBy}
          tab={tab}
        />
      )}

      {showTagModal && (
        <ModalAssignToTag
          open={showTagModal}
          selectedFileContactIds={selectedFileContactIds}
          filter={filterBy}
          onClose={() => setShowTagModal(false)}
          tab={tab}
        />
      )}

      {openAddStageToContactModal && (
        <ModalListForStageAdd
          open={openAddStageToContactModal}
          onClose={() => setOpenAddStageToContactModal(false)}
          selectedFileContactIds={selectedFileContactIds}
          filter={filterBy}
          tab={tab}
        />
      )}

      <ModalExportContact
        exportAll={exportAll}
        filter={filterBy}
        openExportFileModal={openExportFileModal}
        onClose={() => setOpenExportFileModal(false)}
        selectedFileContactIds={selectedFileContactIds}
        tab={tab}
      />
    </div>
  );
};

export default FileReportDetailsSingle;
