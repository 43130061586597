import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";
import {sendRequest} from "./rootApi";

export const createUserProduct = (body) => {
  return HttpRequest.post(Api.createUserProduct, body);
};

export const getContactSoldProducts = (id) => {
  return HttpRequest.get(`${Api.getContactSoldProducts}/${id}`);
};

export const userProductStatus = async (payload) => {
  return HttpRequest.put(`${Api.userProductStatus}/${payload.id}`, payload);
};

export const changeDealStatus = async (payload) => {
  return sendRequest("post", Api.changeDealStatus, payload);
}