import React, { Component } from 'react';
import Task from "./Task.js";
import { confirmAlert } from "react-confirm-alert";
import { AccordionDetails, AccordionSummary, Avatar, FormControlLabel } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { taskMarkAsDone } from '../redux/contactApi.js';
import { connect } from 'react-redux'
import { getSubUserList, setSubUserListFromAction } from '../redux/contactAction.js';
import Utils from '../Helpers/Utils.js';
import StyledAccordion from "../../../common/StyledAccordion";

class TaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            task: [],
            count: 0,
            showModal: false,
            showDone: false,

            editData: null,
            editId: null
        }
    }

    changeVisibility = () => {
        this.setState({
            show: !this.state.show
        })
    };

    modalToggle = () => {

        window.openGlobalActivityModal({
            subUserList: this.props.subUserList, contactDetails: this.props.people, editData: this.state.editData,
            callBack: this.props.refreshData, taskType: this.props.from
        })
    };

    refreshData = () => {
        this.props.refreshData();
    };

    updateTask = (data) => {
        this.setState({
            showModal: false
        });
        this.props.refreshData();
    };

    makeDone = (e, index) => {
        taskMarkAsDone({
            id: e.target.value
        }).then(res => {

            if (res.data.success) {
                window.showNotification('SUCCESS', 'Task marked as done!')
                let tempTasks = [...this.state.task]
                tempTasks[index].status = 2;
                this.setState({ task: tempTasks })

            }

            // this.props.refreshData();
        });
    };

    quickFolowup = (date) => {
        if (this.props.people != null && this.props.people.user_id != Utils.getAccountData('userId')) { // eslint-disable-line eqeqeq
            confirmAlert({
                title: 'Alert!',
                message: 'You can not add or update activity to member user lead.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
            return false;
        }
    };

    showDoneTask = () => {
        this.setState({
            showDone: !this.state.showDone
        })
    };

    componentWillReceiveProps(nextProps) {

        this.setState({
            task: nextProps.activity
        });
    }

    handleUpdate = (id, data) => {
        // this.setState({
        //     // editId: id,
        //     editData: data,
        //     showModal: !this.state.showModal
        // })
        window.openGlobalActivityModal({
            subUserList: this.props.subUserList, contactDetails: this.props.people, editData: data,
            callBack: this.props.refreshData, taskType: this.props.from
        })
    }

    renderAddButton = () => {
        return (
            <div className="collaborator__list__right" onClick={(event) => event.stopPropagation()}>
                <Avatar onClick={this.modalToggle} style={{ marginLeft: 10 }} >
                    <i class="material-icons rightsidebar__list__addicon">
                        add
                    </i>
                </Avatar>
            </div>
        );
    }

    render() {
        let count = 0;
        let countDone = 0;

        let task = this.state.task.map((data, index) => {

            if (this.props.from === 'Task') {
                if (data.task_id !== 2 && data.status !== 2) {
                    if (data.status !== 2) count++;
                    return <Task refreshData={this.refreshData} people={this.props.people} makeDone={this.makeDone} index={index} key={`${index}${data.status}`} data={data} handleUpdate={this.handleUpdate} />;
                }
                return null
            } else if (this.props.from === 'Appointment') {
                if (data.task_id === 2 && data.status !== 2) {
                    if (data.status !== 2) count++;
                    return <Task refreshData={this.refreshData} people={this.props.people} makeDone={this.makeDone} index={index} key={index} data={data} handleUpdate={this.handleUpdate} />;
                }
                return null
            }
            else
                return null;

        });

        let taskDone = this.state.task.map((data, index) => {
            if (this.props.from === 'Task') {

                if (data.task_id !== 2 && data.status === 2) {
                    if (data.status === 2) countDone++;
                    return <Task refreshData={this.refreshData} makeDone={null} people={this.props.people} key={index} data={data} />;
                }
                return null;

            } else if (this.props.from === 'Appointment') {
                if (data.task_id === 2 && data.status === 2) {
                    if (data.status === 2) countDone++;
                    return <Task refreshData={this.refreshData} makeDone={null} people={this.props.people} key={index} data={data} />;
                }
                return null;


            }
            else
                return null;

        });

        return (
            <>
                <StyledAccordion>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <FormControlLabel
                            aria-label="Acknowledge"
                            control={
                                <div className="collaborator__list__parant">
                                    <div className="collaborator__list__left">
                                        <span className="collaborator__left__icon">
                                            {this.props.from === 'Task' &&
                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.2322 3.72473L17.1766 1.66917C17 1.50071 16.7652 1.40674 16.5211 1.40674C16.277 1.40674 16.0422 1.50071 15.8655 1.66917L14.0378 3.50251H4.2322C3.93752 3.50251 3.6549 3.61957 3.44653 3.82794C3.23816 4.03632 3.12109 4.31893 3.12109 4.61362V16.8358C3.12109 17.1305 3.23816 17.4131 3.44653 17.6215C3.6549 17.8299 3.93752 17.947 4.2322 17.947H16.4544C16.7491 17.947 17.0317 17.8299 17.2401 17.6215C17.4485 17.4131 17.5655 17.1305 17.5655 16.8358V6.70251L19.2322 5.03584C19.4059 4.86189 19.5035 4.62611 19.5035 4.38028C19.5035 4.13446 19.4059 3.89868 19.2322 3.72473ZM11.36 11.3525L9.0322 11.8692L9.58776 9.56362L14.8933 4.24695L16.6878 6.04139L11.36 11.3525ZM17.2878 5.40806L15.4933 3.61362L16.5211 2.58584L18.3155 4.38028L17.2878 5.40806Z"></path>
                                                </svg>
                                            }
                                            {this.props.from === 'Appointment' &&
                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.5 0.84375C4.89111 0.84375 0.34375 5.39111 0.34375 11C0.34375 16.6001 4.90039 21.1562 10.5 21.1562C16.1084 21.1562 20.6562 16.6084 20.6562 11C20.6562 5.3999 16.1001 0.84375 10.5 0.84375ZM15.7734 12.5625H9.91406C9.86226 12.5625 9.81258 12.5419 9.77596 12.5053C9.73933 12.4687 9.71875 12.419 9.71875 12.3672V4.16406C9.71875 4.11226 9.73933 4.06258 9.77596 4.02596C9.81258 3.98933 9.86226 3.96875 9.91406 3.96875H11.0859C11.1377 3.96875 11.1874 3.98933 11.224 4.02596C11.2607 4.06258 11.2812 4.11226 11.2812 4.16406V11H15.7734C15.8252 11 15.8749 11.0206 15.9115 11.0572C15.9482 11.0938 15.9688 11.1435 15.9688 11.1953V12.3672C15.9688 12.419 15.9482 12.4687 15.9115 12.5053C15.8749 12.5419 15.8252 12.5625 15.7734 12.5625Z" fill="#133159" fillOpacity="0.65"></path>
                                                </svg>
                                            }
                                        </span>
                                        <span className="collaborator__list--title text-dark-blue">
                                            {this.props.from}s
                                        </span>
                                        <Avatar>{count}</Avatar>
                                    </div>
                                    {this.renderAddButton()}
                                </div>
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="task-list-wrapper">
                            <div className="m-widget2 custom-list-container">
                                {task}
                            </div>
                            {countDone > 0 &&

                                <div className="m-widget2 custom-list-container">
                                    <div className="m--margin-top-15">
                                        <a className="showDoneTask" onClick={(e) => { e.preventDefault(); this.showDoneTask() }} href="#!">
                                            {this.state.showDone ? "Hide " : "View "} {countDone} completed {this.props.from === 'Task' ? "Tasks" : "appointments"}
                                        </a>

                                    </div>
                                    {
                                        this.state.showDone &&
                                        <>{taskDone}</>
                                    }
                                </div>
                            }
                            {countDone === 0 &&

                                <div className="m-widget2 custom-list-container">
                                    <div className="m--margin-top-15">
                                        <a className="showDoneTask" href="#!" onClick={(e) => e.preventDefault()}>
                                            No completed {this.props.from === 'Task' ? "Tasks" : "Appointments"}
                                        </a>

                                    </div>
                                    {
                                        this.state.showDone &&
                                        <>{taskDone}</>
                                    }
                                </div>
                            }
                        </div>

                    </AccordionDetails>
                </StyledAccordion>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        allContactList: state.rightsideReducer.allContactList,
        userInfo: state.rightsideReducer.userInfo,
        subUserList: state.rightsideReducer.subUserList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
        getAllSubUsers: (params, callback) => dispatch(getSubUserList(params, callback))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
