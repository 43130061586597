import React, { Fragment, useState } from 'react';
import EmptyPolicies from './EmptyPolicies';
import AddPolicyModal from './AddPolicyModal';
import AddNewPolicyTypeModal from './AddNewPolicyTypeModal';
import {
    Add as AddIcon
} from "@material-ui/icons";
import {  BasicButton, InsuranceCardContainer, InsuranceWrapper, NewInsurancePolicy } from './PolicyStyle';
import EachInsurance from './EachInsurance';
import {data} from './fakeData'


const Policies = ({contact}) => {
    const[openPolicyModal,setOpenPolicyModal] = useState(false);
    const[openAddNewPolicyTypeModal,setOpenAddNewPolicyTypeModal] = useState(false);


    return (
      <div>
        <InsuranceWrapper>
          <Fragment>
            <NewInsurancePolicy>
                <BasicButton startIcon={<AddIcon/>} onClick={()=>setOpenPolicyModal(true)}>
                    New Policy
                </BasicButton>
            </NewInsurancePolicy>
          <InsuranceCardContainer  className={"awesome__scroll_bar"}>
          {
             data.map(item=>(
               <EachInsurance item={item}/>
             ))
            }
              {/* <EmptyPolicies setOpenPolicyModal={setOpenPolicyModal}/>  */}
          </InsuranceCardContainer>
           
            
          </Fragment>
        </InsuranceWrapper>
      

        {openPolicyModal && (
          <AddPolicyModal
            open={openPolicyModal}
            contact={contact}
            setOpenAddNewPolicyTypeModal={setOpenAddNewPolicyTypeModal}
            onClose={() => setOpenPolicyModal(false)}
          />
        )}
        {openAddNewPolicyTypeModal && (
          <AddNewPolicyTypeModal open={openAddNewPolicyTypeModal} onClose={() => setOpenAddNewPolicyTypeModal(false)} />
        )}
      </div>
    );
};

export default Policies;