import React, { useState, useEffect } from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledAccordion from "../../../common/StyledAccordion";
import SecurityIcon from '@material-ui/icons/Security';
import EachInsurance from "./EachInsurance.jsx";
import { Box } from "@material-ui/core";
import { customScrollBar } from "./PolicyStyle.jsx";


const Policy = () => {
let count=1;
  return (
    <React.Fragment>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls='panel1a-content' id='panel1a-header'>
          <FormControlLabel
            aria-label='Acknowledge'
            control={
              <React.Fragment>
                <div className='collaborator__list__parant'>
                  <div className='collaborator__list__left'>
                    <span className='collaborator__left__icon'>
                   <SecurityIcon style={{height:'21px' , width:'22px'}}/>
                    </span>{" "}
                    <span className='collaborator__list--title text-dark-blue m-0'>Policy </span>{" "}
                    <Avatar>
                      <h6 class='white text-light-blue circle m-0 items_counter d-flex justify-content-center align-items-center'>
                        {count}
                      </h6>
                    </Avatar>{" "}
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </AccordionSummary>
        <AccordionDetails style={{padding:0}}>
          <Box mt={1}><EachInsurance/></Box>
        </AccordionDetails>
      </StyledAccordion>
    </React.Fragment>
  );
};

export default Policy;
