import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
//moved to cdn (don not remove)
// import '../contact-list.css'
// import '../contact-list-responsive.css'
import { getContactSchedules, getContactTimeline, getRelatedContactConversation, removeScheduleBroadcast } from '../../../api/contactApi';
import NoteList from './NoteList';
import FileList from './FileList';
import CoreConstants from '../../../constants/CoreConstants';
import LogList from './Loglist';
import ContactTimelineLog from './ContactTimelineLog';
import { reloadConversationList, toggleNoteModal } from '../../../actions/contactAction';
import ActivityList from './ActivityList/ActivityList';
import NewLoader from '../../common/NewLoader';
import { withTransaction } from '@elastic/apm-rum-react'
import Skeleton from '@material-ui/lab/Skeleton';
import "./MiddleSide.css";
import DealConfirmAlert from '../../common/custom-alert/new-alert/DealConfirmAlert';
import { useHistory } from "react-router-dom";
import NoteComponent from "../leftSide/note/NoteComponent";
import CustomDropdown from "../../globals/CustomDropdown";
import { ActivityIcon, ConversationIcon, FileIcon, LogIcon, NoteIcon, ProductIcon } from "./Icons";
import Products from "./Products";
import Utils from "../../../helpers/Utils";
import Policies from './policies/Policies';

const RELATED_CONTACTS_ID = 'related_contacts';
const allCommunicationTypesV2 = [
    { id: '0', value: 'All' },
    { id: 'scheduled', value: 'Scheduled' },
    { id: '4,14', value: 'Email/Video Email' },
    { id: '1,2', value: 'SMS/MMS' },
    { id: '3', value: 'Ringless Vmail' },
    { id: '5,7', value: 'All Calls' },
    { id: '5', value: 'Incoming Calls' },
    { id: '7', value: 'Outgoing Calls' },
    { id: '25', value: 'Call Recordings' },
    { id: '15', value: 'Chat' },
    { id: '21,22,24', value: 'Direct Mail' },
    { id: '23', value: 'Gifts' },
    { id: '26,30', value: 'Completed Forms' },
];
/* 
All
Scheduled
Email/Video Email
SMS/MMS
Ringless Vmail
All Calls
Incoming Calls
Outgoing Calls
Call Recordings
Chat
Direct Mail
Gifts
Completed Forms
*/
if (Utils.getAccountData("userId") === 1 || Utils.getAccountData("userId") === 446) {
    allCommunicationTypesV2.push({ id: RELATED_CONTACTS_ID, value: 'Related Contacts' });
}
const allCommunicationTypeArray = [0, CoreConstants.Timeline.CONTENT_TYPE_SMS, CoreConstants.Timeline.CONTENT_TYPE_MMS,
    CoreConstants.Timeline.CONTENT_TYPE_OUTGOING_CALL, CoreConstants.Timeline.CONTENT_TYPE_LETTER, CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD,
    CoreConstants.Timeline.CONTENT_TYPE_VOICE, CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL, CoreConstants.Timeline.CONTENT_TYPE_POST_CARD,
    CoreConstants.Timeline.CONTENT_TYPE_GIFT, CoreConstants.Timeline.CONTENT_TYPE_EMAIL, CoreConstants.Timeline.CONTENT_TYPE_CALL,
    CoreConstants.Timeline.CONTENT_TYPE_CALL_RECORD, CoreConstants.Timeline.CONTENT_TYPE_FORM_RESPONSE,
    CoreConstants.Timeline.CONTENT_TYPE_CALL_BRIGDE, CoreConstants.Timeline.CONTENT_TYPE_CHAT, CoreConstants.Timeline.CONTENT_TYPE_SMART_FORM_RESPONSE];

const MiddleSide = (props) => {
    const history = useHistory();
    const [componentLoading, setComponentLoading] = useState(true);
    const [contactTimelineList, setContactTimelineList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(false);
    const [selectedTab, setSelectedTab] = useState('all');
    const [contactFullName, setContactFullName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contentTypeList, setContentTypeList] = useState(allCommunicationTypeArray);
    const [selectedDropdown, setSelectedDropdown] = useState(allCommunicationTypeArray[0])
    const [scrolling, setScrolling] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true)
    const [scheduleTimelineList, setScheduleTimelineList] = useState([])

    useEffect(() => {
        try {
            if (history.location.state) {
                if (history.location.state?.for === "show-mention-user") {
                    if (history.location.state?.notificationType == 3) {
                        setSelectedTab('note')
                    }
                }
            }
        }
        catch (err) {
            console.log("history.location.state", history.location.state, err)
        }
    }, [])

    useEffect(() => {
        loadJSForHeight();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setComponentLoading(true)
        if (selectedDropdown === allCommunicationTypesV2[1].id) {
            fetchScheDuleData();
        } else {
            loadData(1);

        }
        updateContactInfo();
    }, [props.contact]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (selectedDropdown === allCommunicationTypesV2[1].id) {
            fetchScheDuleData();
        } else {
            loadData(1);

        }
    }, [selectedDropdown])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.reloadConversationStatus) {
            if (!firstLoad) {
                setTimeout(() => {
                    setCurrentPage(1)
                    loadData(1)
                    props.reloadNeedForConversation(false);
                }, 2000)

            } else {
                props.reloadNeedForConversation(false);

            }
        }
        if (firstLoad) {
            setFirstLoad(false)
        }

    }, [props.reloadConversationStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadJSForHeight = () => {

        try {

            if (document.querySelector('.single__contact_height .side_content_row .contact_details.white')) {
                let heightForMiddleSide = document.querySelector('.single__contact_height .side_content_row .contact_details.white').offsetHeight;
                document.querySelector('.single__contact_height .side_content_row .contact_center_field.main-inbox_column').style.height = heightForMiddleSide + 'px';
            }
        } catch (e) {
            console.log(e)
        }

    }

    const updateSearchMailType = (type) => {
        if (type === allCommunicationTypesV2[0].id || type === RELATED_CONTACTS_ID) {
            setContentTypeList(allCommunicationTypeArray)
            // loadData(1, type)
        }
        // else if (type == CoreConstants.Timeline.CONTENT_TYPE_SCHEDULE_CONVERSATION) {
        else if (type === allCommunicationTypesV2[1].id) {
            setContentTypeList([type])
            // fetchScheDuleData();
        }
        else {
            // setContentTypeList([type])
            try {
                setContentTypeList(type.split(',').map(Number))
            }
            catch (error) {
                setContentTypeList(allCommunicationTypeArray)
            }
        }
        setSelectedDropdown(type)
    }

    const fetchScheDuleData = async () => {
        setComponentLoading(true)

        await getContactSchedules({ contact_id: props.contactId })
            .then(response => {
                if (response.data !== undefined) {
                    if (response.data.success) {
                        setScheduleTimelineList(response.data.data)

                    } else {
                        setScheduleTimelineList([])
                    }
                    setComponentLoading(false)

                } else {
                    setComponentLoading(false)
                }
            })
    }

    const loadData = async (page) => {

        if (selectedDropdown === RELATED_CONTACTS_ID) {
            setComponentLoading(true);
            await getRelatedContactConversation({ contact_id: props.contactId, page: page })
                .then(response => {

                    if (response.data !== undefined) {
                        if (page === 1) {
                            setContactTimelineList(response.data.data)
                        } else {
                            setContactTimelineList(contactTimelineList.concat(response.data.data))
                        }

                        setComponentLoading(false)
                        if (response.data.to < response.data.total) {
                            setHasMoreData(true);
                            setCurrentPage(page + 1)
                        } else {
                            setHasMoreData(false);
                        }
                    }
                    else if (page > 1) {
                        setComponentLoading(false)
                        setHasMoreData(false);
                    } else {
                        setContactTimelineList([])
                        setComponentLoading(false)
                        setHasMoreData(false);

                    }
                    setScrolling(false)
                    if (page <= 1) {
                        if (window.updateNotificationCount !== undefined) {
                            window.updateNotificationCount()
                        }
                    }
                })
        } else {
            setComponentLoading(true);
            await getContactTimeline({ contact_id: props.contactId, page: page })
                .then(response => {
                    if (response.data !== undefined) {

                        if (page === 1) {
                            setContactTimelineList(response.data.data.data)
                        } else {

                            setContactTimelineList(contactTimelineList.concat(response.data.data.data))

                        }

                        setComponentLoading(false)
                        if (response.data.data.to < response.data.data.total) {
                            setHasMoreData(true);
                            setCurrentPage(page + 1)
                        } else {
                            setHasMoreData(false);
                        }
                    }
                    else if (page > 1) {
                        setComponentLoading(false)
                        setHasMoreData(false);
                    } else {
                        setContactTimelineList([])
                        setComponentLoading(false)
                        setHasMoreData(false);

                    }
                    setScrolling(false)
                    if (page <= 1) {
                        if (window.updateNotificationCount !== undefined) {
                            window.updateNotificationCount()
                        }
                    }
                })
        }
    }

    const updateContactInfo = async () => {


        let firstName = (props.contact.first_name !== undefined && props.contact.first_name != null) ? props.contact.first_name.trim() : '';
        let lastName = (props.contact.last_name !== undefined && props.contact.last_name != null) ? props.contact.last_name.trim() : '';

        setContactFullName((firstName + ' ' + lastName).trim())
        setContactNumber(props.contact.number !== undefined && props.contact.number != null ? props.contact.number : '')
        setContactEmail(props.contact.email !== undefined && props.contact.number != null ? props.contact.email : '')

    }

    const loadMoreData = (e) => {
        if (selectedDropdown !== 'scheduled' && hasMoreData && !scrolling && Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)) {
            setScrolling(true);
            setTimeout(() => {
                if (selectedDropdown === RELATED_CONTACTS_ID) {
                    loadData(currentPage, selectedDropdown);
                } else {
                    loadData(currentPage);
                }
            }, 500);
        }
    }

    const removeSchedule = async (scheduleId, index) => {

        DealConfirmAlert({
            onSubmit: async () => {
                await removeScheduleBroadcast({ id: scheduleId })
                    .then(response => {
                        if (response.data.status === 'success') {
                            let tempScheduleList = [...scheduleTimelineList];
                            tempScheduleList.splice(index, 1)
                            setScheduleTimelineList(tempScheduleList)
                        }
                    })
            },
            title: `Are you sure?`,
            description: '',
            cancelText: 'No',
            symbol: 3,
            submitText: 'Yes'
        });

    }

    const loadingSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 5; i++) {
            skeletons.push(
                <div key={i} className={`skeleton_each_conversation ${(i % 2 === 0) ? 'skeleton_right' : 'skeleton_left'}`}>
                    <Skeleton className="skeleton_conversation_avater" variant="circle" width={50} height={50} />
                    <div className="skeleton_conversation_details">
                        <Skeleton variant="rect" width={300} height={80} />
                        <Skeleton variant="text" width={200} />
                        <Skeleton variant="text" width={200} />
                    </div>
                </div>
            )
        }
        return skeletons;
    }

    return (
        <React.Fragment>
            {/* <div className="col s12 m12 l5 xl6 px-4 contact_center_field main-inbox_column"> */}
            <div className="col s12 m12 l5 xl6 px-4 contact_center_field main-inbox_column">

                <div className="center_field_tabs white contact__middle__inbox__wrapper">
                    <div className="row m-0">
                        <div className="col s12 center_field_top py-3 contact__middle__inbox__top">
                            <ul className="tabs d-flex justify-content-between align-items-center contact__middle__inbox__ul">
                                <li style={{ marginLeft: '6px' }} className='tab col contact__middle__inbox__top__col1'>
                                    <a
                                        className={`px-1 ${selectedTab === "all" ? "active" : ""}`}
                                        href='#!'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedTab("all");
                                            updateSearchMailType(allCommunicationTypeArray[0])
                                        }}
                                    >
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <ConversationIcon className=' mr-2' />
                                            <p className='text-gray m-0 pc-tab-text'>Conversations</p>
                                        </div>
                                    </a>
                                </li>
                            {
                                (Utils.getAccountData("agencyBusinessType") === undefined || Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ? 
                                 <li className="tab col contact__middle__inbox__top__col1">
                                    <a className={`px-1 ${selectedTab === 'products' ? "active" : ""}`} href="#!"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedTab('products');
                                    }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <ProductIcon className="mb-1 mr-2"/>
                                            <p className="text-gray m-0 pc-tab-text">Products</p>
                                        </div>
                                    </a>
                                 </li>
                               :
                                <li className="tab col contact__middle__inbox__top__col1">
                                    <a className={`px-1 ${selectedTab === 'policies' ? "active" : ""}`} href="#!"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedTab('policies');
                                        }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <ProductIcon className="mb-1 mr-2"/>
                                            <p className="text-gray m-0 pc-tab-text">Policies</p>
                                        </div>
                                    </a>
                                </li>
                            }
                            

                                <li className="tab col contact__middle__inbox__top__col1">
                                    <a className={`px-1 ${selectedTab === 'note' ? "active" : ""}`} href="#!"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedTab('note');
                                        }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <NoteIcon className="mb-1 mr-2" />
                                            <p className="text-gray m-0 pc-tab-text">Notes</p>
                                        </div>
                                    </a>
                                </li>
                                <li className="tab col contact__middle__inbox__top__col2">
                                    <a className={`px-1 ${selectedTab === 'activities' ? "active" : ""}`} href="#!"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedTab('activities');
                                        }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <ActivityIcon className="mr-2" />
                                            <p className="text-gray m-0 pc-tab-text">Activities</p>
                                        </div>
                                    </a>
                                </li>
                                <li className="tab col contact__middle__inbox__top__col3">
                                    <a href="#files_tab" className={`px-1 ${selectedTab === 'files' ? "active" : ""}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedTab('files');
                                        }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <FileIcon className="mb-1 mr-2" />
                                            <p className="text-gray m-0 pc-tab-text">Files</p>
                                        </div>
                                    </a>
                                </li>
                                <li className="tab col contact__middle__inbox__top__col4">
                                    <a href="#log_tab" className={`px-1 ${selectedTab === 'logs' ? "active" : ""}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedTab('logs');
                                        }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <LogIcon className="mb-1 mr-2" />
                                            <p className="text-gray m-0 pc-tab-text">Log</p>
                                        </div>
                                    </a>
                                </li>
                                <li className="indicator" style={{ left: 0, right: 439 }}></li>
                            </ul>
                        </div>
                        {/* <!-- single contact center field conversation --> */}
                        {/* {componentLoading && */}
                        {/* <NewLoader loading={componentLoading} onlyLoader={true} size={25}/> */}
                        {/* } */}

                        <div className="pr-0 col s12">
                            {selectedTab === "all" &&
                                <CustomDropdown
                                    defaultText={"Select Type"}
                                    options={allCommunicationTypesV2}
                                    value={`${selectedDropdown}`}
                                    fullWidth={false}
                                    mapping={{
                                        label: "value",
                                        value: "id"
                                    }}
                                    onChange={(event) => {
                                        updateSearchMailType(event.target.value);
                                    }}
                                />}
                            {
                                (componentLoading || props.loadingData) ? (
                                    <div className="inbox_loader"
                                        style={{ padding: '0px 10px', display: 'block', overflow: 'hidden' }}>
                                        {loadingSkeleton()}
                                    </div>
                                ) : (
                                    <div className="all-messages" id="cutomScrollbar1" onScroll={(e) => loadMoreData(e)}>
                                        {
                                            scrolling && (
                                                <div className="load-more-inbox-thread">Loading more...</div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>

                        {!componentLoading && !props.loadingData &&
                            <React.Fragment>
                                {selectedTab === 'all' &&
                                    <div id="conversation_tab"
                                        className="col s12 active contact__middle__inbox__conversation__tab"
                                        style={{ display: "block" }}>
                                        <div
                                            className="awesome__scroll_bar conversation_container contact__middle__inbox__conversation__container"
                                            onScroll={(e) => loadMoreData(e)}
                                        >
                                            <div className=" message__container contact__middle__inbox__msg__container">

                                                {/* {viewContactTimelineLog()} */}
                                                <ContactTimelineLog
                                                    contact={props.contact}
                                                    contactTimelineList={contactTimelineList}
                                                    contentTypeList={contentTypeList} contactFullName={contactFullName}
                                                    contactNumber={contactNumber} contactEmail={contactEmail}
                                                    scheduleTimelineList={scheduleTimelineList}
                                                    contactInfo={props.contact}
                                                    removeSchedule={removeSchedule}
                                                    reloadNeedForConversation={props.reloadNeedForConversation}
                                                />

                                            </div>
                                        </div>
                                        {
                                            scrolling && (
                                                <div className="scroll-loader">
                                                    <NewLoader loading={scrolling} onlyLoader={true} size={25} />
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                                {selectedTab === 'note' &&
                                    <NoteList contactId={props.contact.id} />
                                }
                                {selectedTab === 'files' &&
                                    <FileList contactId={props.contact.id} />
                                }
                                {selectedTab === 'logs' &&
                                    <LogList contactId={props.contact.id} />
                                }
                                {selectedTab === 'activities' &&
                                    <ActivityList contactId={props.contact.id} contact={props.contact} />
                                }

                                {
                                    selectedTab === 'products' &&
                                    <Products subUserList={props.subUserList} contact={props.contact} />
                                }
                                {
                                   selectedTab === 'policies' &&
                                   <Policies subUserList={props.subUserList} contact={props.contact}/>
                                }
                            </React.Fragment>
                        }

                    </div>

                </div>
                {props.showNoteModal && (
                    <NoteComponent
                        open={props.showNoteModal}
                        onClose={() => props.toggleNoteModal(false)}
                    />
                )}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        showNoteModal: state.contactReducer.showNoteModal,
        contact: state.contactReducer.contact,
        subUserList: state.contactReducer.subUserList,
        reloadConversationStatus: state.contactReducer.reloadConversation
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleNoteModal: (params) => dispatch(toggleNoteModal(params)),
        reloadNeedForConversation: (params) => dispatch(reloadConversationList(params))
    };
}

export default withTransaction('contact-details-middleside', 'component')(connect(mapStateToProps, mapDispatchToProps)(MiddleSide));