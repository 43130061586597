import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import BasicInput from "../custom/custom/BasicInput";
import { ModalHead, ModalTitle } from "../Products/ProductStyles";
import { Box, makeStyles, Typography } from "@material-ui/core";
import BasicSelect from "../custom/custom/BasicSelect";

const useStyle = makeStyles({
  formLabel: {
    fontWeight: "600 !important",
    color: "#181f48",
    marginBottom: "0px !important",
  },

  addDealButton: {
    color: " white !important",
    padding: "7px 12px 8px !important",
    fontWeight: "600 !important",
    borderRadius: "5px !important",
    textTransform: "capitalize !important",
    backgroundColor: " #316AFF !important",
  },
  cancelButton: {
    color: " #316AFF !important",
    textTransform:'capitalize !important',
    border:'1px solid #316AFF'
  },
});
const policies=[
  {id:1,
      label:'abc'
  },
  {id:2,
      label:'def'
  },
  {id:3,
      label:'dfg'
  },
  {id:4,
      label:'aert'
  }
]

const AddNewPolicyTypeModal = ({ open, onClose }) => {
  const [policyType, setPolicyType] = useState(null);
  const[selectedPolicyCategory,setSelectedPolicyCategory]=useState('')
  const handleSubmit = () => {
    const payload = {
      policyType,
    };
    console.log(payload);
  };
  const classes = useStyle();

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <ModalHead>
          <ModalTitle>Add a New Policy Type</ModalTitle>
        </ModalHead>
        <Box minWidth={600} p={"16px"}>
          <Box>
            <Box>
              <Typography className={classes.formLabel}>Policy Type</Typography>
              <BasicInput
                type='text'
                onChange={(e) => setPolicyType(e.target.value)}
                fullWidth
                placeholder='Enter Policy Type'
              />
            </Box>
            <Box mt={2}>
                <Typography className={classes.formLabel}>Policy Category</Typography>
                <BasicSelect
                  fullWidth
                  name='policy'
                  defaultText='Select an agent'
                  value={selectedPolicyCategory}
                  options={policies}
                  onChange={(e) => setSelectedPolicyCategory(e.target.value)}
                  mapping={{
                    label: "label",
                    value: "id",
                  }}
                />
              </Box>

            <Box display={"flex"} justifyContent={"end"} gridGap={16} mt={4}>
              <Button className={classes.cancelButton} variant="outlined" onClick={()=>onClose()}>Cancel</Button>
              <Button variant='contained' className={classes.addDealButton} onClick={handleSubmit}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddNewPolicyTypeModal;
