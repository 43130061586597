import React, { useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import { connect } from "react-redux";
import {
  addExtraEmailContact,
  reloadConversationList,
  setContactDetails,
  toggleNoteModal,
} from "../../../../../actions/contactAction";
import { getUserCampaignRegistrationStatusApi } from "../../../../../api/contactApi";
import Utils from "../../../../../helpers/Utils";
import dncNoCall from "../../../../common/dncNoCall";
import Helper from "../../../../globals/Helper";
import { IconList } from "../../../../globals/IconList";
import { ContactsStyles } from "../ContactsStyles";
import BootstrapTooltip from "../../../../globals/BootstrapTooltip";

const thanksIoActive =
  Utils.getAccountData("customMenu")["63"] &&
  Utils.getAccountData("customMenu")["63"] &&
  Utils.getAccountData("customMenu")["63"] !== null &&
  Utils.getAccountData("customMenu")["63"] !== ""
    ? true
    : false;

const zenDirectActive =
  Utils.getAccountData("customMenu")["61"] &&
  Utils.getAccountData("customMenu")["61"] &&
  Utils.getAccountData("customMenu")["61"] !== null &&
  Utils.getAccountData("customMenu")["61"] !== ""
    ? true
    : false;

const GIFT = 8;

const ContactActions = (props) => {
  const [mailOptionVisible, setMailOptionVisible] = useState(false);
  const [lastCommunicationMessage, setLastCommunicationMessage] = useState("");

  const { actionIconButton } = ContactsStyles();

  const getContactEmail = () => {
    let contact = props.contact;
    if (contact.email !== undefined && contact.email !== null) {
      return contact.email;
    }
    return "";
  };

  const callAll = () => {
    let allContacts = props.allContactList && props.allContactList.data ? props.allContactList.data : [];
    let totalContacts = props.allContactList && props.allContactList.total ? props.allContactList.total : 0;
    let currentIndex =
      props.assignedContactListInfo && props.assignedContactListInfo.currentIndex
        ? props.assignedContactListInfo.currentIndex
        : null;

    if (allContacts[1]) {
      let tmpParams = {
        from: "contactListPage",
        contactList: allContacts,
        totalContacts: totalContacts,
        currentIndex: currentIndex,
      };
      window.initiateGlobalDialer(props.contact, tmpParams);
    } else {
      window.initiateGlobalDialer(props.contact);
    }
  };

  const sendVoiceMail = () => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication(
        {
          open: true,
          onClose: () => console.log("RVM close"),
          id: props.contact.id,
          from: "contact-details",
          fetchContactDetails: false,
          sendSuccessCallBack: () => props.reloadNeedForConversation(true),
        },
        "voice"
      );
    }
  };

  const selectActiveMailType = (type) => {
    setMailOptionVisible(false);
    window.openGlobalDirectMailModal({
      messageType: type,
      contactId: props.contact.id,
      contactDetails: props.contact,
      callBack: submitDirectMail,
      from: "inbox",
    });
  };

  const handleNewPostcard = (type = "postcard") => {
    setMailOptionVisible(false);
    window.sendGlobalDirectMailV2({
      contactId: props.contact.id,
      from: "/contacts/" + props.contact.id,
      card_type: type,
      for: "direct-mail-send", //campaign-setting
    });
  };

  const showNotificationStart = (type, message) => {
    window.showNotification(type, message);
  };

  const submitDirectMail = () => {
    console.log("submit Direct Mail");
  };

  const sendSMSModal = () => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication(
        {
          open: true,
          onClose: () => console.log("sms close"),
          id: props.contact.id,
          from: "deal-details",
          fetchContactDetails: false,
          contactInfo: props.contact,
          sendSuccessCallBack: () => {
            setLastCommunicationMessage("A few seconds ago");
            props.reloadNeedForConversation(true);
          },
        },
        "sms"
      );
    }
  };

  const getUserCampaignRegistrationStatus = () => {
    try {
      getUserCampaignRegistrationStatusApi()
        .then((res) => {
          res = res.data;
          if (res.success) {
            if (res.campaignRegistrationStatus === "TRUE") {
              sendSMSModal();
            } else {
              window.globalSmsRestriction(true, {
                callback: (res) => {
                  if (res.sendWithRisk) {
                    sendSMSModal();
                  }
                },
              });
            }
          }
        })
        .catch((err) => {
          sendSMSModal();
          console.log(err);
        });
    } catch (err) {
      sendSMSModal();
      console.log(err);
    }
  };

  return (
    <div
      className='mt-3 ContactCustomClass'
      style={{
        border: "1px solid",
        borderRadius: "5px",
        padding: "5px",
        backgroundColor: "#ffff",
      }}
    >
      <div className='text-center'>
        <div className='user_profile_icons d-flex justify-content-center v3'>
          {props.contact.number && props.contact.number !== "" && (
            <div id='text_message_btn' className='single_icon'>
               <BootstrapTooltip title="Text Message" arrow placement="top">
                <span
                  className={`${actionIconButton} email_btn_icon`}
                  // onClick={(e) =>
                  // 	setIsOpenSMSModal(true)
                  // }
                  onClick={(e) => {
                    console.log("text button clicked");
                    e.preventDefault();

                    if (Utils.getAccountData("campaignRegistrationAvailable") === "TRUE") {
                      if (window.sendCommunication !== undefined) {
                        window.sendCommunication(
                          {
                            open: true,
                            onClose: () => console.log("sms close"),
                            id: props.contact.id,
                            from: "deal-details",
                            fetchContactDetails: false,
                            contactInfo: props.contact,
                            sendSuccessCallBack: () => {
                              setLastCommunicationMessage("A few seconds ago");
                              props.reloadNeedForConversation(true);
                            },
                          },
                          "sms"
                        );
                      }
                    } else {
                      getUserCampaignRegistrationStatus();
                    }
                  }}
                >
                  {IconList.massageIcon}
                </span>
              </BootstrapTooltip>
              {/* <p className="text-gray m-0">Text</p> */}
            </div>
          )}
          {props.contact.email && props.contact.email !== "" && (
            <div id='email_btn' className='single_icon'>
              <BootstrapTooltip title="Email" arrow placement="top">
                <span
                  className={`${actionIconButton} email_btn_icon`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.sendCommunication !== undefined) {
                      window.sendCommunication(
                        {
                          open: true,
                          onClose: () => console.log("Email Close"),
                          id: props.contact.id,
                          from: "contact-details",
                          primaryEmail: getContactEmail(),
                          fetchContactDetails: false,
                          sendSuccessCallBack: () => {
                            setLastCommunicationMessage("A few seconds ago");
                            props.reloadNeedForConversation(true);
                          },
                        },
                        "email"
                      );
                    }
                  }}
                >
                  {IconList.emailIconV2}
                </span>
              </BootstrapTooltip>
              {/* <p className='text-gray m-0'>Email</p> */}
            </div>
          )}

          {props.contact.number && props.contact.number !== "" && (
            <div
              className='single_icon'
              onClick={() => {
                // eslint-disable-next-line no-lone-blocks
                {
                  Utils.getAccountData("dnc") && props.contact && props.contact.number
                    ? props.contact.contact_additional_informations &&
                      props.contact.contact_additional_informations.dnc_status === "DO_NOT_CALL"
                      ? dncNoCall({
                          takeRisk: () => {
                            callAll(props.contact);
                          },
                          cancelButtonText: "Don't Call",
                        })
                      : callAll(props.contact)
                    : callAll(props.contact);
                }
              }}
            >
              <i className='material-icons waves-effect m-2 text-gray modal-trigger d-none'>phone_in_talk</i>
              <BootstrapTooltip title="Call" arrow placement="top">
                <span className={`${actionIconButton} email_btn_icon phone-icon`}>{IconList.callIconv2}</span>
              </BootstrapTooltip>
              {/* <p className='text-gray m-0'>Call</p> */}
            </div>
          )}

          {props.contact.number && props.contact.number !== "" && (
            <BootstrapTooltip title="Ringless Voicemail" arrow placement="top">
              <div className='single_icon'>
                <i
                  data-target='voice_mail_modal'
                  className='modal-trigger material-icons waves-effect m-2 text-gray d-none'
                >
                  mic
                </i>
                <span
                  className={`${actionIconButton} email_btn_icon micBtn`}
                  onClick={(e) => {
                    e.preventDefault();
                    // eslint-disable-next-line no-lone-blocks
                    {
                      Utils.getAccountData("carrierLookup") && props.contact && props.contact.number
                        ? props.contact.contact_additional_informations &&
                          props.contact.contact_additional_informations.dnc_status === "DO_NOT_CALL"
                          ? dncNoCall({
                              takeRisk: () => {
                                sendVoiceMail();
                              },
                              cancelButtonText: "Don't Send",
                            })
                          : sendVoiceMail()
                        : sendVoiceMail();
                    }
                  }}
                >
                  {IconList.micIcon}
                </span>
                {/* <p className='text-gray m-0'>Voice</p> */}
              </div>
            </BootstrapTooltip>
          )}

          {props.contact.email && props.contact.email !== "" && (
            <BootstrapTooltip title="Video Email" arrow placement="top">
              <div
                className='single_icon'
                // onClick={() => setOpenVideoMailModal(true)}>
                onClick={() =>
                  window.sendCommunication(
                    {
                      open: true,
                      contactId: props.contact.id,
                      contactDetails: props.contact,
                      from: "contact-details",
                      responseCallback: () => props.reloadNeedForConversation(true),
                    },
                    "videoEmail"
                  )
                }
              >
                <VideoCallIcon className='modal-trigger material-icons waves-effect m-2 text-gray d-none' />
                <span className={`${actionIconButton} email_btn_icon micBtn videoIcon`}>
                  {IconList.videoIconWithoutStroke}
                </span>
                {/* <p className='text-gray m-0'>Video</p> */}
              </div>
            </BootstrapTooltip>
          )}

          {(thanksIoActive || zenDirectActive) &&
            !Helper.isEmpty(props.contact.address) &&
            !Helper.isEmpty(props.contact.state) && (
              <BootstrapTooltip title="Direct Mail" arrow placement="top">
                <div className='single_icon' onClick={(e) => setMailOptionVisible(e.currentTarget)}>
                  <span className={`${actionIconButton} email_btn_icon micBtn videoIcon`}>
                    {IconList.directMailiconInContactDetailsWithoutStroke}
                  </span>
                  {/* <p className='text-gray m-0'>DMail</p> */}
                </div>
              </BootstrapTooltip>
            )}
          <Menu
            id='more-menu'
            anchorEl={mailOptionVisible}
            keepMounted
            open={Boolean(mailOptionVisible)}
            onClose={() => setMailOptionVisible(false)}
          >
            {Utils.getAccountData("userId") === 1 && (
              <MenuItem
                onClick={() => selectActiveMailType(GIFT)}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 16,
                  paddingRight: 16,
                  justifyContent: "unset",
                }}
              >
                <span>{IconList.giftIcon}</span>
                &nbsp; Old Gift
              </MenuItem>
            )}

            {thanksIoActive && (
              <>
                <MenuItem
                  onClick={() => handleNewPostcard("postcard")}
                  style={{
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 16,
                    paddingRight: 16,
                    justifyContent: "unset",
                  }}
                >
                  <span>{IconList.giftIcon}</span>&nbsp; Postcard
                </MenuItem>
                <MenuItem
                  onClick={() => handleNewPostcard("letter")}
                  style={{
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 16,
                    paddingRight: 16,
                    justifyContent: "unset",
                  }}
                >
                  <span>{IconList.giftIcon}</span>&nbsp; Letter
                </MenuItem>
                <MenuItem
                  onClick={() => handleNewPostcard("notecard")}
                  style={{
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 16,
                    paddingRight: 16,
                    justifyContent: "unset",
                  }}
                >
                  <span>{IconList.giftIcon}</span>&nbsp; Notecard
                </MenuItem>
              </>
            )}
            {zenDirectActive && (
              <MenuItem
                onClick={() => handleNewPostcard("zendirect_gift")}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 16,
                  paddingRight: 16,
                  justifyContent: "unset",
                }}
              >
                <span>{IconList.giftIcon}</span>&nbsp; Gift
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
    showNoteModal: state.contactReducer.showNoteModal,
    assignedContactListInfo: state.contactReducer.assignedContactListInfo,
    allContactList: state.contactReducer.allContactList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNoteModal: (params) => dispatch(toggleNoteModal(params)),
    reloadNeedForConversation: (params) => dispatch(reloadConversationList(params)),
    updateStoredContactDetails: (params) => dispatch(setContactDetails(params)),
    addExtraEmailContact: (params) => dispatch(addExtraEmailContact(params)),
    setContactDetails: (params) => dispatch(setContactDetails(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactActions);
