import {
    InputBase,
    styled,
    withStyles,
    Button,
    Typography, Box
} from "@material-ui/core";

export const TabWrapper = styled("div")({
    padding: ".75rem",
    display: "flex",
    flexDirection: "column",
    height: "100vh"
});

export const TabHeader = styled("div")({
    display: "flex",
    justifyContent: "end",
});

export const BasicButton = withStyles((theme) => ({
    root: {
        fontWeight: "600 !important",
        backgroundColor: "#316AFF !important",
        color: "white !important",
        textTransform: "capitalize !important",
        padding: "7px 12px 8px  !important",
        borderRadius: "5px !important",
        "&:hover, &:focus": {
            backgroundColor: "#316AFF !important",
            color: "white !important",
        },
        "&:disabled": {
            backgroundColor: "#b3ceff !important"
        }
    }
}))(Button);

export const BasicTrButton = withStyles((theme) => ({
    root: {
        fontWeight: "600",
        backgroundColor: "#FFF !important",
        color: "#3C7EF3 !important",
        textTransform: "capitalize !important",
        padding: "10px 20px",
        borderRadius: "5px",
        borderColor: "#808080 !important",
        "&:hover, &:focus": {
            backgroundColor: "#FFF !important",
            borderColor: "#808080 !important",
            color: "#3C7EF3 !important",
        },
    }
}))(Button);

export const BasicInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "&.MuiInputBase-multiline": {
            padding: "0px !important",
        },
        "& .pricePadding": {
            paddingLeft: "24px !important"
        }
    },
    input: {
        position: "relative",
        backgroundColor: "white !important",
        fontSize: "14px !important",
        margin: "0 !important",
        height: "2.5rem !important",
        padding: "10px 12px 10px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: "4px",
            boxShadow: "unset !important",
        },

        "&.MuiSelect-select": {
            height: "1rem !important",
        },
    },
}))(InputBase);

export const ErrorMessage = withStyles((theme) => ({
    root: {
        fontSize: "11px",
        color: "red",
        marginLeft: "4px",
        marginTop: "2px",

        "& .restoreText": {
            color: "#0b0be1",
            textDecoration: "underline",
            fontWeight: 600,
            cursor: "pointer",
            userSelect: "none"
        }
    },
}))(Typography);

export const ContactProducts = styled("div")({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(340px, 1fr))",
    gap: "16px",
    width: "100%",
    maxWidth: "1200px",
    padding: "10px"
});

export const ModalBodyWrapper = styled("div")({
    minWidth: "600",
    minHeight: "300"
});

export const ModalHead = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    paddingTop: "8px",
    paddingBottom: "0px"
});

export const ModalBody = styled("div")({
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "16px",
    paddingRight: "16px"
});

export const ModalTitle = styled("p")({
   color: "#316aff",
   fontSize: "1rem",
   fontWeight: 400,
   lineHeight: 1.5,
   letterSpacing: "0.00938em"
});

export const ModalContactChip = styled("div")({
    backgroundColor: "#181f48",
    padding: "0px 10px",
    color: "#ddd",
    textAlign: "center",
    borderRadius: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "160px"
});

export const ModalFormGroup = styled("div")({

});

export const ModalFormLabel = styled("p")({
    color: "#0e1b3d",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    margin: "0px"
});

export const ModalFormInput = styled("div")({
    marginTop: "8px"
});

export const ModalFoot = styled("div")({
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    padding: "16px"
});

export const ModalFormGroupSectionHead = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "24px"
});

export const ModalFormGroupWrapper = styled("div")({
    display: "flex",
    marginTop: "16px",
    gridGap: "16px"
});

export const ContentProductCardWrapper = styled("div")({
    maxWidth: 360,
    height: 412,
    borderRadius: 8,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "relative"
});

export const ContentProductCardHead = styled("div")({
    backgroundColor: "#316AFF",
    color: "white",
    padding:  '12px 20px',
    borderRadius: "8px 8px 0 0",
    display: "flex",
    flexDirection: "column",
    height: "130px"
});

export const ContentProductCardTitle = styled("h5")({
    color: "#fff !important",
    fontSize: "20px",
    fontWeight: "600"
});

export const ContentProductCardTeamMember = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width:'100%'
});

export const ContentProductCardPlainGroup = styled("div")({
   width:'70%'
});

export const ContentProductCardBody = styled("div")({
    padding: "16px",
    "& .MuiTypography-root": {
        height: "unset !important"
    }
});

export const ContentProductCardFooter = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    backgroundColor: "#f9f9f9",
    borderRadius: "0 0 8px 8px",
    height: "50px",
    width: "100%",
    position: "absolute",
    bottom: "0px",
    "& .MuiTypography-body1": {
        fontSize: "14px !important"
    }
});

export const ContactProductsEmptyWrapper = styled("div")({
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});