import React from "react";
import { Collapse, IconButton, Link, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { IconList } from "../../../globals/IconList";
import { LeftSideV2Styles } from "../LeftSideV2Styles";
import RelatedContactActionPopover from "./RelatedContactActionPopover";
import { reloadConversationList } from "../../../../actions/contactAction";
import { getUserCampaignRegistrationStatusApi } from "../../../../api/contactApi";
import Utils from "../../../../helpers/Utils";

const generateName = (contact) => {
  let name = "";

  if (contact.firstName) {
    name += contact.firstName;
  }

  if (contact.lastName) {
    if (name) {
      name += " ";
    }
    name += contact.lastName;
  }

  return name;
};

const EachRelatedItem = (props) => {
  const { item, contact, setRelatedContacts } = props;

  const [actionPopover, setActionPopover] = React.useState(null);
  const [checked, setChecked] = React.useState(false);

  const handleActionPopover = (event) => {
    event.stopPropagation();
    setActionPopover(actionPopover ? null : event.currentTarget);
  };

  const handleCloseActionPopover = () => {
    setActionPopover(null);
  };

  let relatedContactInfo = {
    "id": item.relatedContactId,
    "first_name": item.firstName,
    "last_name": item.lastName,
    "email": item.email,
    "number": item.number,
    "address": item.address,
    "city": item.city,
    "state": item.state,
  }

  const openActionPopover = Boolean(actionPopover);
  const openActionId = openActionPopover ? "simple-action-popper" : undefined;

  const { linkUnderLine, textDarkBlue, heighFit } = LeftSideV2Styles();

  const sendSMSModal = () => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication(
        {
          open: true,
          onClose: () => console.log("sms close"),
          id: item.relatedContactId,
          relationSuperContactId: contact.id,
          from: "contact-details",
          fetchContactDetails: false,
          contactInfo: relatedContactInfo,
          sendSuccessCallBack: () => {
            props.reloadNeedForConversation(true);
          },
        },
        "sms"
      );
    }
  };

  const getUserCampaignRegistrationStatus = () => {
    try {
      getUserCampaignRegistrationStatusApi()
        .then((res) => {
          res = res.data;
          if (res.success) {
            if (res.campaignRegistrationStatus === "TRUE") {
              sendSMSModal();
            } else {
              window.globalSmsRestriction(true, {
                callback: (res) => {
                  if (res.sendWithRisk) {
                    sendSMSModal();
                  }
                },
              });
            }
          }
        })
        .catch((err) => {
          sendSMSModal();
          console.log(err);
        });
    } catch (err) {
      sendSMSModal();
      console.log(err);
    }
  };

  const getContactEmail = () => {
    if (item.email) {
      return item.email;
    }
    return "";
  };

  const sendEmailModal = () => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication(
        {
          open: true,
          onClose: () => console.log("Email Close"),
          id: item.relatedContactId,
          relationSuperContactId: contact.id,
          from: "contact-details",
          primaryEmail: getContactEmail(),
          fetchContactDetails: false,
          sendSuccessCallBack: () => {
            props.reloadNeedForConversation(true);
          },
        },
        "email"
      );
    }
  };

  const sendVoiceMail = () => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication(
        {
          open: true,
          onClose: () => console.log("RVM close"),
          id: item.relatedContactId,
          relationSuperContactId: contact.id,
          from: "contact-details",
          fetchContactDetails: false,
          sendSuccessCallBack: () => {
            props.reloadNeedForConversation(true);
          },
        },
        "voice"
      );
    }
  };

  const sendVideoEmail = () => {
    if (window.sendCommunication !== undefined) {
      window.sendCommunication(
        {
          open: true,
          contactId: item.relatedContactId,
          relationSuperContactId: contact.id,
          contactDetails: relatedContactInfo,
          from: "contact-details",
          responseCallback: () => {
            props.reloadNeedForConversation(true);
          },
        },
        "videoEmail"
      )
    }
  };

  const callToNumber = () => {
    if (window.initiateGlobalDialer) {

      window.initiateGlobalDialer(relatedContactInfo);
    }
  };

  return (
    <div className='item' onMouseEnter={() => setChecked(true)} onMouseLeave={() => setChecked(false)}>
      <div className='itemContent'>
        <div className='itemText'>
          <Link href={`/contacts/${item.relatedContactId}`} variant='overline' color='primary' className={linkUnderLine}>
            {generateName(item)}
          </Link>

          <Typography variant='body1' className={`${textDarkBlue} ${heighFit}`}>
            {item.relationship}
          </Typography>
        </div>

        <div>
          <IconButton
            size='small'
            className='iconButton'
            onClick={(event) => {
              handleActionPopover(event);
              setChecked(false);
            }}
          >
            <MoreHorizIcon fill='currentColor' />
          </IconButton>
        </div>
      </div>

      <Collapse in={checked}>
        <div className='actionListHover'>
          {
            item.number &&
            <BootstrapTooltip title='Text Message' arrow placement='top'>
              <IconButton size='small' className='actionItem' onClick={() => {
                if (Utils.getAccountData("campaignRegistrationAvailable") === "TRUE") {
                  sendSMSModal();
                } else {
                  getUserCampaignRegistrationStatus();
                }
              }}>
                {IconList.massageIcon}
              </IconButton>
            </BootstrapTooltip>
          }
          {
            item.email &&
            <BootstrapTooltip title='Email' arrow placement='top'>
              <IconButton size='small' className='actionItem' onClick={() => {
                sendEmailModal();
              }}>
                {IconList.emailIconV2}
              </IconButton>
            </BootstrapTooltip>
          }
          {
            item.number &&
            <BootstrapTooltip title='Call' arrow placement='top'>
              <IconButton size='small' className='actionItem' onClick={() => {
                callToNumber();
              }}>
                {IconList.callIconv2}
              </IconButton>
            </BootstrapTooltip>
          }
          {
            item.number &&
            <BootstrapTooltip title='Ringless Voicemail' arrow placement='top'>
              <IconButton size='small' className='actionItem' onClick={() => {
                sendVoiceMail();
              }}>
                {IconList.micIcon}
              </IconButton>
            </BootstrapTooltip>
          }
          {
            item.email &&
            <BootstrapTooltip title='Video Email' arrow placement='top' onClick={() => {
              sendVideoEmail();
            }}>
              <IconButton size='small' className='actionItem'>
                {IconList.videoIconWithoutStroke}
              </IconButton>
            </BootstrapTooltip>
          }
          {/*<BootstrapTooltip title='Direct Mail' arrow placement='top'>*/}
          {/*  <IconButton size='small' className='actionItem'>*/}
          {/*    {IconList.directMailiconInContactDetailsWithoutStroke}*/}
          {/*  </IconButton>*/}
          {/*</BootstrapTooltip>*/}
        </div>
      </Collapse>

      <RelatedContactActionPopover
        contact={item}
        id={openActionId}
        open={openActionPopover}
        popover={actionPopover}
        onClose={handleCloseActionPopover}
        setRelatedContacts={setRelatedContacts}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reloadNeedForConversation: (params) => dispatch(reloadConversationList(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EachRelatedItem);
