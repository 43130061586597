import { Grid, makeStyles, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  InsuraceCardBody,
  InsuraceCardFooter,
  InsuraceFlexBox,
  InsuraceStatusForActive,
  InsuraceStatusForInActive,
  InsuraceTitle,
  InsuranceCard,
  InsuranceCardWrapper,
  InsuranceHeader,
  InsuranceHeaderFlexBox,
  InsuranceIconContainer,
} from "./PolicyStyle";
import EditPolicyPopOver from "./EditPolicyPopOver";
import { STATUS } from "../../../dataClean/Constants";

const useStyle = makeStyles({
  textStyle: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textWrap: "wrap",
    width: "90%",
    fontWeight: 600,
  },
});

const EachInsurance = ({ item, dataObject, fetchContactPolicyData, setOpenPolicyModal, setPolicyId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const openEditPolicyPopOver = Boolean(anchorEl);
  const id = openEditPolicyPopOver ? "simple-popover" : undefined;
const classes=useStyle()
  return (
    <InsuranceCard>
      <InsuranceCardWrapper>
        <InsuranceHeader>
          <InsuraceFlexBox>
            <InsuraceTitle>{dataObject?.policyType[item?.policy_id]}</InsuraceTitle>
            <InsuranceHeaderFlexBox>
              {item.status === STATUS.ACTIVE ? (
                <InsuraceStatusForActive>ACTIVE</InsuraceStatusForActive>
              ) : (
                <InsuraceStatusForInActive>INACTIVE</InsuraceStatusForInActive>
              )}
              <InsuranceIconContainer aria-describedby={id} onClick={handleOpenPopOver}>
                <MoreVertIcon />
              </InsuranceIconContainer>
            </InsuranceHeaderFlexBox>
          </InsuraceFlexBox>
          <InsuraceFlexBox>
            <Typography variant='body1' style={{ fontWeight: 300 }}>
              Policy &nbsp;: &nbsp;{item.unique_key}
            </Typography>
            <Typography variant='body1'>Full Premium&nbsp;:&nbsp; ${item.price}</Typography>
          </InsuraceFlexBox>
        </InsuranceHeader>
        <InsuraceCardBody>
          <Grid container spacing={1}>
            <Grid item lg={6} xl={3}>
              <Typography style={{ color: "#929da9", height: "24px" }}>EFFECTIVE DATE</Typography>
              <Typography style={{ fontWeight: 600 }}>{item.effective_date}</Typography>
            </Grid>
            <Grid item lg={6} xl={3}>
              <Typography style={{ color: "#929da9", height: "24px" }}>EXPIRATION DATE</Typography>
              <Typography style={{ fontWeight: 600 }}>{item.expiration_date}</Typography>
            </Grid>
            <Grid item lg={12} xl={6}>
              <Typography style={{ color: "#929da9", height: "24px" }}>INSURANCE COMPANY</Typography>
              <Typography className={classes.textStyle}>{dataObject?.company[item?.insurance_company_id]}</Typography>
            </Grid>
            {/* <Grid item xs={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <a href={''}>
                <LinkIcon style={{ rotate: "135deg", fontSize: "35px" }} />
              </a>
            </Grid> */}
          </Grid>
        </InsuraceCardBody>
        <InsuraceCardFooter>
          <InsuraceFlexBox>
            <Typography style={{ fontWeight: 600, color: "#929da9" }}>AGENT</Typography>
            <Typography style={{ fontWeight: 600 }}>{dataObject?.agent[item?.agent_id]}</Typography>
          </InsuraceFlexBox>
        </InsuraceCardFooter>
      </InsuranceCardWrapper>

      {openEditPolicyPopOver && (
        <EditPolicyPopOver
          open={openEditPolicyPopOver}
          id={id}
          setPolicyId={setPolicyId}
          setOpenPolicyModal={setOpenPolicyModal}
          fetchContactPolicyData={fetchContactPolicyData}
          item={item}
          handleClose={handleClosePopOver}
          anchorEl={anchorEl}
        />
      )}
    </InsuranceCard>
  );
};

export default EachInsurance;
