import React, {
    useEffect,
    useState
} from "react";
import {
    Checkbox,
    Dialog,
    Divider,
    FormControlLabel,
    Typography,
} from "@material-ui/core";
import {
    BasicButton,
    BasicInput,
    BasicTrButton,
    ErrorMessage,
    ModalBody,
    ModalBodyWrapper,
    ModalContactChip,
    ModalFoot,
    ModalFormGroup,
    ModalFormGroupSectionHead,
    ModalFormGroupWrapper,
    ModalFormInput,
    ModalFormLabel,
    ModalHead,
    ModalTitle
} from "./ProductStyles";
import { changeDealStatus, userProductStatus } from "../../../../api/dealApi";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { CONTACT_PRODUCTS, CONTACT_STAGE, RESPONSE_STATUS } from "../../../../constants/CoreConstants";
import { AttachMoney } from "@material-ui/icons";
import BasicSelect from "../../../common/BasicSelect";
import AddProductModal from "./AddProductModal";
import AddIcon from "@material-ui/icons/Add";

const AddProductFromDropdown = ({ contact, open, onClose, appendNewContactProduct, productList,setCreateProduct }) => {
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        unit_cost: "",
        unit_price: "",
        billing_frequency: "",
        close_date: ""
    });
    const [formDataError, setFormDataError] = useState({
        title: "",
        description: "",
        unit_cost: "",
        unit_price: "",
        billing_frequency: "",
        close_date: ""
    });
    const [submitting, setSubmitting] = useState(false);
    const [contactLabel, setContactLabel] = useState("");
    const [duplicateTrashProduct, setDuplicateTrashProduct] = useState(null);
    const [createDealAfterAddProduct, setCreateDealAfterAddProduct] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedProductDetails, setSelectedProductDetails] = useState(null);
    const [createModal, setCreateModal] = useState(false);
    useEffect(() => {
        if (contact) {
            let label = "";

            if (contact.first_name) {
                label += contact.first_name;
            }

            if (contact.last_name) {
                if (label) {
                    label += " ";
                }
                label += contact.last_name;
            }

            if (!label) {
                label = contact.email;
            }

            if (!label) {
                label = contact.number;
            }

            setContactLabel(label);
        }
    }, [contact]);

    const handleOnClose = () => {
        onClose();
        resetFormData();
    };

    const resetFormData = () => {
        setFormData({
            title: "",
            description: "",
            unit_cost: "",
            unit_price: "",
            billing_frequency: "",
            close_date: ""
        });
        setFormDataError({
            title: "",
            description: "",
            unit_cost: "",
            unit_price: "",
            billing_frequency: "",
            close_date: ""
        });
    };

    const validateFormData = () => {
        let inValid = false;
        const errors = {
            title: "",
            description: "",
            unit_cost: "",
            unit_price: "",
            billing_frequency: "",
            close_date: ""
        }

        if (formData.title.trim() === "") {
            inValid = true;

            errors.title = "Product title is required";
        }

        if (formData.unit_cost.trim() === "") {
            inValid = true;

            errors.unit_cost = "Product unit cost is required";
        }

        if (formData.unit_price.trim() === "") {
            inValid = true;

            errors.unit_price = "Product unit price is required";
        }

        if (inValid) {
            setFormDataError(errors);
        }

        return inValid;
    };

    const handleSubmitFormData = async () => {
        try {
            if (validateFormData()) {
                return;
            }

            setSubmitting(true);

            if (createDealAfterAddProduct) {
                const dealResponse = await window.globalAddDeal(true, {
                    callback: async (data) => {     
                        if (data && data?.contact_stage_id) {
                            const dealStatusResponse = await changeDealStatus({
                                stage_id: data?.contact_stage_id,
                                status: CONTACT_STAGE.WIN,
                            });

                            let contactStage = data.contact_stage;
                            let contactStageProducts = data.contact_stage_products;

                            if (dealStatusResponse && dealStatusResponse.data?.status === RESPONSE_STATUS.SUCCESS) {
                                contactStageProducts.forEach((contactStageProduct) => {
                                    appendNewContactProduct({
                                        userProductTitle: contactStageProduct.title,
                                        userProductUnitCost: parseFloat(contactStageProduct.unit_cost),
                                        userProductUnitPrice: parseFloat(contactStageProduct.unit_price),
                                        dealTitle: contactStage.title,
                                        dealCreatedById: contactStage.created_by,
                                        dealClosedById: contactStage.created_by,
                                        dealProductValue: parseFloat(contactStageProduct.unit_price),
                                        dealCreatedDate: contactStage.created_at,
                                        dealClosedDate: contactStage.created_at,
                                        pipelineTitle: data?.pipelineTitle,
                                        stageTitle: data?.stageTitle,
                                    });
                                });
                                setCreateDealAfterAddProduct(false);
                            }
                        }
                    },
                    data: {
                        contact_id: contact.id,
                        user_contact_stage_products: [
                            {
                                user_product_id: selectedProductDetails.id,
                                value: selectedProductDetails.unit_price,
                                status: selectedProductDetails.status,
                            },
                        ],
                    },
                    edit: false,
                });
            }

            handleOnClose();
        } catch (err) {
            console.log(err)
        } finally {
            setSubmitting(false);
        }
    };


    const handleRestoreProduct = async () => {
        try {
            const response = await userProductStatus({ id: duplicateTrashProduct.id, status: CONTACT_PRODUCTS.PRODUCT_STATUS.ACTIVE });

            if (response && response.success) {
                window.globalAddDeal(true, {
                    callback: async (data) => {
                        if (data && data?.contact_stage_id) {
                            const response = await changeDealStatus({ stage_id: data?.contact_stage_id, status: CONTACT_STAGE.WIN });

                            let contactStage = data.contact_stage;
                            let contactStageProducts = data.contact_stage_products;

                            if (response && response.data?.status === RESPONSE_STATUS.SUCCESS) {
                                contactStageProducts.forEach((contactStageProduct) => {
                                    appendNewContactProduct({
                                        userProductTitle: contactStageProduct.title,
                                        userProductUnitCost: parseFloat(contactStageProduct.unit_cost),
                                        userProductUnitPrice: parseFloat(contactStageProduct.unit_price),
                                        dealTitle: contactStage.title,
                                        dealCreatedById: contactStage.created_by,
                                        dealClosedById: contactStage.created_by,
                                        dealProductValue: parseFloat(contactStageProduct.unit_price),
                                        dealCreatedDate: contactStage.created_at,
                                        dealClosedDate: contactStage.created_at,
                                        pipelineTitle: data?.pipelineTitle,
                                        stageTitle: data?.stageTitle,
                                    });
                                });
                            }
                        }
                    },
                    data: {
                        contact_id: contact.id,
                        user_contact_stage_products: [
                            {
                                user_product_id: duplicateTrashProduct.id,
                                value: formData.unit_price,
                                status: duplicateTrashProduct.status
                            }
                        ]
                    },
                    edit: false,
                });
                handleOnClose();
            }
        } catch (err) {

        }
    }

    const productOptions = productList.map((user) => ({ value: user.id, label: user.title }));

    useEffect(() => {
        if (selectedProduct) {
            const matchProduct = productList.find(product => product.id === selectedProduct);
            if (matchProduct) {
                setSelectedProductDetails(matchProduct);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    title: matchProduct.title ? matchProduct.title : '',
                    description: matchProduct.description ? matchProduct.description : '',
                    unit_cost: matchProduct.unit_cost ? matchProduct.unit_cost : '',
                    unit_price: matchProduct.unit_price ? matchProduct.unit_price : '',
                    billing_frequency: matchProduct.billing_frequency ? matchProduct.billing_frequency : '',
                }));
            }
        }
    }, [selectedProduct, productList]);




    const handleCreateProductModal = () => {
        setCreateModal(true);
    };


    const handleCreateModalClose = () => {
        setCreateModal(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleOnClose}>
            <ModalBodyWrapper>
                <ModalHead>
                    <ModalTitle>
                        Add a New Product Or Service
                    </ModalTitle>
                    <Divider style={{ width: "165px", backgroundColor: "#316aff" }} />
                    {
                        contactLabel &&
                        <ModalContactChip>
                            <BootstrapTooltip placement={"top"} arrow title={contactLabel}>
                                <span>{contactLabel}</span>
                            </BootstrapTooltip>
                        </ModalContactChip>
                    }
                </ModalHead>

                <ModalBody>
                    <ModalFormGroup>
                        <ModalFormLabel>
                            Name of Product / Service
                        </ModalFormLabel>
                        <div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ModalFormInput style={{ width: '75%' }}>
                                <BasicSelect
                                    value={selectedProduct}
                                    defaultText="Select Product"
                                    options={productOptions}
                                    onChange={(e) => { setSelectedProduct(e.target.value); }}

                                />
                                {
                                    formDataError.title &&
                                    <ErrorMessage>{formDataError.title} {duplicateTrashProduct ? <span className="restoreText" onClick={handleRestoreProduct}>Restore</span> : null}</ErrorMessage>
                                }
                            </ModalFormInput>
                            <BasicButton
                                startIcon={<AddIcon />}
                                onClick={handleCreateProductModal}
                            >
                                Add New
                            </BasicButton>
                        </div>
                    </ModalFormGroup>
                    {
                        createModal && (
                            <AddProductModal
                                contact={contact}
                                appendNewContactProduct={appendNewContactProduct}
                                open={createModal}
                                onClose={handleCreateModalClose}
                                setCreateProduct={setCreateProduct}
                            />
                        )
                    }

                    <ModalFormGroupSectionHead>
                        <ModalFormLabel style={{ color: "#ced4da" }}>
                            Product / Service Information
                        </ModalFormLabel>
                        <Divider style={{
                            width: "330px",
                            backgroundColor: "#316aff",
                        }}
                        />
                    </ModalFormGroupSectionHead>

                    <ModalFormGroup style={{ marginTop: "16px" }}>
                        <ModalFormLabel style={{ color: '#ced4da' }}>
                            Description
                        </ModalFormLabel>
                        <ModalFormInput>
                            <BasicInput
                                style={{ color: '#ced4da !important' }}
                                fullWidth
                                multiline
                                name={"description"}
                                value={formData.description}
                                required
                                disabled
                            />

                            {
                                formDataError.description &&
                                <ErrorMessage>{formDataError.description}</ErrorMessage>
                            }
                        </ModalFormInput>
                    </ModalFormGroup>

                    <ModalFormGroupWrapper>
                        <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel style={{ color: '#ced4da' }}>
                                Unit Cost
                            </ModalFormLabel>
                            <ModalFormInput style={{ position: 'relative' }}>
                                <BasicInput
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 999999, className: "pricePadding" }}
                                    name={"unit_cost"}
                                    value={formData.unit_cost}
                                    disabled
                                />
                                <AttachMoney fontSize="small" style={{ position: 'absolute', left: '6px', top: '8px', color: '#ced4da' }} />
                                {
                                    formDataError.unit_cost &&
                                    <ErrorMessage>{formDataError.unit_cost}</ErrorMessage>
                                }
                            </ModalFormInput>
                        </ModalFormGroup>
                        <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel style={{ color: '#ced4da' }}>
                                Unit Price
                            </ModalFormLabel>
                            <ModalFormInput style={{ position: 'relative' }}>
                                <BasicInput
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 999999, className: "pricePadding", }}
                                    name={"unit_price"}
                                    value={formData.unit_price}
                                    disabled
                                />
                                <AttachMoney fontSize="small" style={{ position: 'absolute', left: '6px', top: '8px', color: '#ced4da' }} />
                                {
                                    formDataError.unit_price &&
                                    <ErrorMessage>{formDataError.unit_price}</ErrorMessage>
                                }
                            </ModalFormInput>
                        </ModalFormGroup>
                    </ModalFormGroupWrapper>

                    <ModalFormGroupWrapper>
                        <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel style={{ color: '#ced4da' }}>
                                Billing Frequency
                            </ModalFormLabel>
                            <ModalFormInput>
                                <BasicInput
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 999999 }}
                                    name={"billing_frequency"}
                                    value={formData.billing_frequency}
                                    // onChange={handleChangeFormData}
                                    disabled
                                />
                                {
                                    formDataError.billing_frequency &&
                                    <ErrorMessage>{formDataError.billing_frequency}</ErrorMessage>
                                }
                            </ModalFormInput>
                        </ModalFormGroup>
                        {/* <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel style={{ color: '#ced4da' }}>
                                Close Date
                            </ModalFormLabel>
                            <ModalFormInput>
                                <BasicInput
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    name={"close_date"}
                                    value={formData.close_date}
                                    onChange={handleChangeFormData}
                                    disabled
                                />
                                {
                                    formDataError.close_date &&
                                    <ErrorMessage>{formDataError.close_date}</ErrorMessage>
                                }
                            </ModalFormInput>
                        </ModalFormGroup> */}
                    </ModalFormGroupWrapper>
                    <ModalFormGroup style={{ marginTop: '24px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                color="black"
                                    size={'small'}
                                    checked={createDealAfterAddProduct}
                                />
                            }
                            style={{ color: '#0e1b3d', fontSize: '16px !important', fontWeight: '600' }}
                            label='Include this manual entry in sales reporting and goal calculations'
                        />
                        <Typography style={{ fontSize: '12px', paddingLeft: '28px', color: '#0e1b3d', fontWeight: '400' }}>
                        For accurate reporting you must associate this entry with a deal
                        </Typography>
                    </ModalFormGroup>
                </ModalBody>

                <ModalFoot>
                    <BasicTrButton style={{ marginRight: "10px" }} onClick={handleOnClose}>
                        Cancel
                    </BasicTrButton>
                    <BasicButton disabled={submitting} onClick={handleSubmitFormData}>
                        Add Deal
                    </BasicButton>
                </ModalFoot>
            </ModalBodyWrapper>
        </Dialog>
    );
};

export default AddProductFromDropdown;