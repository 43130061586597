import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { LeftSideV2Styles } from "../../LeftSideV2Styles";
import { connect } from "react-redux";

const Conversation = (props) => {
  const { textDarkBlue, alignJustifyCenter, textFontSize } = LeftSideV2Styles();
  const [contact, setContact] = useState(props.contact);
  const [lastCommunicationMessage, setLastCommunicationMessage] = useState("");

  useEffect(() => {
    setContact(props.contact);
  }, [props.contact]);

  useEffect(() => {
    getLastCommunicationinfo();
  }, [props.contact]);

  const getLastCommunicationinfo = () => {
    setLastCommunicationMessage(
      props.contact?.contact_activity?.local_last_communication?.for_human
        ? props.contact.contact_activity.local_last_communication.for_human
        : "No conversation yet!"
    );
  };

  if (!contact) {
    return null;
  }

  return (
    <Box className={alignJustifyCenter} sx={{ color: "white" }}>
      {lastCommunicationMessage !== "No conversation yet!" ? (
        <Typography variant='body2' className={textFontSize}>
          Last contact: {lastCommunicationMessage}
        </Typography>
      ) : (
        <Typography variant='body2' className={textFontSize}>
          {lastCommunicationMessage}
        </Typography>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};

export default connect(mapStateToProps)(Conversation);
