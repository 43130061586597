import React, {useContext, useEffect, useState} from "react";
import {
  Box, Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  Typography,
} from "@material-ui/core";
import FileUploader from "../../FileUploader";
import { DataCleanContext } from "../../StateProvider";
import {
  AGENCY_CARRIER_LOOKUP_CREDIT_TYPE,
  CARRIER_INFO_TYPES
} from "../../../../constants/CoreConstants";
import ActionType from "../../ActionTypes";
import numberToDollar from "../../../../helpers/numberToDollar";

const SkipTracingBulk = ({ onSelectFile }) => {
  const { state, dispatch } = useContext(DataCleanContext);
  const [selectedLookupType, setSelectedLookupType] = useState([]);

  useEffect(() => {
    dispatch({
      type: ActionType.UPDATE_STATE,
      payload: {
        carrierInfos: {
          ...state.carrierInfos,
          doNotCall: null,
          emailVerificationType: null,
          phoneVerificationType: null
        },
      },
    });
  }, []);


  const onChangeRadio = (e) => {
    dispatch({
      type: ActionType.UPDATE_STATE,
      payload: {
        carrierInfos: {
          ...state.carrierInfos,
          skipTrace: CARRIER_INFO_TYPES[e.target.value],
        },
      },
    });
  };

  const onLookupTypeChange = (lookupType) => {
    let spSelectedLookupType = [...selectedLookupType];
    if (selectedLookupType.includes(lookupType)){
      let index = selectedLookupType.indexOf(lookupType);
      spSelectedLookupType.splice(index,1);
      setSelectedLookupType(spSelectedLookupType);
      dispatch({
        type: ActionType.UPDATE_STATE,
        payload: {
          carrierInfos: {
            ...state.carrierInfos,
            [lookupType]: null,
          },
        },
      });
    }else {
      spSelectedLookupType.push(lookupType);
      setSelectedLookupType(spSelectedLookupType);
      dispatch({
        type: ActionType.UPDATE_STATE,
        payload: {
          carrierInfos: {
            ...state.carrierInfos,
            [lookupType]: CARRIER_INFO_TYPES[lookupType],
          },
        },
      });
    }
  };

  return (
    <>
      <Box p={2}>
        <Box style={{ marginBottom: "10px" }}>
          <Typography variant="body1">
            Upload a list of contacts
          </Typography>
          <Typography variant="caption">
            select an option below uploading your list:
          </Typography>
        </Box>
        <FormControl style={{ flexDirection: "row" }}>
          <FormControlLabel
            value="skipTraceCurrentOwner"
            checked={
              state.carrierInfos.skipTrace ===
              CARRIER_INFO_TYPES.skipTraceCurrentOwner
            }
            control={<Radio color={"primary"} />}
            label="Find current owner"
            onChange={onChangeRadio}
          />
          <FormControlLabel
            value="skipTraceCurrentResident"
            checked={
              state.carrierInfos.skipTrace ===
              CARRIER_INFO_TYPES.skipTraceCurrentResident
            }
            control={<Radio color={"primary"} />}
            label="Find current resident"
            onChange={onChangeRadio}
          />
          {/*<FormControlLabel*/}
          {/*  control={<Checkbox color={"primary"} />}*/}
          {/*  label='Check DNC'*/}
          {/*/>*/}
        </FormControl>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Box component="div" style={{ marginRight: 20 }}>
            <Typography variant="h6" style={{ fontWeight: 600 }}>Additional options:</Typography>
          </Box>
          <FormControl onChange={(event)=>{
            onLookupTypeChange(event.target.value);
          }} style={{ flexDirection: "row", gap: 10 }}>
            <FormControlLabel
                name="action"
                value={"doNotCall"}
                checked={selectedLookupType.includes("doNotCall")}
                control={<Checkbox color="primary" />}
                label={`Check DNC Status (${numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.DNC]) ?? "x.xx"})`}
            />
            <FormControlLabel
                name="action"
                value={"phoneVerificationType"}
                checked={selectedLookupType.includes("phoneVerificationType")}
                control={<Checkbox color="primary" />}
                label={`Validate Phone Numbers (${numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.CARRIER]) ?? "x.xx"})`}
            />
            <FormControlLabel
                name="action"
                value={"emailVerificationType"}
                checked={selectedLookupType.includes("emailVerificationType")}
                control={<Checkbox color="primary" />}
                label={`Validate Email Address (${numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.VERIFY_EMAIL]) ?? "x.xx"})`}
            />
          </FormControl>
        </Box>
      </Box>
      <Box p={2}>
        <FileUploader onSelectFile={onSelectFile} />
      </Box>
    </>
  );
};

export default SkipTracingBulk;
