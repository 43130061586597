import React, { useState } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import EachPhoneNumber from "./EachPhoneNumber";
import EditPhoneNumberModal from "./EditPhoneNumberModal";
import { connect } from "react-redux";
import {showValidator, toLowerCase} from "../helper/utils";
import { updateMultipleData } from "../../../../actions/contactAction";
import CustomTitle from "../common/customTitle/CustomTitle";
import Styles from "../common/customTitle/customTitle.module.css";
import {
  LOOKUP_TYPE_CARRIER_LOOKUP,
  LOOKUP_TYPE_DNC_LOOKUP,
} from "../../../../constants/CoreConstants";
import Utils from "../../../../helpers/Utils";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },
});

const PhoneNumber = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const icons = useStyles();

  return (
    <Box className={icons.hoverDiv}>
      <Box mb={2}>
        <Box sx={{ display: "flex", paddingRight: "7px", justifyContent: "end", gap: 8 }}>
          {props.contact.number && Utils.getAccountData("dnc") &&
              <Button
                  className={`${Styles.buttonStyle} ${Styles.checkButton}`}
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  onClick={handleOpen}
              >
                Check Dnc
              </Button>
          }
          {props.contact.number && Utils.getAccountData("carrierLookup") &&
              <Button
                  className={`${Styles.buttonStyle} ${Styles.validButton}`}
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  onClick={handleOpen}
              >
                Validate
              </Button>
          }
        </Box>

        <CustomTitle handleOpen={handleOpen} title={"PHONE NUMBER"} iconHide={icons.icon} />
      </Box>

      {props.contact.number && (
        <Box sx={{ gap: 10, mb: 1 }}>
          <EachPhoneNumber
            value={props.contact.number}
            contact={props.contact}
            numberState={toLowerCase(props?.contact?.contact_additional_informations?.number_state)}
          />
        </Box>
      )}

      {props.contactAdditionalContacts.map((contactAdditionalContact, index) =>
        contactAdditionalContact.number ? (
          <Box sx={{ gap: 10, mb: 1 }}>
            <EachPhoneNumber
              contact={props.contact}
              id={contactAdditionalContact.id}
              value={contactAdditionalContact.number}
              numberState={toLowerCase(contactAdditionalContact.number_state)}
              contactAdditionalContact={contactAdditionalContact}
              index={index}
            />
          </Box>
        ) : (
          <div />
        )
      )}

      {open && <EditPhoneNumberModal open={open} onClose={handleClose} />}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
    contactAdditionalContacts: state.contactReducer.contactAdditionalContacts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);
