import React, { useState, useEffect } from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledAccordion from "../../../common/StyledAccordion";
import SecurityIcon from "@material-ui/icons/Security";
import EachInsurance from "./EachInsurance.jsx";
import { Box } from "@material-ui/core";
import {getPolicyCompanyInsuranceData } from "../../../../api/dealApi.js";
import { connect } from "react-redux";
import { fetchContactPolicies, fetchPolicyCompanyAgentData } from "../redux/contactAction.js";
import { STATUS } from "../../../dataClean/Constants.js";
import AddPolicyModal from "../../middleSide/policies/AddPolicyModal.jsx";
import AddNewPolicyTypeModal from "../../middleSide/policies/AddNewPolicyTypeModal.jsx";
import Typography from "@material-ui/core/Typography";

const Policy = (props) => {
  const [policyId, setPolicyId] = useState(null);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [openAddNewPolicyTypeModal, setOpenAddNewPolicyTypeModal] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState("");

  useEffect(() => {
    props?.getPolicyCompanyAgentData();
    fetchContactPolicyData();
  }, []); // Fetches data on component mount

  const generateObject = () => {
    const createObjectFromData = (data) => {
      return (data || []).reduce((acc, curr) => {
        acc[curr.value] = curr.label;
        return acc;
      }, {});
    };

    const policyObject = createObjectFromData(props.contactPolicyCompanyAgents.policyTypes);
    const companyObject = createObjectFromData(props.contactPolicyCompanyAgents.insuranceCompanies);
    const agentObject = createObjectFromData(props.contactPolicyCompanyAgents.agents);

    return {
      policyType: policyObject,
      company: companyObject,
      agent: agentObject,
    };
  };

  const fetchContactPolicyData =  () => {
    props.getPolicies({ contact_id: props.contact.id })
  };
   
  const handleCloseModal=(isFetch)=>{
    if(isFetch){
      fetchContactPolicyData()
      setOpenPolicyModal(false)
    }
    else{
      setOpenPolicyModal(false)
    }
    
  }

  let count = props.contactPolicies?.length;
  return (
    <React.Fragment>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls='panel1a-content' id='panel1a-header'>
          <FormControlLabel
            aria-label='Acknowledge'
            control={
              <React.Fragment>
                <div className='collaborator__list__parant'>
                  <div className='collaborator__list__left'>
                    <span className='collaborator__left__icon'>
                      <SecurityIcon style={{ height: "21px", width: "22px" }} />
                    </span>{" "}
                    <span className='collaborator__list--title text-dark-blue m-0'>Policy </span>{" "}
                    <Avatar>
                      <h6 className='white text-light-blue circle m-0 items_counter d-flex justify-content-center align-items-center'>
                        {count}
                      </h6>
                    </Avatar>{" "}
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <Box mt={1}>
            {props.contactPolicies && props.contactPolicies[0] ?
              props.contactPolicies?.map((item) => (
              <EachInsurance
                setOpenPolicyModal={setOpenPolicyModal}
                item={item}
                dataObject={generateObject()}
                fetchContactPolicyData={fetchContactPolicyData}
                setPolicyId={setPolicyId}
                contact={props.contact}
              />
            )) :
                <Typography style={{ height: "unset !important", marginLeft: "10px" }}>No Policy Available</Typography>
            }
          </Box>
        </AccordionDetails>
      </StyledAccordion>
      {openPolicyModal && (
        <AddPolicyModal
          open={openPolicyModal}
          contact={props.contact}
          policies={props.contactPolicyCompanyAgents.policyTypes}
          companies={props.contactPolicyCompanyAgents.insuranceCompanies}
          agents={props.contactPolicyCompanyAgents.agents}
          setOpenAddNewPolicyTypeModal={setOpenAddNewPolicyTypeModal}
          onClose={() => setOpenPolicyModal(false)}
          policyId={policyId}
          policyData={props.contactPolicies}
          handleCloseModal={handleCloseModal}
          selectedPolicy={selectedPolicy}
          setSelectedPolicy={setSelectedPolicy}
        />
      )}
       {openAddNewPolicyTypeModal && (
        <AddNewPolicyTypeModal
          open={openAddNewPolicyTypeModal}
          setSelectedPolicy={setSelectedPolicy}
          onClose={() => setOpenAddNewPolicyTypeModal(false)}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
    contactPolicies: state.contactReducer.contactPolicies,
    contactPolicyCompanyAgents: state.contactReducer.contactPolicyCompanyAgentData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPolicies: (params) => dispatch(fetchContactPolicies(params)),
    getPolicyCompanyAgentData:(params) => dispatch(fetchPolicyCompanyAgentData(params))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Policy);
