export const data=[
    {
        policyName:'Auto Insurance',
        policyStatus:'ACTIVE',
        policyNumber:'abcd1242424',
        fullPremium:1200,
        effectiveDate:'20/04/24',
        expirationDate:'23/12/24',
        companyName:'Insurance',
        policyLink:'https://hrm.orangetoolz.com/',
        agentName:'Bond-007'
    },
    {
        policyName:'Bike Insurance',
        policyStatus:'INACTIVE',
        policyNumber:'asdzx1242424',
        fullPremium:1300,
        effectiveDate:'24/06/24',
        expirationDate:'23/08/24',
        companyName:'Insurance',
        policyLink:'https://hrm.orangetoolz.com/',
        agentName:'Bond-007'
    },

]